import CommonModal from "components/Common/CommonModal";
import React, { useState } from "react";
import { toast } from "react-toastify";
import "./CreateDesignSystemModal.scss";
const CreateDesignSystemModal = ({ isOpen, onRequestClose, onApply, title }) => {
	const [name, setName] = useState();
	const [file, setFile] = useState();
	const [logo, setLogo] = useState();
	const [description, setDescription] = useState();

	const handleApply = () => {
		if (!name) {
			toast.warning("Please enter name");
			return;
		}
		let request = {
			design_system_name: name,
			image: file,
			design_system_description: description,
			logo: logo,
		};
		onApply(request);
		onRequestClose();
	};

	return (
		<CommonModal
			isOpen={isOpen}
			onRequestClose={onRequestClose}
			title={title}
			classPrefix="plt"
			onApply={handleApply}
			onCancelText="Cancel"
			onApplyText="Add"
			// bodyClassName='overflow-unset'
		>
			<div className="plt-dropdown-secondary  w-full row-gap-4 ">
				<div className="plt-select-dropdown-wrapper create-dss dis-ml-24 dis-mr-24 dis-pt-16 dis-pb-16">
					{/* Field Name */}
					<div className="upload-font-content d-flex align-items-center justify-content-between  ">
						<div className="plt-select-dropdown-wrapper w-full">
							<h6 className="plt-select-dropdown-title plt-ui-text-sm-regular plt-font-color-primary dis-mb-4">
								Design system name
							</h6>
							<div className="modal-value-block w-100">
								<input
									type="text"
									className="input-field-custom modal-value-btn plt-ui-text-sm-regular plt-font-color-primary "
									onChange={(e) => setName(e.target.value)}
								/>
							</div>
						</div>
					</div>
					{/* Field: Thumbnail */}
					<div className="plt-select-dropdown-wrapper">
						<h6 className="plt-select-dropdown-title plt-ui-text-sm-regular plt-font-color-primary dis-mb-4">
							Thumbnail <span>(Maximum size: 5mb, Supported formats - png,jpg,jpeg,webp)</span>
						</h6>
						<input
							type="file"
							accept="image/*"
							onChange={(e) => setFile(e.target.files[0])}
							className="input-field-custom"
						/>
					</div>
					{/* Field: Logo */}
					<div className="plt-select-dropdown-wrapper">
						<h6 className="plt-select-dropdown-title plt-ui-text-sm-regular plt-font-color-primary dis-mb-4">
							Logo <span>(Maximum size: 2mb, Supported formats - png,jpg,jpeg,webp)</span>
						</h6>
						<input
							type="file"
							accept="image/*"
							onChange={(e) => setLogo(e.target.files[0])}
							className="input-field-custom"
						/>
					</div>
					{/* Field: Description */}
					<div className="upload-font-content d-flex align-items-center justify-content-between dis-mb-16 ">
						<div className="plt-select-dropdown-wrapper">
							<h6 className="plt-select-dropdown-title plt-ui-text-sm-regular plt-font-color-primary dis-mb-4">
								Description
							</h6>
							<div className="modal-value-block w-100">
								<textarea
									className="modal-textarea w-100"
									placeholder="Text"
									rows="5"
									cols="50"
									type="text"
									onChange={(e) => setDescription(e.target.value)}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</CommonModal>
	);
};

export default CreateDesignSystemModal;
