export const PrimaryLogo = ({ svgPathData, fillColor, strokeColor, strokeWidth, ...rest }) => {
	return (
		<svg width="430" height="111" viewBox="0 0 430 111" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M134.716 90.5951C119.594 90.5951 109 80.1509 109 64.9681C109 48.6249 121.135 40.3082 132.693 40.3082C139.531 40.3082 145.214 43.2093 148.585 48.5282V20.6769H160.046V65.0648C160.046 80.5377 149.644 90.5951 134.716 90.5951ZM134.619 79.8608C142.998 79.8608 148.585 73.865 148.585 65.4516C148.585 57.0383 142.998 51.0425 134.619 51.0425C126.24 51.0425 120.558 57.0383 120.558 65.4516C120.558 73.865 126.24 79.8608 134.619 79.8608Z"
				fill="black"
			/>
			<path
				d="M190.327 90.5951C175.302 90.5951 164.707 79.9575 164.707 65.4516C164.707 50.9458 175.302 40.3082 190.327 40.3082C205.351 40.3082 215.753 50.9458 215.753 65.4516V69.5133H176.746C178.287 76.4761 183.585 80.1509 190.327 80.1509C195.431 80.1509 199.187 78.7003 201.595 75.6057H213.827C210.263 84.6961 201.595 90.5951 190.327 90.5951ZM177.035 60.3262H203.618C201.788 53.9437 196.587 50.7524 190.327 50.7524C183.874 50.7524 178.865 54.0404 177.035 60.3262Z"
				fill="black"
			/>
			<path
				d="M238.569 90.5951C226.433 90.5951 218.825 84.1158 218.536 75.2189H230.093C230.19 78.4102 232.983 80.5377 238.569 80.5377C244.444 80.5377 246.948 78.1201 246.948 75.2189C246.948 71.5441 242.71 71.0606 237.606 70.3836C230.286 69.4166 219.499 68.1594 219.499 56.458C219.499 46.6908 226.915 40.3082 238.376 40.3082C250.03 40.3082 257.157 46.8842 257.446 55.2976H246.081C245.792 52.3964 243.096 50.3656 238.569 50.3656C233.561 50.3656 231.056 52.7832 231.056 55.5877C231.056 59.2625 235.391 59.8427 240.495 60.5197C246.852 61.39 258.506 62.6472 258.506 74.542C258.506 84.2125 250.704 90.5951 238.569 90.5951Z"
				fill="black"
			/>
			<path
				d="M281.391 90.5951C269.255 90.5951 261.646 84.1158 261.357 75.2189H272.915C273.011 78.4102 275.804 80.5377 281.391 80.5377C287.266 80.5377 289.77 78.1201 289.77 75.2189C289.77 71.5441 285.532 71.0606 280.427 70.3836C273.108 69.4166 262.321 68.1594 262.321 56.458C262.321 46.6908 269.737 40.3082 281.198 40.3082C292.852 40.3082 299.979 46.8842 300.268 55.2976H288.903C288.614 52.3964 285.917 50.3656 281.391 50.3656C276.382 50.3656 273.878 52.7832 273.878 55.5877C273.878 59.2625 278.212 59.8427 283.317 60.5197C289.673 61.39 301.327 62.6472 301.327 74.542C301.327 84.2125 293.526 90.5951 281.391 90.5951Z"
				fill="black"
			/>
			<path
				d="M313.425 35.3762C308.995 35.3762 305.816 32.1849 305.816 27.7364C305.816 23.288 308.995 20 313.425 20C317.856 20 321.034 23.288 321.034 27.7364C321.034 32.1849 317.856 35.3762 313.425 35.3762ZM307.646 89.3379V41.5654H319.108V89.3379H307.646Z"
				fill="black"
			/>
			<path
				d="M351.178 111C339.331 111 329.989 105.875 327.003 94.56H338.561C340.487 99.0085 345.11 101.039 351.178 101.039C359.653 101.039 364.95 97.0744 364.95 85.9532V81.7949C361.676 87.1137 355.993 90.0149 348.866 90.0149C337.501 90.0149 325.366 82.3751 325.366 65.4516C325.366 50.9458 335.96 40.3082 350.985 40.3082C366.01 40.3082 376.412 50.3656 376.412 65.8385V85.5664C376.412 103.844 365.143 111 351.178 111ZM350.985 79.2806C359.364 79.2806 364.95 73.865 364.95 65.4516C364.95 57.0383 359.364 51.0425 350.985 51.0425C342.606 51.0425 336.923 57.0383 336.923 65.4516C336.923 73.865 342.606 79.2806 350.985 79.2806Z"
				fill="black"
			/>
			<path
				d="M382.999 89.3379V64.4846C382.999 48.5282 392.438 40.3082 406.5 40.3082C420.561 40.3082 430 48.4315 430 64.4846V89.3379H418.539V64.4846C418.539 55.3943 413.723 51.0425 406.5 51.0425C399.276 51.0425 394.461 55.3943 394.461 64.4846V89.3379H382.999Z"
				fill="black"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M53.6842 106C70.9794 106 85 91.991 85 74.7099V33.9651C85 29.9373 83.3748 26.0795 80.492 23.2642L56.6702 0V67.095C56.6702 73.3017 51.6345 78.3333 45.4226 78.3333H28.3298V59.3314C28.3298 53.1246 33.3656 48.093 39.5774 48.093H56.6597L31.1343 25.4756C29.3343 23.8807 27.0118 23 24.606 23C11.0165 23 0 34.0074 0 47.5858V74.7056C0 77.0255 0.936047 79.2475 2.59649 80.869L21.2968 99.1316C25.8055 103.535 31.8597 106 38.1643 106H53.6842Z"
				fill="url(#paint0_linear_233_33)"
			/>
			<mask
				id="mask0_233_33"
				style={{ maskType: "alpha" }}
				maskUnits="userSpaceOnUse"
				x="0"
				y="0"
				width="85"
				height="106"
			>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M53.6842 106C70.9794 106 85 91.991 85 74.7099V33.9651C85 29.9373 83.3748 26.0795 80.492 23.2642L56.6702 0V67.095C56.6702 73.3017 51.6345 78.3333 45.4226 78.3333H28.3298V59.3314C28.3298 53.1246 33.3656 48.093 39.5774 48.093H56.6597L31.1343 25.4756C29.3343 23.8807 27.0118 23 24.606 23C11.0165 23 0 34.0074 0 47.5858V74.7056C0 77.0255 0.936047 79.2475 2.59649 80.869L21.2968 99.1316C25.8055 103.535 31.8597 106 38.1643 106H53.6842Z"
					fill="url(#paint1_linear_233_33)"
				/>
			</mask>
			<g mask="url(#mask0_233_33)">
				<path d="M31.0255 109L53.544 73L92.576 104.5L31.0255 109Z" fill="#B677FF" />
				<path d="M-33.2226 82.8184L-8.70245 43.8184L28.3279 78.3184L-33.2226 82.8184Z" fill="#C05AFF" />
				<path d="M53.2263 52.9336L79.9819 15.4294L114.935 52.0294L53.2263 52.9336Z" fill="#4D7AFF" />
			</g>
			<defs>
				<linearGradient
					id="paint0_linear_233_33"
					x1="-3.91936e-06"
					y1="96.9659"
					x2="110.174"
					y2="62.0403"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#B94BE3" />
					<stop offset="1" stopColor="#1166CE" />
				</linearGradient>
				<linearGradient
					id="paint1_linear_233_33"
					x1="-3.91936e-06"
					y1="96.9659"
					x2="110.174"
					y2="62.0403"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#B94BE3" />
					<stop offset="1" stopColor="#1166CE" />
				</linearGradient>
			</defs>
		</svg>
	);
};

export const GoogleLogo = ({ svgPathData, fillColor, strokeColor, strokeWidth, ...rest }) => {
	return (
		<svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clipPath="url(#clip0_423_15827)">
				<path
					d="M7.46721 0.658267C5.4689 1.3515 3.74556 2.66728 2.55032 4.41233C1.35508 6.15739 0.750946 8.23974 0.826651 10.3535C0.902355 12.4673 1.65391 14.5011 2.97092 16.1562C4.28794 17.8113 6.10099 19.0004 8.14377 19.5489C9.7999 19.9762 11.535 19.995 13.2 19.6036C14.7083 19.2648 16.1028 18.5401 17.2469 17.5005C18.4376 16.3854 19.302 14.9668 19.7469 13.3973C20.2304 11.6905 20.3164 9.89567 19.9985 8.15045H10.6985V12.0083H16.0844C15.9768 12.6236 15.7461 13.2108 15.4062 13.7349C15.0663 14.2589 14.6242 14.7091 14.1063 15.0583C13.4487 15.4935 12.7073 15.7862 11.9297 15.9176C11.1499 16.0627 10.3501 16.0627 9.57033 15.9176C8.77995 15.7544 8.03227 15.4282 7.37502 14.9598C6.319 14.2123 5.52608 13.1503 5.1094 11.9255C4.68579 10.6776 4.68579 9.32486 5.1094 8.07702C5.406 7.20235 5.89632 6.40598 6.54377 5.74733C7.2847 4.97975 8.22273 4.43108 9.25495 4.16151C10.2872 3.89195 11.3737 3.91191 12.3953 4.2192C13.1935 4.46409 13.9233 4.89214 14.5266 5.4692C15.1339 4.86504 15.7401 4.25931 16.3453 3.65202C16.6578 3.32545 16.9985 3.01452 17.3063 2.68014C16.3852 1.82314 15.3042 1.15624 14.125 0.717642C11.9777 -0.0620611 9.62811 -0.0830148 7.46721 0.658267Z"
					fill="white"
				/>
				<path
					d="M7.46758 0.657073C9.6283 -0.0847125 11.9779 -0.0643102 14.1254 0.714886C15.3048 1.15647 16.3853 1.82657 17.3051 2.68676C16.9926 3.02114 16.6629 3.33364 16.3441 3.65864C15.7379 4.26384 15.1322 4.86697 14.527 5.46801C13.9237 4.89095 13.1938 4.46289 12.3957 4.21801C11.3744 3.90964 10.2879 3.88852 9.25542 4.15698C8.22292 4.42544 7.28432 4.97311 6.54258 5.73989C5.89513 6.39854 5.40481 7.19491 5.1082 8.06957L1.86914 5.56176C3.02853 3.26264 5.03594 1.50399 7.46758 0.657073Z"
					fill="#E33629"
				/>
				<path
					d="M1.01015 8.04688C1.18412 7.18402 1.47316 6.34843 1.86953 5.5625L5.10859 8.07656C4.68498 9.3244 4.68498 10.6772 5.10859 11.925C4.02942 12.7583 2.94974 13.5958 1.86953 14.4375C0.877575 12.463 0.575047 10.2133 1.01015 8.04688Z"
					fill="#F8BD00"
				/>
				<path
					d="M10.698 8.14844H19.998C20.3159 9.89366 20.2299 11.6885 19.7464 13.3953C19.3015 14.9648 18.4371 16.3834 17.2464 17.4984C16.2011 16.6828 15.1511 15.8734 14.1058 15.0578C14.624 14.7082 15.0663 14.2576 15.4063 13.733C15.7462 13.2084 15.9766 12.6205 16.0839 12.0047H10.698C10.6964 10.7203 10.698 9.43437 10.698 8.14844Z"
					fill="#2778ED"
				/>
				<path
					d="M1.86719 14.4383C2.9474 13.6049 4.02708 12.7674 5.10625 11.9258C5.52376 13.1511 6.31782 14.2131 7.375 14.9602C8.0343 15.4264 8.78359 15.7499 9.575 15.9102C10.3548 16.0552 11.1546 16.0552 11.9344 15.9102C12.7119 15.7787 13.4533 15.486 14.1109 15.0508C15.1562 15.8664 16.2062 16.6758 17.2516 17.4914C16.1076 18.5316 14.7132 19.2568 13.2047 19.5961C11.5397 19.9875 9.80457 19.9687 8.14844 19.5414C6.8386 19.1917 5.61512 18.5751 4.55469 17.7305C3.43239 16.8393 2.51568 15.7163 1.86719 14.4383Z"
					fill="#319F43"
				/>
			</g>
			<defs>
				<clipPath id="clip0_423_15827">
					<rect width="20" height="20" fill="white" transform="translate(0.5)" />
				</clipPath>
			</defs>
		</svg>
	);
};

export const ModalCloseIcon = ({ svgPathData, fillColor, strokeColor, strokeWidth, ...rest }) => {
	return (
		<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M13.3298 2.67285L2.67725 13.3219" stroke="#171717" strokeWidth="1.5" strokeLinecap="round" />
			<path d="M13.3332 13.3337L2.6665 2.66699" stroke="#171717" strokeWidth="1.5" strokeLinecap="round" />
		</svg>
	);
};

export const LinkedlnLogo = ({ svgPathData, fillColor, strokeColor, strokeWidth }) => {
	return (
		<svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clipPath="url(#clip0_423_15836)">
				<path
					d="M15.8125 0H5.1875C2.59867 0 0.5 2.09867 0.5 4.6875V15.3125C0.5 17.9013 2.59867 20 5.1875 20H15.8125C18.4013 20 20.5 17.9013 20.5 15.3125V4.6875C20.5 2.09867 18.4013 0 15.8125 0Z"
					fill="white"
				/>
				<path
					d="M15.8125 0H5.1875C2.59867 0 0.5 2.09867 0.5 4.6875V15.3125C0.5 17.9013 2.59867 20 5.1875 20H15.8125C18.4013 20 20.5 17.9013 20.5 15.3125V4.6875C20.5 2.09867 18.4013 0 15.8125 0Z"
					fill="#0A66C2"
				/>
				<path
					d="M14.9309 17.0066H17.2176C17.3004 17.0066 17.3799 16.9737 17.4385 16.9151C17.4971 16.8566 17.5301 16.7771 17.5301 16.6942L17.5312 11.8628C17.5312 9.33758 16.9871 7.39656 14.0361 7.39656C12.9143 7.35484 11.8564 7.93313 11.2855 8.89875C11.2828 8.90344 11.2785 8.90709 11.2734 8.90914C11.2684 8.91119 11.2628 8.91153 11.2575 8.9101C11.2523 8.90867 11.2476 8.90556 11.2443 8.90124C11.2409 8.89692 11.2391 8.89163 11.2391 8.88617V7.94219C11.2391 7.85931 11.2061 7.77982 11.1475 7.72122C11.0889 7.66261 11.0094 7.62969 10.9266 7.62969H8.75648C8.6736 7.62969 8.59412 7.66261 8.53551 7.72122C8.47691 7.77982 8.44398 7.85931 8.44398 7.94219V16.6937C8.44398 16.7766 8.47691 16.8561 8.53551 16.9147C8.59412 16.9733 8.6736 17.0062 8.75648 17.0062H11.043C11.1259 17.0062 11.2054 16.9733 11.264 16.9147C11.3226 16.8561 11.3555 16.7766 11.3555 16.6937V12.3677C11.3555 11.1445 11.5876 9.95992 13.1041 9.95992C14.599 9.95992 14.6184 11.3596 14.6184 12.447V16.6941C14.6184 16.777 14.6513 16.8565 14.7099 16.9151C14.7685 16.9737 14.848 17.0066 14.9309 17.0066ZM3.46875 4.65836C3.46875 5.58531 4.2318 6.34797 5.15875 6.34797C6.08547 6.34789 6.84805 5.58477 6.84805 4.65805C6.84789 3.73133 6.08523 2.96875 5.15844 2.96875C4.23141 2.96875 3.46875 3.73148 3.46875 4.65836ZM4.01242 17.0066H6.30203C6.38491 17.0066 6.4644 16.9737 6.523 16.9151C6.58161 16.8565 6.61453 16.777 6.61453 16.6941V7.94219C6.61453 7.85931 6.58161 7.77982 6.523 7.72122C6.4644 7.66261 6.38491 7.62969 6.30203 7.62969H4.01242C3.92954 7.62969 3.85006 7.66261 3.79145 7.72122C3.73285 7.77982 3.69992 7.85931 3.69992 7.94219V16.6941C3.69992 16.777 3.73285 16.8565 3.79145 16.9151C3.85006 16.9737 3.92954 17.0066 4.01242 17.0066Z"
					fill="white"
				/>
			</g>
			<defs>
				<clipPath id="clip0_423_15836">
					<rect width="20" height="20" fill="white" transform="translate(0.5)" />
				</clipPath>
			</defs>
		</svg>
	);
};

export const EditButtonicon = ({ svgPathData, fillColor, strokeColor, strokeWidth }) => {
	return (
		<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M4.40921 13.0187L13.0142 4.41367C13.3392 4.08867 13.8667 4.08867 14.1917 4.41367L15.5884 5.81034C15.9134 6.13534 15.9134 6.66284 15.5884 6.98784L6.98254 15.592C6.82671 15.7487 6.61504 15.8362 6.39421 15.8362H4.16504V13.607C4.16504 13.3862 4.25254 13.1745 4.40921 13.0187Z"
				stroke="#171717"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M11.46 5.9668L14.035 8.5418"
				stroke="#171717"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export const CopyButtonicon = ({ svgPathData, fillColor, strokeColor, strokeWidth }) => {
	return (
		<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect
				x="4.66528"
				y="4.66504"
				width="9.33722"
				height="9.33722"
				rx="2"
				stroke="#171717"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M4.66509 11.3348H3.3312C2.59452 11.3348 1.99731 10.7376 1.99731 10.0009V3.33145C1.99731 2.59476 2.59452 1.99756 3.3312 1.99756H10.0006C10.7373 1.99756 11.3345 2.59476 11.3345 3.33145V4.66534"
				stroke="#171717"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export const PlusButtonicon = ({ svgPathData, fillColor, strokeColor, strokeWidth }) => {
	return (
		<svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M13 8.5H3" stroke="#630B99" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
			<path d="M8 13.5V3.5" stroke="#630B99" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
		</svg>
	);
};

export const PlusButtonWhiteicon = ({ svgPathData, fillColor, strokeColor, strokeWidth }) => {
	return (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M19.5 12H4.5" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
			<path d="M12 19.5V4.5" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
		</svg>
	);
};

export const CopyLinkicon = ({ svgPathData, fillColor, strokeColor, strokeWidth }) => {
	return (
		<svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M9.25257 6.08417L5.59523 9.7415C5.04457 10.2922 5.04457 11.1855 5.59523 11.7362V11.7362C6.1459 12.2868 7.03923 12.2868 7.5899 11.7362L12.4112 6.91483C13.4212 5.90483 13.4212 4.2675 12.4112 3.2575V3.2575C11.4012 2.2475 9.7639 2.2475 8.7539 3.2575L3.93257 8.07883C2.46323 9.54817 2.46323 11.9295 3.93257 13.3988V13.3988C5.4019 14.8682 7.78323 14.8682 9.25257 13.3988L12.1786 10.4728"
				stroke="#630B99"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export const EditIcon = ({ svgPathData, fillColor, strokeColor, strokeWidth }) => {
	return (
		<svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M3.97353 10.7496L10.75 3.9732C11.0059 3.71727 11.4213 3.71727 11.6772 3.9732L12.7771 5.07308C13.0331 5.32902 13.0331 5.74442 12.7771 6.00036L6.00003 12.7761C5.87731 12.8995 5.71062 12.9684 5.53672 12.9684H3.78125V11.213C3.78125 11.039 3.85016 10.8724 3.97353 10.7496Z"
				stroke="#171717"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M9.52344 5.19922L11.5512 7.22703"
				stroke="#171717"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export const DropdownArrow = ({ svgPathData, fillColor, strokeColor, strokeWidth }) => {
	return (
		<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M12.6667 5.66663L8.00004 10.3333L3.33337 5.66663"
				stroke="#171717"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export const LeftArrow = ({ svgPathData, fillColor, strokeColor, strokeWidth }) => {
	return (
		<svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M6.33331 10.6667L1.66665 6.00004L6.33331 1.33337"
				stroke="#6D6D6D"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export const RightArrow = ({ svgPathData, fillColor, strokeColor, strokeWidth }) => {
	return (
		<svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M1.66669 1.33329L6.33335 5.99996L1.66669 10.6666"
				stroke="#171717"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export const EyeButton = ({ svgPathData, fillColor, strokeColor, strokeWidth }) => {
	return (
		<svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clipPath="url(#clip0_5787_1233)">
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M1.99756 8.50006C1.99815 8.05787 2.0999 7.62168 2.29502 7.22486V7.22486C3.39907 5.10314 5.60865 3.78887 8.00006 3.83145C10.3915 3.78887 12.601 5.10314 13.7051 7.22486V7.22486C14.1017 8.02874 14.1017 8.97138 13.7051 9.77526V9.77526C12.601 11.897 10.3915 13.2113 8.00006 13.1687C5.60865 13.2113 3.39907 11.897 2.29502 9.77526V9.77526C2.0999 9.37844 1.99815 8.94225 1.99756 8.50006Z"
					stroke="#171717"
					strokeWidth="1.5"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M10.0009 8.49998C10.0009 9.60501 9.10513 10.5008 8.0001 10.5008C6.89507 10.5008 5.99927 9.60501 5.99927 8.49998C5.99927 7.39495 6.89507 6.49915 8.0001 6.49915"
					stroke="#171717"
					strokeWidth="1.5"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</g>
			<defs>
				<clipPath id="clip0_5787_1233">
					<rect width="16" height="16" fill="white" transform="translate(0 0.5)" />
				</clipPath>
			</defs>
		</svg>
	);
};

export const EyeButtonOpen = ({ svgPathData, fillColor, strokeColor, strokeWidth }) => {
	return (
		<svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clipPath="url(#clip0_5787_989)">
				<path
					d="M9.70562 9.53845C9.27926 10.2574 8.4511 10.6357 7.62854 10.4873C6.80598 10.339 6.16217 9.69518 6.01381 8.87262C5.86546 8.05005 6.24379 7.2219 6.9627 6.79553"
					stroke="#171717"
					strokeWidth="1.5"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M11.9978 11.8307C10.8479 12.7029 9.44325 13.173 8.00005 13.1686C5.60865 13.2112 3.39908 11.8969 2.29501 9.77517C1.89841 8.9713 1.89841 8.02866 2.29501 7.22478C2.84717 6.12519 3.72737 5.22435 4.81387 4.64685"
					stroke="#171717"
					strokeWidth="1.5"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M13.618 9.92306C13.6448 9.87237 13.6796 9.82691 13.7049 9.77525C14.1015 8.97137 14.1015 8.02873 13.7049 7.22486C12.6008 5.10314 10.3912 3.78886 7.99982 3.83145C7.84989 3.83145 7.70416 3.85146 7.55615 3.86121"
					stroke="#171717"
					strokeWidth="1.5"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M14.0026 13.8356L2.66455 2.49756"
					stroke="#171717"
					strokeWidth="1.5"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</g>
			<defs>
				<clipPath id="clip0_5787_989">
					<rect width="16" height="16" fill="white" transform="translate(0 0.5)" />
				</clipPath>
			</defs>
		</svg>
	);
};

// export const ErrorIcon = ({
//   svgPathData,
//   fillColor,
//   strokeColor,
//   strokeWidth,
// }) => {
//   return (
//     <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
// <path fillRule="evenodd" clipRule="evenodd" d="M0.666504 5.99996C0.666504 3.05649 3.05584 0.666626 5.99984 0.666626C8.94917 0.666626 11.3332 3.05649 11.3332 5.99996C11.3332 8.94449 8.94917 11.3333 5.99984 11.3333C3.05584 11.3333 0.666504 8.94449 0.666504 5.99996ZM7.96681 7.96317C8.18862 7.7422 8.18862 7.3837 7.96681 7.16208L6.80555 6.00183L7.96681 4.84094C8.18862 4.61997 8.18862 4.25495 7.96681 4.03333C7.745 3.81106 7.38618 3.81106 7.15784 4.03333L6.0031 5.19292L4.84184 4.03333C4.6135 3.81106 4.25468 3.81106 4.03286 4.03333C3.81105 4.25495 3.81105 4.61997 4.03286 4.84094L5.19413 6.00183L4.03286 7.15556C3.81105 7.3837 3.81105 7.7422 4.03286 7.96317C4.14377 8.07398 4.29382 8.13329 4.43735 8.13329C4.5874 8.13329 4.73093 8.07398 4.84184 7.96317L6.0031 6.81009L7.16436 7.96317C7.27527 8.08115 7.4188 8.13329 7.56232 8.13329C7.71238 8.13329 7.8559 8.07398 7.96681 7.96317Z" fill="#CE463D"/>
// </svg>

//   );
// };

export const navSettingsIcon = ({ svgPathData, fillColor, strokeColor, strokeWidth }) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
			<path
				d="M22.1031 14.607C21.8896 14.277 21.586 13.947 21.1926 13.738C20.8778 13.584 20.6755 13.331 20.4956 13.034C19.9223 12.088 20.2595 10.845 21.2151 10.284C22.3392 9.657 22.6989 8.26 22.0469 7.171L21.2938 5.873C20.653 4.784 19.2478 4.399 18.1349 5.037C17.1456 5.565 15.8753 5.213 15.302 4.278C15.1222 3.97 15.021 3.64 15.0435 3.31C15.0772 2.881 14.9423 2.474 14.7399 2.144C14.324 1.462 13.5708 1 12.739 1H11.1539C10.3333 1.022 9.58008 1.462 9.16414 2.144C8.95055 2.474 8.8269 2.881 8.84938 3.31C8.87186 3.64 8.77069 3.97 8.59083 4.278C8.01751 5.213 6.74721 5.565 5.7692 5.037C4.64505 4.399 3.2511 4.784 2.59909 5.873L1.8459 7.171C1.20514 8.26 1.56487 9.657 2.67778 10.284C3.63331 10.845 3.97055 12.088 3.40848 13.034C3.21737 13.331 3.01502 13.584 2.70026 13.738C2.31805 13.947 1.9808 14.277 1.80094 14.607C1.385 15.289 1.40749 16.147 1.82342 16.862L2.59909 18.182C3.01502 18.886 3.79069 19.326 4.60008 19.326C4.98229 19.326 5.43195 19.216 5.79168 18.996C6.07272 18.809 6.40997 18.743 6.78094 18.743C7.89385 18.743 8.8269 19.656 8.84938 20.745C8.84938 22.01 9.8836 23 11.1876 23H12.7165C14.0092 23 15.0435 22.01 15.0435 20.745C15.0772 19.656 16.0102 18.743 17.1231 18.743C17.4829 18.743 17.8201 18.809 18.1124 18.996C18.4721 19.216 18.9106 19.326 19.304 19.326C20.1022 19.326 20.8778 18.886 21.2938 18.182L22.0807 16.862C22.4854 16.125 22.5191 15.289 22.1031 14.607Z"
				fill="white"
			/>
			<path
				d="M11.9621 15.1129C10.1972 15.1129 8.76953 13.7379 8.76953 12.0109C8.76953 10.2839 10.1972 8.89795 11.9621 8.89795C13.727 8.89795 15.121 10.2839 15.121 12.0109C15.121 13.7379 13.727 15.1129 11.9621 15.1129Z"
				fill="#630B99"
			/>
		</svg>
	);
};

export const TabAddIcon = ({ svgPathData, fillColor, strokeColor, strokeWidth }) => {
	return (
		<svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M9.99967 7.1665V13.8332" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
			<path
				d="M13.3337 10.5002H6.66699"
				stroke="white"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M10 18V18C5.8575 18 2.5 14.6425 2.5 10.5V10.5C2.5 6.3575 5.8575 3 10 3V3C14.1425 3 17.5 6.3575 17.5 10.5V10.5C17.5 14.6425 14.1425 18 10 18Z"
				stroke="white"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export const SidebarMenuLogo = ({ svgPathData, fillColor, strokeColor, strokeWidth }) => {
	return (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clipPath="url(#clip0_4294_1035)">
				<path d="M3 4H21V6H3V4ZM3 11H15V13H3V11ZM3 18H21V20H3V18Z" fill="white" />
			</g>
			<defs>
				<clipPath id="clip0_4294_1035">
					<rect width="24" height="24" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
};

export const CusotmRadius = ({ svgPathData, fillColor, strokeColor, strokeWidth }) => {
	return (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M21 18C21 19.657 19.657 21 18 21"
				stroke="#323232"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M21 18C21 19.657 19.657 21 18 21"
				stroke="#171717"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M3 6C3 4.343 4.343 3 6 3"
				stroke="#323232"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M3 6C3 4.343 4.343 3 6 3"
				stroke="#171717"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M6 21C4.343 21 3 19.657 3 18"
				stroke="#171717"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M18 3C19.657 3 21 4.343 21 6"
				stroke="#171717"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export const OverviewIcon = ({ svgPathData, fillColor, strokeColor, strokeWidth, color }) => {
	return (
		<svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M13.191 0H4.81C1.77 0 0 1.78 0 4.83V15.16C0 18.26 1.77 20 4.81 20H13.191C16.28 20 18 18.26 18 15.16V4.83C18 1.78 16.28 0 13.191 0Z"
				fill="white"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M5.07996 4.6499V4.6599C4.64896 4.6599 4.29996 5.0099 4.29996 5.4399C4.29996 5.8699 4.64896 6.2199 5.07996 6.2199H8.06896C8.49996 6.2199 8.84996 5.8699 8.84996 5.4289C8.84996 4.9999 8.49996 4.6499 8.06896 4.6499H5.07996ZM12.92 10.7399H5.07996C4.64896 10.7399 4.29996 10.3899 4.29996 9.9599C4.29996 9.5299 4.64896 9.1789 5.07996 9.1789H12.92C13.35 9.1789 13.7 9.5299 13.7 9.9599C13.7 10.3899 13.35 10.7399 12.92 10.7399ZM12.92 15.3099H5.07996C4.77996 15.3499 4.48996 15.1999 4.32996 14.9499C4.16996 14.6899 4.16996 14.3599 4.32996 14.1099C4.48996 13.8499 4.77996 13.7099 5.07996 13.7399H12.92C13.319 13.7799 13.62 14.1199 13.62 14.5299C13.62 14.9289 13.319 15.2699 12.92 15.3099Z"
				fill={color}
			/>
		</svg>
	);
};

export const StylesIcon = ({ svgPathData, fillColor, strokeColor, strokeWidth, color }) => {
	return (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M15.9213 1.84613H19.3073C20.7096 1.84613 21.8458 2.99198 21.8458 4.40609V7.82066C21.8458 9.23477 20.7096 10.3806 19.3073 10.3806H15.9213C14.5191 10.3806 13.3828 9.23477 13.3828 7.82066V4.40609C13.3828 2.99198 14.5191 1.84613 15.9213 1.84613Z"
				fill={color}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M4.38423 1.84613H7.77019C9.17246 1.84613 10.3087 2.99198 10.3087 4.40609V7.82066C10.3087 9.23477 9.17246 10.3806 7.77019 10.3806H4.38423C2.98196 10.3806 1.8457 9.23477 1.8457 7.82066V4.40609C1.8457 2.99198 2.98196 1.84613 4.38423 1.84613ZM4.38423 13.3116H7.77019C9.17246 13.3116 10.3087 14.4575 10.3087 15.8716V19.2862C10.3087 20.6993 9.17246 21.8461 7.77019 21.8461H4.38423C2.98196 21.8461 1.8457 20.6993 1.8457 19.2862V15.8716C1.8457 14.4575 2.98196 13.3116 4.38423 13.3116ZM19.3072 13.3116H15.9212C14.5189 13.3116 13.3827 14.4575 13.3827 15.8716V19.2862C13.3827 20.6993 14.5189 21.8461 15.9212 21.8461H19.3072C20.7094 21.8461 21.8457 20.6993 21.8457 19.2862V15.8716C21.8457 14.4575 20.7094 13.3116 19.3072 13.3116Z"
				fill="white"
			/>
		</svg>
	);
};

export const ComponentsIcon = ({ svgPathData, fillColor, strokeColor, color, strokeWidth }) => {
	return (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M22 11.9999C22 17.5229 17.523 21.9999 12 21.9999C6.477 21.9999 2 17.5229 2 11.9999C2 6.47788 6.477 1.99988 12 1.99988C17.523 1.99988 22 6.47788 22 11.9999Z"
				fill="white"
			/>
			<path
				d="M15.8597 8.70493L14.2397 13.8249C14.1797 14.0349 14.0097 14.2049 13.7997 14.2659L8.69972 15.8649C8.35972 15.9759 8.02972 15.6449 8.13972 15.3049L9.73972 10.1749C9.79972 9.96493 9.96972 9.80493 10.1797 9.73493L15.2997 8.13493C15.6497 8.02493 15.9697 8.35493 15.8597 8.70493Z"
				fill={color}
			/>
		</svg>
	);
};

export const SettingsIcon = ({ svgPathData, fillColor, strokeColor, strokeWidth, color }) => {
	return (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M21.2301 14.37C21.036 14.07 20.76 13.77 20.4023 13.58C20.1162 13.44 19.9322 13.21 19.7687 12.94C19.2475 12.08 19.5541 10.95 20.4228 10.44C21.4447 9.87 21.7718 8.6 21.179 7.61L20.4943 6.43C19.9118 5.44 18.6344 5.09 17.6226 5.67C16.7233 6.15 15.5685 5.83 15.0473 4.98C14.8838 4.7 14.7918 4.4 14.8122 4.1C14.8429 3.71 14.7203 3.34 14.5363 3.04C14.1582 2.42 13.4735 2 12.7172 2H11.2763C10.5302 2.02 9.84553 2.42 9.4674 3.04C9.27323 3.34 9.16081 3.71 9.18125 4.1C9.20169 4.4 9.10972 4.7 8.9462 4.98C8.42501 5.83 7.27019 6.15 6.38109 5.67C5.35913 5.09 4.09191 5.44 3.49917 6.43L2.81446 7.61C2.23194 8.6 2.55897 9.87 3.57071 10.44C4.43937 10.95 4.74596 12.08 4.23498 12.94C4.06125 13.21 3.87729 13.44 3.59115 13.58C3.24368 13.77 2.93709 14.07 2.77358 14.37C2.39546 14.99 2.4159 15.77 2.79402 16.42L3.49917 17.62C3.87729 18.26 4.58245 18.66 5.31825 18.66C5.66572 18.66 6.0745 18.56 6.40153 18.36C6.65702 18.19 6.96361 18.13 7.30085 18.13C8.31259 18.13 9.16081 18.96 9.18125 19.95C9.18125 21.1 10.1215 22 11.3069 22H12.6968C13.872 22 14.8122 21.1 14.8122 19.95C14.8429 18.96 15.6911 18.13 16.7029 18.13C17.0299 18.13 17.3365 18.19 17.6022 18.36C17.9292 18.56 18.3278 18.66 18.6855 18.66C19.411 18.66 20.1162 18.26 20.4943 17.62L21.2097 16.42C21.5776 15.75 21.6083 14.99 21.2301 14.37Z"
				fill="white"
			/>
			<path
				d="M12.0117 14.8302C10.4073 14.8302 9.10938 13.5802 9.10938 12.0102C9.10938 10.4402 10.4073 9.18018 12.0117 9.18018C13.6162 9.18018 14.8834 10.4402 14.8834 12.0102C14.8834 13.5802 13.6162 14.8302 12.0117 14.8302Z"
				fill={color}
			/>
		</svg>
	);
};

export const CardSettingsIcon = ({ svgPathData, fillColor, strokeColor, strokeWidth }) => {
	return (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<mask id="mask0_3526_833" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
				<rect width="24" height="24" fill="#D9D9D9" />
			</mask>
			<g mask="url(#mask0_3526_833)">
				<path
					d="M12 20C11.45 20 10.9792 19.8042 10.5875 19.4125C10.1958 19.0208 10 18.55 10 18C10 17.45 10.1958 16.9792 10.5875 16.5875C10.9792 16.1958 11.45 16 12 16C12.55 16 13.0208 16.1958 13.4125 16.5875C13.8042 16.9792 14 17.45 14 18C14 18.55 13.8042 19.0208 13.4125 19.4125C13.0208 19.8042 12.55 20 12 20ZM12 14C11.45 14 10.9792 13.8042 10.5875 13.4125C10.1958 13.0208 10 12.55 10 12C10 11.45 10.1958 10.9792 10.5875 10.5875C10.9792 10.1958 11.45 10 12 10C12.55 10 13.0208 10.1958 13.4125 10.5875C13.8042 10.9792 14 11.45 14 12C14 12.55 13.8042 13.0208 13.4125 13.4125C13.0208 13.8042 12.55 14 12 14ZM12 8C11.45 8 10.9792 7.80417 10.5875 7.4125C10.1958 7.02083 10 6.55 10 6C10 5.45 10.1958 4.97917 10.5875 4.5875C10.9792 4.19583 11.45 4 12 4C12.55 4 13.0208 4.19583 13.4125 4.5875C13.8042 4.97917 14 5.45 14 6C14 6.55 13.8042 7.02083 13.4125 7.4125C13.0208 7.80417 12.55 8 12 8Z"
					fill="#171717"
				/>
			</g>
		</svg>
	);
};

export const AddButtonicon = ({ svgPathData, fillColor, strokeColor, strokeWidth }) => {
	return (
		<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M10.0002 6.6665V13.3332"
				stroke="#171717"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M13.3332 10.0002H6.6665"
				stroke="#171717"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M10 17.5V17.5C5.8575 17.5 2.5 14.1425 2.5 10V10C2.5 5.8575 5.8575 2.5 10 2.5V2.5C14.1425 2.5 17.5 5.8575 17.5 10V10C17.5 14.1425 14.1425 17.5 10 17.5Z"
				stroke="#171717"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export const DeleteButtonicon = ({ svgPathData, fillColor, strokeColor, strokeWidth }) => {
	return (
		<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M15 5V15.625C15 16.6608 14.1442 17.5 13.1092 17.5H6.85917C5.82333 17.5 5 16.6608 5 15.625V5"
				stroke="#171717"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path d="M16.25 5.00016H3.75" stroke="#171717" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
			<path
				d="M8.33325 2.50016H11.6666"
				stroke="#171717"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M11.6667 8.3335V14.1668"
				stroke="#171717"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M8.33341 14.1668V8.3335"
				stroke="#171717"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export const DeleteButtoniconRed = ({
  svgPathData,
  fillColor,
  strokeColor,
  strokeWidth,
}) => {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16.1032 8.38965C16.1032 8.38965 15.6507 14.0021 15.3882 16.3663C15.2632 17.4955 14.5657 18.1571 13.4232 18.178C11.249 18.2171 9.07234 18.2196 6.89901 18.1738C5.79984 18.1513 5.11401 17.4813 4.99151 16.3721C4.72734 13.9871 4.27734 8.38965 4.27734 8.38965" stroke="#CE463D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M17.2567 5.69987H3.125" stroke="#CE463D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M14.5335 5.69949C13.8793 5.69949 13.316 5.23699 13.1877 4.59616L12.9852 3.58283C12.8602 3.11533 12.4368 2.79199 11.9543 2.79199H8.42682C7.94432 2.79199 7.52099 3.11533 7.39599 3.58283L7.19349 4.59616C7.06516 5.23699 6.50182 5.69949 5.84766 5.69949" stroke="#CE463D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
    
  );
};

export const ButtonIcon = (props) => {
	const color = props.color;
	return (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<rect x="4.75" y="4.75" width="14.3581" height="14.3581" stroke={color} strokeWidth="1.5" />
			<path d="M5.5 5.5L18.3615 18.3615" stroke={color} strokeWidth="1.5" />
			<path d="M18.3563 5.5L5.5 18.3563" stroke={color} strokeWidth="1.5" />
		</svg>
	);
};

export const FilterButtonicon = ({ svgPathData, fillColor, strokeColor, strokeWidth }) => {
	return (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M10 13.333L3.36 7.8C3.132 7.61 3 7.328 3 7.032V4.5C3 3.948 3.448 3.5 4 3.5H20C20.552 3.5 21 3.948 21 4.5V7.032C21 7.329 20.868 7.61 20.64 7.8L14 13.333V17.882C14 18.261 13.786 18.607 13.447 18.776L10.723 20.138C10.391 20.304 10 20.063 10 19.691V13.333Z"
				stroke="#171717"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path d="M3.12012 7.5H20.8801" stroke="#171717" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
		</svg>
	);
};

export const ColorCopyIcon = (props) => {
	return (
		<svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect
				x="5.33301"
				y="5.16504"
				width="9.33722"
				height="9.33722"
				rx="2"
				stroke="white"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M5.33282 11.8348H3.99893C3.26224 11.8348 2.66504 11.2376 2.66504 10.5009V3.83145C2.66504 3.09476 3.26224 2.49756 3.99893 2.49756H10.6684C11.4051 2.49756 12.0023 3.09476 12.0023 3.83145V5.16534"
				stroke="white"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export const ColorEditIcon = (props) => {
	return (
		<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M1.82156 8.02209L8.23256 1.11793C8.4747 0.857173 8.8677 0.857173 9.10984 1.11793L10.1504 2.23854C10.3925 2.4993 10.3925 2.92254 10.1504 3.1833L3.73878 10.0868C3.62268 10.2125 3.46498 10.2827 3.30045 10.2827H1.63965V8.49413C1.63965 8.31695 1.70484 8.14712 1.82156 8.02209Z"
				stroke="white"
				strokeWidth="1.3245"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M7.07227 2.3667L8.99072 4.43273"
				stroke="white"
				strokeWidth="1.3245"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export const EditTypoIcon = (props) => {
	return (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect width="24" height="24" rx="5.51724" fill="#F6F6F6" />
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M7.97353 14.7496L14.75 7.9732C15.0059 7.71727 15.4213 7.71727 15.6772 7.9732L16.7771 9.07308C17.0331 9.32902 17.0331 9.74442 16.7771 10.0004L10 16.7761C9.87731 16.8995 9.71062 16.9684 9.53672 16.9684H7.78125V15.213C7.78125 15.039 7.85016 14.8724 7.97353 14.7496Z"
				stroke="#171717"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M13.5234 9.19873L15.5512 11.2265"
				stroke="#171717"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export const TickIcon = () => {
	return (
		<svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M20 6L9 17L4 12" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
		</svg>
	);
};

export const TickIconWhite = () => {
	return (
		<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M3.3335 9.58333L8.05705 14.1667L17.5002 5" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
		</svg>
		);
};

export const TickIconWhiteSmall = () => {
	return (
		<svg width="11" height="8" viewBox="0 0 11 8" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M1 3.75L3.83413 6.5L9.5 1" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
		</svg>
		);
};


export const ToasterCloseIcon = (props) => {
	return (
		<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M13.3298 2.67334L2.67725 13.3224" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
			<path d="M13.3332 13.3332L2.6665 2.6665" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
		</svg>
	);
};

export const ButtonIconSample = (props) => {
	const color = props.color;
	return (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<rect x="4.75" y="4.75" width="14.3581" height="14.3581" stroke={color} strokeWidth="1.5" />
			<path d="M5.5 5.5L18.3615 18.3615" stroke={color} strokeWidth="1.5" />
			<path d="M18.3563 5.5L5.5 18.3563" stroke={color} strokeWidth="1.5" />
		</svg>
	);
};

export const StatusIcon = (props) => {
	const color = props.color;
	return (
		<svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M8 0.5C3.58862 0.5 0 4.08862 0 8.5C0 12.9114 3.58862 16.5 8 16.5C12.4114 16.5 16 12.9114 16 8.5C16 4.08862 12.4114 0.5 8 0.5ZM12.0547 6.80469L7.72131 11.1379C7.59131 11.2679 7.42065 11.3334 7.25 11.3334C7.07935 11.3334 6.90869 11.2679 6.77869 11.1379L4.61206 8.97131C4.35132 8.71069 4.35132 8.28931 4.61206 8.02869C4.87268 7.76794 5.29395 7.76794 5.55469 8.02869L7.25 9.724L11.1121 5.86206C11.3727 5.60132 11.7939 5.60132 12.0547 5.86206C12.3153 6.12268 12.3153 6.54395 12.0547 6.80469Z"
				fill="#0F900F"
			/>
		</svg>
	);
};

export const ErrorIcon = () => {
	return (
		<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M0.666504 5.99996C0.666504 3.05649 3.05584 0.666626 5.99984 0.666626C8.94917 0.666626 11.3332 3.05649 11.3332 5.99996C11.3332 8.94449 8.94917 11.3333 5.99984 11.3333C3.05584 11.3333 0.666504 8.94449 0.666504 5.99996ZM7.96681 7.96317C8.18862 7.7422 8.18862 7.3837 7.96681 7.16208L6.80555 6.00183L7.96681 4.84094C8.18862 4.61997 8.18862 4.25495 7.96681 4.03333C7.745 3.81106 7.38618 3.81106 7.15784 4.03333L6.0031 5.19292L4.84184 4.03333C4.6135 3.81106 4.25468 3.81106 4.03286 4.03333C3.81105 4.25495 3.81105 4.61997 4.03286 4.84094L5.19413 6.00183L4.03286 7.15556C3.81105 7.3837 3.81105 7.7422 4.03286 7.96317C4.14377 8.07398 4.29382 8.13329 4.43735 8.13329C4.5874 8.13329 4.73093 8.07398 4.84184 7.96317L6.0031 6.81009L7.16436 7.96317C7.27527 8.08115 7.4188 8.13329 7.56232 8.13329C7.71238 8.13329 7.8559 8.07398 7.96681 7.96317Z"
				fill="#CE463D"
			/>
		</svg>
	);
};

export const CloseIcon = (props) => {
	return (
		<svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path d="M6.66484 1.33667L1.33855 6.6612" stroke="#434343" strokeWidth="1.5" strokeLinecap="round" />
			<path d="M6.66666 6.66671L1.33333 1.33337" stroke="#434343" strokeWidth="1.5" strokeLinecap="round" />
		</svg>
	);
};

export const UserIcon = () => {
	return (
		<svg width="48" height="60" viewBox="0 0 48 60" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M39.882 15.8732C39.882 24.6842 32.8173 31.7493 24 31.7493C15.1857 31.7493 8.11804 24.6842 8.11804 15.8732C8.11804 7.06207 15.1857 0 24 0C32.8173 0 39.882 7.06207 39.882 15.8732ZM24 60C10.9871 60 0 57.885 0 49.7249C0 41.5618 11.0561 39.5218 24 39.5218C37.0159 39.5218 48 41.6368 48 49.7969C48 57.96 36.9439 60 24 60Z"
				fill="white"
			/>
		</svg>
	);
};

export const DownloadIcon = () => {
	return (
		<svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M21 15V19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V15"
				stroke="black"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path d="M7 10L12 15L17 10" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
			<path d="M12 15V3" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
		</svg>
	);
};

export const UploadIcon = () => {
	return (
		<svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M7.99984 13.1667V8.5" stroke="#171717" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
			<path
				d="M6.55518 9.83334L7.99984 8.38867L9.44451 9.83334"
				stroke="#171717"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M10.6668 13.1668H12.3735C13.6588 13.1668 14.7068 12.1188 14.7068 10.8335C14.7068 9.54816 13.6588 8.50016 12.3735 8.50016H12.0835V7.8335C12.0835 5.62683 10.2901 3.8335 8.08346 3.8335C6.09879 3.8335 4.45346 5.2855 4.14146 7.18216C2.55679 7.26283 1.29346 8.56216 1.29346 10.1668C1.29346 11.8235 2.63679 13.1668 4.29346 13.1668H5.33346"
				stroke="#171717"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export const UploadIconSmall = () => {
	return (
		<svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M10 9.1665L8 7.1665L6 9.1665"
				stroke="#171717"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M5.33333 13.8333H4.66667C3.19391 13.8333 2 12.6394 2 11.1667V5.16667C2 3.69391 3.19391 2.5 4.66667 2.5H11.3333C12.8061 2.5 14 3.69391 14 5.16667V11.1667C14 12.6394 12.8061 13.8333 11.3333 13.8333H10.6667"
				stroke="#171717"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M7.99984 7.1665V14.4998"
				stroke="#171717"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export const WarningIconSmall = () => {
	return (
		<svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M8 14.5V14.5C4.686 14.5 2 11.814 2 8.5V8.5C2 5.186 4.686 2.5 8 2.5V2.5C11.314 2.5 14 5.186 14 8.5V8.5C14 11.814 11.314 14.5 8 14.5Z"
				stroke="#434343"
				strokeWidth="1.2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M8.00016 11.8333V8.5H7.3335"
				stroke="#434343"
				strokeWidth="1.2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M7.83267 5.83268C7.74067 5.83268 7.666 5.90735 7.66667 5.99935C7.66667 6.09135 7.74134 6.16602 7.83334 6.16602C7.92534 6.16602 8 6.09135 8 5.99935C8 5.90735 7.92534 5.83268 7.83267 5.83268"
				stroke="#434343"
				strokeWidth="1.2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export const LogoutIcon = ({ color }) => {
	return (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M18.36 6.64001C19.6184 7.8988 20.4753 9.50246 20.8223 11.2482C21.1693 12.994 20.9909 14.8034 20.3096 16.4478C19.6284 18.0921 18.4748 19.4976 16.9948 20.4864C15.5148 21.4752 13.7749 22.0029 11.995 22.0029C10.2151 22.0029 8.47515 21.4752 6.99517 20.4864C5.51519 19.4976 4.36164 18.0921 3.68036 16.4478C2.99909 14.8034 2.82069 12.994 3.16772 11.2482C3.51475 9.50246 4.37162 7.8988 5.63 6.64001"
				stroke="white"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path d="M12 2V12" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
		</svg>
	);
};
export const ThreeDotIcon = ({ color }) => {
	return (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z"
				stroke="black"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12C18 12.5523 18.4477 13 19 13Z"
				stroke="black"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M5 13C5.55228 13 6 12.5523 6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12C4 12.5523 4.44772 13 5 13Z"
				stroke="black"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};
export const ContentIcon = ({ color }) => {
	return (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M4 19C4 20.1046 4.89543 21 6 21H16C17.1046 21 18 20.1046 18 19V7C18 5.89543 17.1046 5 16 5H14"
				fill={color}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M13.3675 2.1046L5.36754 4.77126C4.55086 5.04349 4 5.80777 4 6.66863V18.9989C4 19.6418 4.30905 20.2455 4.83058 20.6214C5.35212 20.9973 6.02256 21.0996 6.63246 20.8963L14.6325 18.2296C15.4491 17.9574 16 17.1931 16 16.3322V4.00196C16 3.35908 15.691 2.75538 15.1694 2.37948C14.6479 2.00358 13.9774 1.9013 13.3675 2.1046Z"
				fill="white"
			/>
		</svg>
	);
};
export const ResourceIcon = ({ color }) => {
	return (
		<svg width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
			<circle cx="20.5" cy="20" r="10" fill="white" />
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M15.5 17.0315V23.5516C15.5029 23.7297 15.5998 23.8929 15.7548 23.9808C15.9097 24.0686 16.0996 24.068 16.2539 23.9791C17.6428 23.2691 19.3238 23.4753 20.5 24.4998C21.6762 23.4753 23.3572 23.2691 24.7461 23.9791C24.9004 24.068 25.0903 24.0686 25.2452 23.9808C25.4002 23.8929 25.4971 23.7296 25.5 23.5516V17.0315C25.4911 16.7538 25.3766 16.4899 25.1799 16.2937C23.7898 15.1288 21.73 15.2608 20.5 16.5936C19.27 15.2608 17.2102 15.1288 15.8201 16.2937C15.6234 16.4899 15.5089 16.7538 15.5 17.0315Z"
				fill={color}
			/>
			<path d="M20.5 24.2483L20.5 16.0721" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
		</svg>
	);
};

export const PatternsIcon = ({ color }) => {
	return (
		<svg width="22" height="23" viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M9.56613 12.0993L1.90258 7.93439C1.56169 7.74922 1.15915 7.75687 0.825582 7.95516C0.492013 8.15341 0.292969 8.50344 0.292969 8.89149V18.5531C0.292969 18.944 0.504063 19.3069 0.843916 19.5001L8.50761 23.8569C8.67655 23.9529 8.8613 24.0009 9.04609 24.0009C9.23439 24.0009 9.42265 23.9511 9.59385 23.8514C9.9329 23.6541 10.1353 23.3022 10.1353 22.9098V13.0564C10.1353 12.6569 9.91729 12.2901 9.56613 12.0993Z"
				fill="white"
			/>
			<path
				d="M20.3175 5.42039C20.317 5.01246 20.0916 4.64245 19.7295 4.45476L11.3726 0.122251C11.0562 -0.0418609 10.6791 -0.0406418 10.3638 0.125299L2.12989 4.45781C1.77137 4.64653 1.54837 5.01541 1.54785 5.42058C1.54738 5.82565 1.7695 6.19505 2.1275 6.38457L10.3615 10.7441C10.5209 10.8285 10.696 10.8707 10.8711 10.8707C11.0441 10.8707 11.2171 10.8295 11.3749 10.7471L19.732 6.38767C20.0937 6.19899 20.318 5.82833 20.3175 5.42039Z"
				fill="white"
			/>
			<path
				d="M21.175 7.95524C20.8416 7.75695 20.4389 7.74912 20.0981 7.93442L12.4343 12.0993C12.0833 12.2902 11.8652 12.6569 11.8652 13.0564V22.9099C11.8652 23.3022 12.0677 23.6542 12.4067 23.8514C12.5779 23.9511 12.7661 24.0009 12.9545 24.0009C13.1392 24.0009 13.324 23.9529 13.4929 23.8569L21.1567 19.5001C21.4965 19.3069 21.7076 18.944 21.7076 18.5531V8.89157C21.7076 8.50352 21.5085 8.15349 21.175 7.95524Z"
				fill={color}
			/>
		</svg>
	);
};

export const ProfileSettingsIcon = () => {
	return (
		<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M6.46875 4.21826V9.99986" stroke="#171717" strokeWidth="1.5" strokeLinecap="round" />
			<path d="M13.5332 15.7812V9.99965" stroke="#171717" strokeWidth="1.5" strokeLinecap="round" />
			<rect x="4.87793" y="11.8512" width="3.18048" height="3.18048" rx="1.45288" stroke="#171717" strokeWidth="1.5" />
			<rect
				x="0.75"
				y="-0.75"
				width="3.18048"
				height="3.18048"
				rx="1.45288"
				transform="matrix(1 0 0 -1 11.1924 7.3988)"
				stroke="#171717"
				strokeWidth="1.5"
			/>
		</svg>
	);
};

export const HelpCenterIcon = () => {
	return (
		<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M16.6663 16.6667V4.81504C16.6663 3.99671 16.003 3.33337 15.1847 3.33337H4.81467C3.99634 3.33337 3.33301 3.99671 3.33301 4.81504V13.7042C3.33301 14.5225 3.99634 15.1859 4.81467 15.1859H13.8888L16.6663 16.6667Z"
				stroke="#171717"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M9.99786 12.3259C9.91786 12.3259 9.85286 12.3909 9.8537 12.4709C9.8537 12.5509 9.9187 12.6159 9.9987 12.6159C10.0787 12.6159 10.1437 12.5509 10.1437 12.4709C10.1445 12.3909 10.0795 12.3259 9.99786 12.3259"
				stroke="#171717"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M9.99953 10.0634V9.86336C9.99953 9.20753 10.4045 8.85253 10.8112 8.58003C11.2079 8.31336 11.6054 7.96503 11.6054 7.32253C11.6054 6.43586 10.887 5.71753 10.0004 5.71753C9.1137 5.71753 8.39453 6.4342 8.39453 7.32086"
				stroke="#171717"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export const SearchIcon = () => {
	return (
		<svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
			<circle
				cx="7.34394"
				cy="6.84442"
				r="5.99237"
				stroke="#DDDDDD"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M11.5117 11.3232L13.8611 13.6665"
				stroke="#DDDDDD"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export const DownloadArrow = () => {
	return (
		<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect width="32" height="32" rx="5" fill="white" />
			<path
				d="M15.9997 21.8332V10.1665"
				stroke="#323232"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M20.167 17.6655L15.9995 21.833L11.832 17.6655"
				stroke="#323232"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export const ProfileLogoutIcon = () => {
	return (
		<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M10.5304 1.59145C9.47647 1.16238 8.33302 0.99985 7.20131 1.11825C6.0696 1.23665 4.98455 1.63232 4.04228 2.27023C3.10001 2.90813 2.32961 3.76857 1.79931 4.77534C1.26901 5.7821 0.995179 6.90411 1.00208 8.04198C1.00898 9.17985 1.2964 10.2985 1.83887 11.2987C2.38135 12.299 3.16213 13.15 4.11207 13.7764C5.06201 14.4029 6.15177 14.7853 7.28484 14.89C8.41791 14.9947 9.5593 14.8183 10.6079 14.3765"
				stroke="#171717"
				strokeWidth="1.5"
				strokeLinecap="round"
			/>
			<path
				d="M14.998 8.00004L8.05735 8.00004"
				stroke="#171717"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M12.5192 10.4789L14.998 8.00006"
				stroke="#171717"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M12.5192 5.5213L14.998 8.00012"
				stroke="#171717"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};
export const PremiumIcon = () => {
	return (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M18.6586 18.8846C13.6629 17.2504 7.9929 18.1166 5.1954 18.9148C4.86135 19.0102 4.51089 18.8112 4.43394 18.4725L2.50386 9.97553C2.28908 9.02999 3.24708 8.24573 4.13159 8.64301L7.68759 10.2402C7.95766 10.3615 8.27578 10.263 8.43001 10.0103L11.0857 5.65881C11.5519 4.89491 12.6668 4.91153 13.1101 5.68899L15.5616 9.98913C15.7115 10.252 16.0364 10.3581 16.3125 10.2344L19.8393 8.65388C20.7293 8.25504 21.6898 9.05113 21.4631 9.99969L19.4415 18.4576C19.3593 18.8013 18.9946 18.9945 18.6586 18.8846Z"
				fill="#D89D08"
			/>
			<path
				d="M5.56141 20.0415C9.87856 19.1024 13.219 18.5945 18.577 20.0415"
				stroke="#D89D08"
				strokeWidth="1.17535"
				strokeLinecap="round"
			/>
		</svg>
	);
};

export const PremiumButtonIcon = () => {
	return (
		<svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
			<circle
				cx="12"
				cy="12.2422"
				r="9.5"
				stroke="#323232"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M16.3027 9.60289L14.6104 9.94201L12.7079 8.03576C12.5203 7.84781 12.2657 7.74219 12.0001 7.74219C11.7346 7.74219 11.4799 7.84781 11.2923 8.03576L9.38979 9.94201L7.69747 9.60289C7.35922 9.53512 7.00993 9.64646 6.77331 9.89749C6.5367 10.1485 6.44617 10.5038 6.5338 10.8374L7.88871 15.9961C8.00414 16.4357 8.40144 16.7421 8.8559 16.7421H15.1443C15.5988 16.7421 15.9961 16.4357 16.1115 15.9961L17.4664 10.8374C17.554 10.5037 17.4635 10.1485 17.2269 9.89748C16.9903 9.64646 16.641 9.53512 16.3027 9.60289Z"
				stroke="#323232"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export const StarButtonIcon = () => {
	return (
		<svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12.0001 17.2444V17.2444C12.159 17.2443 12.2932 17.1267 12.3143 16.9693L12.4623 15.8628C12.6817 14.2179 13.9757 12.9239 15.6206 12.7045L16.7271 12.5564C16.8845 12.5354 17.0021 12.4011 17.0022 12.2423V12.2423C17.0021 12.0835 16.8845 11.9492 16.7271 11.9282L15.6206 11.7801C13.9757 11.5608 12.6817 10.2667 12.4623 8.62181L12.3143 7.51535C12.2932 7.35792 12.159 7.24034 12.0001 7.24023V7.24023C11.8413 7.24034 11.707 7.35792 11.686 7.51535L11.5379 8.62181C11.3186 10.2667 10.0245 11.5608 8.37962 11.7801L7.27316 11.9282C7.11573 11.9492 6.99815 12.0835 6.99805 12.2423V12.2423C6.99815 12.4011 7.11573 12.5354 7.27316 12.5564L8.37962 12.7045C10.0245 12.9239 11.3186 14.2179 11.5379 15.8628L11.686 16.9693C11.707 17.1267 11.8413 17.2443 12.0001 17.2444V17.2444Z"
				stroke="#323232"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M18.7209 5.52144C22.432 9.2331 22.4317 15.2504 18.7202 18.9617C15.0087 22.673 8.99139 22.673 5.2799 18.9617C1.56841 15.2504 1.56812 9.2331 5.27925 5.52144C7.06163 3.7388 9.47921 2.7373 12.0001 2.7373C14.5209 2.7373 16.9385 3.7388 18.7209 5.52144"
				stroke="#323232"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export const ToolButtonIcon = () => {
	return (
		<svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M10.25 10.7422L8.27191 11.0465C7.54016 11.1591 7 11.7887 7 12.5291V13.2422C7 13.7945 7.44772 14.2422 8 14.2422H16C16.5523 14.2422 17 13.7945 17 13.2422V12.5291C17 11.7887 16.4598 11.1591 15.7281 11.0465L13.75 10.7422V7.99219C13.75 7.02569 12.9665 6.24219 12 6.24219V6.24219C11.0335 6.24219 10.25 7.02569 10.25 7.99219V10.7422Z"
				stroke="#323232"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M16.5 14.1079V16.2423C16.5 16.7946 16.0523 17.2423 15.5 17.2423H8.5C7.94772 17.2423 7.5 16.7946 7.5 16.2423V14.1079"
				stroke="#323232"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<rect
				x="3"
				y="3.24219"
				width="18"
				height="18"
				rx="5"
				stroke="#323232"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};
