import React from "react";
import "./PrimaryButton.scss";
import { ButtonIcon } from "../../Icons";

function PrimaryButton({
	buttonType = "dss",
	variant = "primary",
	size = "md",
	text = "Button text",
	fluid,
	icon,
	prefixIcon,
	suffixIcon,
	prefixClass,
	suffixClass,
	disabled = false,
	iconOnly = false,
	style,
	onClick,
}) {
	return (
		<button
			className={`${buttonType}-btn ${buttonType}-btn-${variant} ${buttonType}-btn-${size} ${fluid ? `${buttonType}-btn-fluid` : ""} ${icon ? `${buttonType}-btn-icon` : ""} ${disabled ? "disabled" : ""} ${iconOnly ? `icon-only` : ""}`}
			type="button"
			onClick={onClick}
			style={style}
		>
			{prefixIcon && (
				<span className={`${buttonType}-icon prefix-icon ${prefixClass}`}>
					{prefixIcon == true ? <ButtonIcon color="#FFFFFF" /> : prefixIcon}
				</span>
			)}
			{text}
			{suffixIcon && (
				<span className={`${buttonType}-icon suffix-icon ${suffixClass}`}>
					{suffixIcon == true ? <ButtonIcon color="#FFFFFF" /> : suffixIcon}
				</span>
			)}{" "}
		</button>
	);
}

export default PrimaryButton;
