import React, { useState } from "react";
import "./TokenButtonComponent.scss";
import TabNavigation from "../../TabNavigation";
import { buttonTableData, TokenButtonDatas } from "../../../../assets/data/data";
import { useGlobalContext } from "context/GlobalContext";

import ButtonTable from "./ButtonTable";
import useScrollManagement from "hooks/useScrollManagement";

const TokenButtonComponent = () => {
	const tabData = ["Primary", "Secondary", "Outline", "Danger", "Ghost", "Fluid width"];

	const [activeSideTab, setActiveSideTab] = useState(0);
	const { designSystem } = useGlobalContext();
	const sectionRefs = React.useRef(tabData.map(() => React.createRef()));

	const scrollToSection = useScrollManagement(sectionRefs.current, activeSideTab, setActiveSideTab, "tab-content-body");

	const handleTabClick = (index) => {
		setActiveSideTab(index);
		scrollToSection(index);
	};

	const buttonTableData1 = [
		{
			elements: "Text/Label",
			tokenName: designSystem?.light?.text?.button?.fill.primary?.tokenName,
			property: "Text color",
		},
		{
			elements: "Typography",
			tokenName: designSystem?.light?.uitext?.button?.large?.semibold?.tokenName,
			property: "Large",
		},
		{
			elements: "",
			tokenName: designSystem?.light?.uitext?.button?.normal?.semibold?.tokenName,
			property: "Normal",
		},
		{
			elements: "",
			tokenName: designSystem?.light?.uitext?.button?.dense?.semibold?.tokenName,
			property: "Dense",
		},
		{
			elements: "Border radius",
			tokenName: designSystem?.light?.borderRadius?.button?.large?.tokenName,
			property: "Border radius",
		},
		{
			elements: "",
			tokenName: designSystem?.light?.borderRadius?.button?.normal?.tokenName,
			property: "Border radius",
		},
		{
			elements: "",
			tokenName: designSystem?.light?.borderRadius?.button?.dense?.tokenName,
			property: "Border radius",
		},
		{
			elements: "Spacing",
			tokenName: designSystem?.light?.spacing?.button?.large?.tokenName,
			property: "Spacing between the container",
		},
		{
			elements: "",
			tokenName: designSystem?.light?.spacing?.button?.normal?.tokenName,
			property: "Spacing between the container",
		},
		{
			elements: "",
			tokenName: designSystem?.light?.spacing?.button?.dense?.tokenName,
			property: "Spacing between the container",
		},
		{
			elements: "Fill",
			tokenName: designSystem?.light?.background?.button?.fill?.primary?.tokenName,
			property: "Button/ container color",
		},
		{
			elements: "",
			tokenName: designSystem?.light?.background?.button?.fill?.pressed?.tokenName,
			property: "Button/ container color",
		},
		{
			elements: "",
			tokenName: designSystem?.light?.background?.button?.fill?.hovered?.tokenName,
			property: "Button/ container color",
		},
		{
			elements: "",
			tokenName: designSystem?.light?.background?.button?.fill?.disabled?.tokenName,
			property: "Button/ container color",
		},
	];

	const buttonTableData2 = [
		{
			elements: "Text/Label",
			tokenName: designSystem?.light?.text?.button?.default.primary?.tokenName,
			property: "Text color",
		},
		{
			elements: "Typography",
			tokenName: designSystem?.light?.uitext?.button?.large?.semibold?.tokenName,
			property: "Large",
		},
		{
			elements: "",
			tokenName: designSystem?.light?.uitext?.button?.normal?.semibold?.tokenName,
			property: "Normal",
		},
		{
			elements: "",
			tokenName: designSystem?.light?.uitext?.button?.dense?.semibold?.tokenName,
			property: "Dense",
		},
		{
			elements: "Border radius",
			tokenName: designSystem?.light?.borderRadius?.button?.large?.tokenName,
			property: "Border radius",
		},
		{
			elements: "",
			tokenName: designSystem?.light?.borderRadius?.button?.normal?.tokenName,
			property: "Border radius",
		},
		{
			elements: "",
			tokenName: designSystem?.light?.borderRadius?.button?.dense?.tokenName,
			property: "Border radius",
		},
		{
			elements: "Spacing",
			tokenName: designSystem?.light?.spacing?.button?.large?.tokenName,
			property: "Spacing between the container",
		},
		{
			elements: "",
			tokenName: designSystem?.light?.spacing?.button?.normal?.tokenName,
			property: "Spacing between the container",
		},
		{
			elements: "",
			tokenName: designSystem?.light?.spacing?.button?.dense?.tokenName,
			property: "Spacing between the container",
		},
		{
			elements: "Fill",
			tokenName: designSystem?.light?.background?.button?.default?.primary?.tokenName,
			property: "Button/ container color",
		},
		{
			elements: "",
			tokenName: designSystem?.light?.background?.button?.default?.pressed?.tokenName,
			property: "Button/ container color",
		},
		{
			elements: "",
			tokenName: designSystem?.light?.background?.button?.default?.hovered?.tokenName,
			property: "Button/ container color",
		},
		{
			elements: "",
			tokenName: designSystem?.light?.background?.button?.default?.disabled?.tokenName,
			property: "Button/ container color",
		},
	];

	const buttonTableData3 = [
		{
			elements: "Text/Label",
			tokenName: designSystem?.light?.text?.button?.stroke?.primary?.tokenName,
			property: "Text color",
		},
		{
			elements: "Typography",
			tokenName: designSystem?.light?.uitext?.button?.large?.semibold?.tokenName,
			property: "Large",
		},
		{
			elements: "",
			tokenName: designSystem?.light?.uitext?.button?.normal?.semibold?.tokenName,
			property: "Normal",
		},
		{
			elements: "",
			tokenName: designSystem?.light?.uitext?.button?.dense?.semibold?.tokenName,
			property: "Dense",
		},
		{
			elements: "Border radius",
			tokenName: designSystem?.light?.borderRadius?.button?.large?.tokenName,
			property: "Border radius",
		},
		{
			elements: "",
			tokenName: designSystem?.light?.borderRadius?.button?.normal?.tokenName,
			property: "Border radius",
		},
		{
			elements: "",
			tokenName: designSystem?.light?.borderRadius?.button?.dense?.tokenName,
			property: "Border radius",
		},
		{
			elements: "Spacing",
			tokenName: designSystem?.light?.spacing?.button?.large?.tokenName,
			property: "Spacing between the container",
		},
		{
			elements: "",
			tokenName: designSystem?.light?.spacing?.button?.normal?.tokenName,
			property: "Spacing between the container",
		},
		{
			elements: "",
			tokenName: designSystem?.light?.spacing?.button?.dense?.tokenName,
			property: "Spacing between the container",
		},
		{
			elements: "Fill",
			tokenName: designSystem?.light?.background?.button?.stroke?.pressed?.tokenName,
			property: "Button/ container color",
		},
		{
			elements: "",
			tokenName: designSystem?.light?.background?.button?.stroke?.hovered?.tokenName,
			property: "Button/ container color",
		},
	];

	const buttonTableData4 = [
		{
			elements: "Text/Label",
			tokenName: designSystem?.light?.text?.button?.ghost?.primary?.tokenName,
			property: "Text color",
		},
		{
			elements: "Typography",
			tokenName: designSystem?.light?.uitext?.button?.large?.semibold?.tokenName,
			property: "Large",
		},
		{
			elements: "",
			tokenName: designSystem?.light?.uitext?.button?.normal?.semibold?.tokenName,
			property: "Normal",
		},
		{
			elements: "",
			tokenName: designSystem?.light?.uitext?.button?.dense?.semibold?.tokenName,
			property: "Dense",
		},
		{
			elements: "Border radius",
			tokenName: designSystem?.light?.borderRadius?.button?.large?.tokenName,
			property: "Border radius",
		},
		{
			elements: "",
			tokenName: designSystem?.light?.borderRadius?.button?.normal?.tokenName,
			property: "Border radius",
		},
		{
			elements: "",
			tokenName: designSystem?.light?.borderRadius?.button?.dense?.tokenName,
			property: "Border radius",
		},
		{
			elements: "Spacing",
			tokenName: designSystem?.light?.spacing?.button?.large?.tokenName,
			property: "Spacing between the container",
		},
		{
			elements: "",
			tokenName: designSystem?.light?.spacing?.button?.normal?.tokenName,
			property: "Spacing between the container",
		},
		{
			elements: "",
			tokenName: designSystem?.light?.spacing?.button?.dense?.tokenName,
			property: "Spacing between the container",
		},
		{
			elements: "Fill",
			tokenName: designSystem?.light?.background?.button?.ghost?.pressed?.tokenName,
			property: "Button/ container color",
		},
		{
			elements: "",
			tokenName: designSystem?.light?.background?.button?.ghost?.hovered?.tokenName,
			property: "Button/ container color",
		},
	];

	return (
		<div className="tab-content-wrapper d-flex justify-content-between">
			<div className="tab-content-container">
				{TokenButtonDatas.map((data, index) => (
					<div key={data.id} className="plt-content-block dis-mb-40">
						<div className="plt-content-wrapper dis-mb-16">
							<div className="plt-content-block-top d-flex justify-content-between align-items-center dis-mb-8">
								<h6
									ref={sectionRefs.current[index]}
									className="plt-contents-head plt-heading-md plt-font-color-primary font-weight-700"
								>
									{data.title}
								</h6>
							</div>
							{/* <p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
          {data.description}
          </p> */}
						</div>
						{/* <h5 className="sub-heading plt-heading-md plt-font-color-primary dis-pb-8 ">{data.sub_heading}</h5> */}
						<ButtonTable
							buttonTableData={
								data.id === 1
									? buttonTableData1
									: data.id === 2
										? buttonTableData2
										: data.id === 3
											? buttonTableData3
											: buttonTableData4
							}
						/>
					</div>
				))}
			</div>
			<div className="plt-token-contents">
				<TabNavigation tabs={tabData} title={"CONTENTS"} activeIndex={activeSideTab} onTabClick={handleTabClick} />
			</div>
		</div>
	);
};

export default TokenButtonComponent;
