import UserImage from "assets/images/png/userAvatar.png";
import EditButton from "components/Buttons/EditButton";
import ConfirmDialog from "components/Common/Confirm/ConfirmDialog";
import Dropdown from "components/Common/Dropdown/Dropdown";
import PublishAlert from "components/Common/PublishAlert/PublishAlert";
import ThumbnailModal from "components/Home/FileUpload/ThumbnailModal";
import Tooltip from "components/Tooltip";
import UserMenu from "components/UserMenu";
import AuthAction from "components/auth/AuthAction";
import Permission from "components/auth/Permission";
import { getUser } from "hooks/storageUtils";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { changeThumbnail, deleteDesignSystemImage, updateToken } from "../../../api";
import { useGlobalContext } from "../../../context/GlobalContext";
import {
	checkForPublishing,
	getTokensFromLocalStorage,
	removeTokensfromLocalStorage,
} from "../../../hooks/storageUtils";
import "./Header.scss";
import ThemeToggle from "components/Common/ThemeToggle";

// Custom event name
const STORAGE_CHANGE_EVENT = "localStorageChange";

const Tab = React.memo(({ onSelectTab, index, isActive, title }) => (
	<li
		onClick={() => onSelectTab(index)}
		className={`${isActive ? "active" : ""} banner-tab-nav-item dis-pl-24 dis-pr-24 cursor-pointer`}
		role="tab"
		aria-selected={isActive ? "true" : "false"}
	>
		<span className="banner-tab-text plt-body-compact-md font-weight-500 dis-pt-8 dis-pb-12">{title}</span>
	</li>
));

const Header = ({ tabs, bannerData, selectedTab, setSelectedTab, setBannerImage }) => {
	const { id } = useParams();
	const [isThumbnailModalOpen, setThumbnailModalOpen] = useState(false);
	const [shouldShowPublishSpan, setShouldShowPublishSpan] = useState(checkForPublishing());
	const [error, setError] = useState("");
	const [isDropdownOpen, setIsDropdownOpen] = useState(false);

	const apiUrl = process.env.REACT_APP_SHEET_URL;
	const user = getUser();

	const {
		designSystemMeta,
		setDesignSystemMeta,
		setPusherSheetIsLoading,
		setLoading,
		setResetTokens,
		setButtonSelectedColor,
		theme,
	} = useGlobalContext();

	// useEffect(() => {
	//   if (designSystemMeta.banner) {
	//     setBannerImage(
	//       process.env.REACT_APP_SHEET_URL + designSystemMeta.banner_url
	//     );
	//   }
	// }, [designSystemMeta]);

	useEffect(() => {
		const handleStorageChange = () => {
			setShouldShowPublishSpan(checkForPublishing());
		};

		window.addEventListener(STORAGE_CHANGE_EVENT, handleStorageChange);
		return () => {
			window.removeEventListener(STORAGE_CHANGE_EVENT, handleStorageChange);
		};
	}, []);

	const handleSelectTab = (index) => {
		setSelectedTab(index);
	};

	const handlePublish = async () => {
		setPusherSheetIsLoading(true);
		if (checkForPublishing()) {
			try {
				let tokens = getTokensFromLocalStorage();
				await updateToken(tokens, id);
				removeTokensfromLocalStorage();
			} catch (error) {
				console.error("Failed to update token", error);
			}
		} else {
			removeTokensfromLocalStorage();
		}
	};

	const closeThumbnailModal = () => {
		setThumbnailModalOpen(false);
	};

	const handleThumbnailApply = async (file) => {
		if (!file) {
			// toast.warning("Please select an image");
			setError("Please select an image");
			return;
		}
		closeThumbnailModal();
		setIsDropdownOpen(false);
		try {
			const request = {
				image: file,
				admin_id: designSystemMeta.admin_id,
				design_system_id: designSystemMeta.id,
				type: "banner",
			};
			setLoading(true);
			const data = await changeThumbnail(designSystemMeta.id, request);
			toast.success(data.message, {
				onClose: () => {},
				autoClose: 1000,
			});
			if (setBannerImage) {
				setBannerImage(data.data.banner_url);
			}
			updateStateWithBanner(data.data);
		} catch (error) {
			console.error("Failed to change banner", error);
			let errorMessage =
				error.response && error.response.data.message
					? error.response.data.message
					: error.message || "An unexpected error occurred";
			toast.error(errorMessage);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		document.documentElement.setAttribute("data-theme", theme);
	}, [theme]);

	// const handleThemeChange = (value) => {
	// 	const newTheme = value;
	// 	localStorage.setItem("theme", newTheme);
	// 	setTheme(newTheme);
	// 	document.documentElement.setAttribute("data-theme", newTheme);
	// };

	const updateStateWithBanner = (data) => {
		const newDesignSystemMeta = { ...designSystemMeta };
		newDesignSystemMeta.banner = data.banner;
		newDesignSystemMeta.banner_url = data.banner_url;
		setDesignSystemMeta(newDesignSystemMeta);
	};

	const handleDeleteBannerImg = async () => {
		setIsDropdownOpen(false);
		const type = "banner";
		// if (confirm("Are you sure to delete") == true) {
		try {
			setLoading(true);
			const data = await deleteDesignSystemImage(designSystemMeta.id, type);
			updateStateWithBanner(data.data);
			toast.success(data.message);
		} catch (error) {
			toast.error(error.message);
			console.error("Failed delete image", error);
		} finally {
			setLoading(false);
			setDeleteBannerImgModalOpen(false);
		}
		//}
	};
	const [isDeleteBannerImgModalOpen, setDeleteBannerImgModalOpen] = useState(false);
	const closeDeleteBannerImgModal = () => {
		setDeleteBannerImgModalOpen(false);
	};
	const [textColorClass, setTextColorClass] = useState("");

	// Function to calculate text color based on CSS variable value
	const calculateTextColorClass = () => {
		const rootStyles = getComputedStyle(document.documentElement);
		const hexColor = rootStyles.getPropertyValue("--dss-color-primary-600").trim();

		if (!hexColor) return "";

		const rgb = {
			r: parseInt(hexColor.slice(1, 3), 16),
			g: parseInt(hexColor.slice(3, 5), 16),
			b: parseInt(hexColor.slice(5, 7), 16),
		};

		const brightness = Math.round((rgb.r * 299 + rgb.g * 587 + rgb.b * 114) / 1000);
		return brightness > 125 ? "plt-theme-pallette-item-dark" : "plt-theme-pallette-item-light";
	};

	useEffect(() => {
		const updateTextColorClass = () => {
			const newClass = calculateTextColorClass();
			setTextColorClass(newClass);
		};
		updateTextColorClass();

		const observer = new MutationObserver(() => updateTextColorClass());
		observer.observe(document.documentElement, {
			attributes: true,
			attributeFilter: ["style"],
		});

		return () => observer.disconnect();
	}, []);

	return (
		<div
			className={`banner ${
				bannerData.bannerImage && bannerData.bannerImage !== "/storage/banners/" ? "has-image" : ""
			}`}
			style={{
				backgroundImage:
					bannerData.bannerImage && bannerData.bannerImage !== "/storage/banners/"
						? `url(${apiUrl}${bannerData.bannerImage})`
						: "",
			}}
		>
			<div className="banner-header-wrapper">
				<ThemeToggle></ThemeToggle>
				<div className="tab-profile-dropdown">
					<UserMenu UserImage={user?.image ? `${apiUrl}${user?.image_url}` : UserImage} showDomain={false} />
				</div>
				<h2 className={`banner-head dis-mb-16 plt-heading-3xl ${textColorClass}`}>{bannerData.heading}</h2>
				<p className={`banner-desc plt-body-lg  ${textColorClass}`}>{bannerData.description}</p>
				<AuthAction>
					<Permission designSystem={designSystemMeta}>
						<div className="banner-edit-icon">
							<Tooltip position="left" arrow="lft-center" text="Change image">
								<div onClick={() => setIsDropdownOpen((prev) => !prev)}>
									<EditButton />
								</div>
							</Tooltip>
							<Dropdown isOpen={isDropdownOpen} onToggle={setIsDropdownOpen} customClass="" trigger={isDropdownOpen}>
								<ul>
									<li
										className="dropdown-item plt-ui-text-sm-medium plt-font-color-primary cursor-pointer"
										onClick={() => {
											setThumbnailModalOpen(true);
											setIsDropdownOpen(false);
										}}
									>
										Change image
									</li>
									<li
										className="dropdown-item plt-ui-text-sm-medium plt-font-color-primary cursor-pointer"
										onClick={() => {
											setDeleteBannerImgModalOpen(true);
										}}
									>
										<span className="dropdown-item-icon dis-mr-8">Delete image</span>
									</li>
								</ul>
							</Dropdown>
						</div>
					</Permission>
				</AuthAction>
			</div>
			{tabs.length > 0 && (
				<div>
					<div className="banner-tab-wrapper d-flex">
						<ul className="banner-tab-nav-links d-flex" role="tablist">
							{tabs.map((tabTitle, index) => (
								<Tab
									key={index}
									onSelectTab={handleSelectTab}
									index={index}
									isActive={selectedTab === index}
									title={tabTitle}
								/>
							))}
						</ul>
					</div>

					{shouldShowPublishSpan && (
						<PublishAlert
							show={shouldShowPublishSpan}
							handleCancel={() => {
								setResetTokens(true);
								removeTokensfromLocalStorage();
								setButtonSelectedColor({});
								setShouldShowPublishSpan(false);
							}}
							handlePublish={handlePublish}
							shouldShowPublishSpan={shouldShowPublishSpan}
						/>
					)}
				</div>
			)}
			<ThumbnailModal
				isOpen={isThumbnailModalOpen}
				onRequestClose={closeThumbnailModal}
				onApply={handleThumbnailApply}
				title="Change banner image"
				data={designSystemMeta}
				error={error}
				aspectRatio={837 / 420.789}
			/>

			<ConfirmDialog
				isOpen={isDeleteBannerImgModalOpen}
				onRequestClose={closeDeleteBannerImgModal}
				onApply={handleDeleteBannerImg}
				title="Remove this banner image?"
				message="The image you have uploaded will be removed."
			/>
		</div>
	);
};

export default React.memo(Header);
