import React, { useState } from "react";
import "./Principles.scss";
import TabNavigation from "../TabNavigation";
import PrinciplesImg from "../../../assets/images/png/PrinciplesImg.png";
import PrinciplesBtmImg from "../../../assets/images/png/PrinciplesBtmImg.png";

const Principles = () => {
	const tabData = ["What are our core values?", "Foundational principles"];
	const [activeTab, setActiveTab] = useState(0);
	const [selectedTab, setSelectedTab] = useState(0);

	const handleHeaderTabClick = (id) => {
		setSelectedTab(id);
	};

	const handleTabClick = (index) => {
		setActiveTab(index);
	};

	return (
		<div className="d-flex justify-content-between w-full">
			<div className="tab-content-container">
				<div className="plt-content-block dis-mb-24">
					<div className="plt-content-block-top d-flex align-items-center dis-mb-8">
						<h6 className="plt-contents-head plt-heading-md plt-font-color-primary">What are our core values?</h6>
					</div>
					<p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
						The design system find its foundation in our core values. Our core values—simplicity, accessibility, and
						innovation—drive every design decision we make. They serve as the foundation for creating products that
						resonate with users and stand the test of time.
					</p>
				</div>
				<div className="plt-img-wrapper d-flex align-items-center justify-content-center  dis-mb-48">
					<img src={PrinciplesImg} alt="userimage" className="w-100" />
				</div>
				<div className="plt-content-block dis-mb-24">
					<div className="plt-content-block-top d-flex align-items-center dis-mb-8">
						<h6 className="plt-contents-head plt-heading-md plt-font-color-primary">Foundational principles</h6>
					</div>
					<p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
						User-centricity, consistency, and scalability - our design system is built with these in mind.
					</p>
				</div>
				<div className="plt-content-block dis-mb-24">
					<div className="plt-content-block-top d-flex align-items-center dis-mb-8">
						<h6 className="plt-contents-head plt-heading-sm plt-font-color-primary">User-centricity</h6>
					</div>
					<p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
						At the heart of our design system is a commitment to understanding and prioritizing user needs. User
						research and empathy guide our decisions, ensuring our products are intuitive and valuable.
					</p>
				</div>
				<div className="plt-content-block dis-mb-24">
					<div className="plt-content-block-top d-flex align-items-center dis-mb-8">
						<h6 className="plt-contents-head plt-heading-sm plt-font-color-primary">Consistency</h6>
					</div>
					<p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
						Consistency breeds familiarity. We maintain a consistent design language, from color schemes to interaction
						patterns, creating a seamless experience that users can rely on across all touchpoints.
					</p>
				</div>
				<div className="plt-content-block dis-mb-16">
					<div className="plt-content-block-top d-flex align-items-center dis-mb-8">
						<h6 className="plt-contents-head plt-heading-sm plt-font-color-primary">Scalability</h6>
					</div>
					<p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
						Our design system is built with scalability in mind. As our products and user base grow, the system adapts
						gracefully, offering a robust framework that supports expansion without compromising coherence.
					</p>
				</div>
				<div className="plt-img-wrapper d-flex align-items-center justify-content-center  dis-mb-48">
					<img src={PrinciplesBtmImg} alt="userimage" className="w-100" />
				</div>
			</div>
			<div className="plt-token-contents ">
				<TabNavigation tabs={tabData} title={"CONTENTS"} activeIndex={activeTab} onTabClick={handleTabClick} />
			</div>
		</div>
	);
};

export default Principles;
