import React, { useState } from "react";
import "./UsageButtonComponent.scss";
import ComponentCard from "../../../Common/ComponentCard";
import PrimaryButton from "../../../Common/PrimaryButton";
import { ColorRing } from "react-loader-spinner";
import { useGlobalContext } from "../../../../context/GlobalContext";
import TabNavigation from "../../TabNavigation";
import BtnDetail from "../../../../assets/images/png/BtnDetail.png";
import ComponentButtonCard from "../../../Common/ComponentButtonCard";
import GuidelineCard from "../../../Common/Guidelines/GuidelineCard";
import { ComponentUsagePrimaryDatas } from "../../../../assets/data/data";
import useScrollManagement from "hooks/useScrollManagement";
import DetailItem from "components/DetailItem";

// import { DropdownArrow } from "../../../../components/Icons";
// import BodyTextTable from "./BodyTextTable";
// import TypographyTable from "./TypographyTable";

const details = [
	{
		number: 1,
		title: "Label",
		description:
			"Text describing the button action. Use action verbs or phrases to tell the user what will happen next, and follow the button label content guidelines.",
	},
	{
		number: 2,
		title: "Button",
		description:
			"Most buttons don’t need an icon. Use an icon to add additional affordance where the icon has a clear and well-established meaning.",
	},
	{
		number: 3,
		title: "Icon (optional)",
		description:
			" Most buttons don’t need an icon. Use an icon to add additional affordance where the icon has a clear and well-established meaning.",
	},
];

const UsageButtonComponent = () => {
	const { isLoading } = useGlobalContext();
	const tabData = ["Anatomy", "Types of buttons states", "Content guidelines"];
	// const [selectedTab, setSelectedTab] = useState(0);

	const [activeSideTab, setActiveSideTab] = useState(0);

	const sectionRefs = React.useRef(tabData.map(() => React.createRef()));

	const scrollToSection = useScrollManagement(sectionRefs.current, activeSideTab, setActiveSideTab, "tab-content-body");

	const handleTabClick = (index) => {
		setActiveSideTab(index);
		scrollToSection(index);
	};

	return (
		<div className="tab-content-wrapper d-flex justify-content-between">
			<div className="tab-content-container">
				<div className="plt-content-block">
					<div className="plt-content-wrapper dis-mb-24">
						<h6
							ref={sectionRefs.current[0]}
							className="plt-contents-head plt-heading-md plt-font-color-primary dis-mb-8"
						>
							Anatomy
						</h6>
						<p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
							Buttons are fundamental elements in user interfaces, serving as interactive components that facilitate
							user actions and engagement.
						</p>
					</div>
					<div className="plt-btn-detail-wrapper d-flex align-items-center justify-content-center">
						<img src={BtnDetail} alt="userimage" />
					</div>
					<p className="plt-body-md dis-mt-8 dis-mb-24">
						Buttons typically have a label and can include an icon before or after the label.
					</p>

					<ul className="plt-btn-detail-set dis-mb-40 dis-mt-24">
						{details.map((detail) => (
							<DetailItem
								key={detail.number}
								number={detail.number}
								title={detail.title}
								description={detail.description}
							/>
						))}
					</ul>

					<div className="plt-content-wrapper dis-mb-24">
						<h6
							ref={sectionRefs.current[1]}
							className="plt-contents-head plt-heading-md plt-font-color-primary dis-mb-8"
						>
							Types of buttons states
						</h6>
						<p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
							Buttons are fundamental elements in user interfaces, serving as interactive components that facilitate
							user actions and engagement.
						</p>
					</div>

					{ComponentUsagePrimaryDatas.map((data, index) => (
						<div key={index} className="dis-mb-40">
							<div className="plt-content-wrapper dis-mb-24">
								<h6 className="plt-contents-head plt-heading-sm plt-font-color-primary dis-mb-8">{data.title}</h6>
								<p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
									{data.description}
								</p>
							</div>
							<div className="plt-btn-box-wrapper d-flex justify-content-between flex-wrap">
								<div className="plt-btn-box">
									<h4 className="plt-character-title plt-font-color-primary plt-heading-xs dis-mb-8">
										{data.sub_title_1}
									</h4>
									<ComponentButtonCard
										state={"default"}
										buttonType={data.title.toLowerCase() === "outline" ? "outlined" : data.title.toLowerCase()}
									/>
								</div>
								<div className="plt-btn-box">
									<h4 className="plt-character-title plt-font-color-primary plt-heading-xs dis-mb-8">
										{data.sub_title_2}
									</h4>
									<ComponentButtonCard
										state={"hovered"}
										buttonType={data.title.toLowerCase() === "outline" ? "outlined" : data.title.toLowerCase()}
									/>
								</div>
								<div className="plt-btn-box">
									<h4 className="plt-character-title plt-font-color-primary plt-heading-xs dis-mb-8">
										{data.sub_title_3}
									</h4>
									<ComponentButtonCard
										state={"pressed"}
										buttonType={data.title.toLowerCase() === "outline" ? "outlined" : data.title.toLowerCase()}
									/>
								</div>
								<div className="plt-btn-box">
									<h4 className="plt-character-title plt-font-color-primary plt-heading-xs dis-mb-8">
										{data.sub_title_4}
									</h4>
									<ComponentButtonCard
										state={"disabled"}
										buttonType={data.title.toLowerCase() === "outline" ? "outlined" : data.title.toLowerCase()}
									/>
								</div>
							</div>

							<p className={`plt-body-md plt-font-color-primary dis-mt-12 ${data.classname}`}>{data.detail}</p>
							{data.detail_list}
						</div>
					))}

					<div className="plt-content-wrapper dis-mb-24">
						<h6
							ref={sectionRefs.current[2]}
							className="plt-contents-head plt-heading-md plt-font-color-primary dis-mb-8"
						>
							Content guidelines
						</h6>
						<p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
							Buttons are fundamental elements in user interfaces, serving as interactive components that facilitate
							user actions and engagement.
						</p>
					</div>

					<div className="plt-content-wrapper dis-mb-24">
						<h6 className="plt-contents-head plt-heading-sm plt-font-color-primary dis-mb-8">
							Use sentence case capitalization
						</h6>
						<p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
							Only capitalize the first letter of the button and any proper nouns. Most feature names aren’t capitalized
							or considered proper nouns when following our capitalization guidance.
						</p>
					</div>

					<GuidelineCard />
				</div>
			</div>
			<div className="plt-token-contents">
				<TabNavigation tabs={tabData} title={"CONTENTS"} activeIndex={activeSideTab} onTabClick={handleTabClick} />
			</div>
		</div>
	);
};

export default UsageButtonComponent;
