import { tokenDropdown, uiTextTokens } from "api";
import { useGlobalContext } from "context/GlobalContext";
import React, { useEffect, useState } from "react";
import {
  ComponentBtnDatas,
  ComponentDangerDatas,
  ComponentFluidDatas,
  ComponentGostDatas,
  ComponentLinkDatas,
  ComponentOutlineDatas,
  ComponentPrimaryDatas,
  ComponentSecondaryDatas,
  FillBtnDatas,
  FillIconBtnDatas,
} from "../../../../assets/data/data";
import ComponentCard from "../../../../components/Common/ComponentCard";
import TabNavigation from "../../TabNavigation";
import "./PreviewButtonComponent.scss";

import Dropdown from "components/Dropdown";
import { EditIcon } from "components/Icons";
import ColorTokenModal from "components/TokenModals/ColorTokenModal";
import PaddingTokenModal from "components/TokenModals/PaddingTokenModal";
import RadiusTokenModal from "components/TokenModals/RadiusTokenModal";
import TypoTokenModal from "components/TokenModals/TypoTokenModal";

import ShadowTokenModal from "components/TokenModals/ShadowTokenModal";
import TextTokenModal from "components/TokenModals/TextTokenModal";
import { getCssVariableValue } from "helpers/style";
import { updateCSSVariablesAllObj } from "helpers/token";
import { addToLocalStorage } from "hooks/storageUtils";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useParams } from "react-router-dom";
import useTokensData from "../../../../assets/data/buttonTokenData";
import useScrollManagement from "../../../../hooks/useScrollManagement";
import colorSectionData from "../../../../pages/styles/colorSectionData";
import CommonModal from "../../../Common/CommonModal";
import PrimaryButton from "../../../Common/PrimaryButton";
const getSpacingValues = (selectedTokenData, buttonState, buttonSize) => {
  const spacingData =
    selectedTokenData[buttonState?.value.toLowerCase()]?.spacing;
  if (!spacingData) {
    return { horizontal: "", vertical: "" };
  }
  const spacingValue = spacingData[buttonSize]?.value || "";

  const values = spacingValue.split(" ").map((val) => val.replace(/[{}]/g, "")); // Remove braces

  // Assign default values to vertical and horizontal
  const [vertical, horizontal = vertical] = values;

  // Function to get CSS variable value
  const getCssVariableValue = (variable) => {
    const element = document.documentElement; // or any specific element
    return getComputedStyle(element).getPropertyValue(variable)?.trim();
  };

  const horizontalVar = `--dss-${horizontal?.replace(".", "-")}`;
  const verticalVar = `--dss-${vertical?.replace(".", "-")}`;

  return {
    horizontal: {
      label: horizontal,
      value: getCssVariableValue(horizontalVar),
    },
    vertical: {
      label: vertical,
      value: getCssVariableValue(verticalVar),
    },
  };
};

const PreviewButtonComponent = ({ setSelectedTab }) => {
  const colorSections = colorSectionData();

  const { sheetIsLoading, isLoading, setButtonSelectedColor, designSystem } =
    useGlobalContext();

  const [selectedTokenData, setSelectedTokenData] = useState({});
  const tabData = [
    "Primary",
    "Secondary",
    "Outline",
    "Danger",
    "Ghost",
    "Link",
  ];

  const AllUsage = () => (
    <p className='plt-character-des plt-body-compact-sm dis-pt-12 dis-mb-32'>
      See all states{" "}
      <span
        onClick={() => setSelectedTab(1)}
        className='plt-text-underline plt-body-compact-sm plt-font-color-primary font-weight-500 cursor-pointer'
      >
        usage
      </span>
    </p>
  );

  const [selectedtextdecoration, setSelectedtextdecoration] =
    useState("Underline");
  const textdecoration = [
    { value: "Underline", name: "Underline" },
    { value: "Overline", name: "Overline" },
    { value: "line-through", name: "line-through" },
    { value: "None", name: "None" },
  ];
  const handleTextdecorationChange = (name, value) =>
    setSelectedtextdecoration(value);

  const [activeSideTab, setActiveSideTab] = useState(0);

  const sectionRefs = React.useRef(tabData.map(() => React.createRef()));

  const scrollToSection = useScrollManagement(
    sectionRefs.current,
    activeSideTab,
    setActiveSideTab,
    "tab-content-body"
  );

  const handleTabClick = (index) => {
    setActiveSideTab(index);
    scrollToSection(index);
  };

  const [spaceHValue, setSpaceHValue] = useState({ label: "", value: "" });
  const [spaceVValue, setSpaceVValue] = useState({ label: "", value: "" });
  const tokensData = useTokensData();

  const { id } = useParams();
  const [isModalVisible, setModalVisibility] = useState(false);
  const buttonStates = [
    { value: "Default", name: "Default" },
    { value: "Hovered", name: "Hovered" },
    { value: "Pressed", name: "Pressed" },
    { value: "Disabled", name: "Disabled" },
  ];
  const [buttonState, setButtonState] = useState(() => buttonStates[0]);
  const [buttonSize, setButtonSize] = useState();
  const [isColorTokenModalOpen, setColorTokenModalOpen] = useState(false);
  const [isPaddingTokenModalOpen, setPaddingTokenModalOpen] = useState(false);
  const [isShadowTokenModalOpen, setShadowTokenModalOpen] = useState(false);
  const [isTextTokenModalOpen, setTextTokenModalOpen] = useState(false);
  const [isTypoTokenModalOpen, setTypoTokenModalOpen] = useState(false);
  const [tokenType, setTokenType] = useState("");
  const [isRadiusTokenModalOpen, setRadiusTokenModalOpen] = useState(false);
  const [tokenState, setTokenState] = useState({ key: "", value: "" });
  const [selectedClassName, setSelectedClassName] = useState("");
  const [componentInfo, setComponentInfo] = useState({});
  const [selectedTypography, setSelectedTypography] = useState([]);
  //shadow change function and state
  const [selectedShadow, setSelectedShadow] = useState(
    selectedTokenData[buttonState?.value.toLowerCase()]?.elevation?.[buttonSize]
  );
  const [boxShadowStyle, setBoxShadowStyle] = useState([]);

  useEffect(() => {
    setSelectedShadow(
      selectedTokenData[buttonState?.value.toLowerCase()]?.elevation?.[
        buttonSize
      ]
    );
  }, [selectedTokenData]);

  useEffect(() => {
    const constructBoxShadow = (shadow) => {
      if (!shadow) return "";

      const { x, y, blur, spread, color } = shadow;
      return `${x} ${y} ${blur} ${spread} ${getCssVariableValue(color)}`;
    };
    setBoxShadowStyle(constructBoxShadow(selectedShadow?.value));
  }, [selectedTokenData, selectedShadow]);

  const radiusValues = designSystem?.core?.borderRadius
    ? Object.keys(designSystem?.core?.borderRadius).map((key, index) => ({
        id: key,
        key: `{borderRadius.${key}}`,
        label: `dss-border-radius-${key}`,
        value: designSystem?.core?.borderRadius[key]?.value,
      }))
    : [];

  // Extract the radius key from selectedTokenData and remove curly braces

  const [selectedRadius, setSelectedRadius] = useState({
    key: designSystem?.light?.borderRadius?.button?.[buttonSize]?.value,
  });

  useEffect(() => {
    if (selectedTokenData && buttonState && buttonSize && designSystem) {
      const { horizontal, vertical } = getSpacingValues(
        selectedTokenData,
        buttonState,
        buttonSize
      );
      setSpaceHValue(horizontal);
      setSpaceVValue(vertical);
    }
    setSelectedRadius({
      key: designSystem?.light?.borderRadius?.button?.[buttonSize]?.value,
    });
  }, [selectedTokenData, buttonState, buttonSize, designSystem]);

  const spaces = designSystem?.core?.spacing
    ? Object.keys(designSystem?.core?.spacing).map((key) => ({
        label: `spacing.${key}`,
        value: designSystem?.core?.spacing[key]?.value,
      }))
    : [];

  const handleHorizontalChange = (selectedOption) => {
    setSpaceHValue(selectedOption);
  };

  const handleVerticalChange = (selectedOption) => {
    setSpaceVValue(selectedOption);
  };

  const handleRadiusChange = (item) => {
    setSelectedRadius(item);
  };

  const handleStateChange = (name, value) => {
    setButtonState(value);
    console.log(selectedTokenData, "selectedTokenData");
    console.log(selectedTokenData[buttonState?.value.toLowerCase()]?.border);
  };

  const onApplyChanges = async () => {
    addToLocalStorage([tokenState]);
    setColorTokenModalOpen(false);
  };

  const showModal = (variant, size, tokenData, disabled = false) => {
    setModalVisibility(true);
    setSelectedClassName(variant);
    setComponentInfo({
      variant: variant,
      size: size,
      disabled: disabled,
    });
    setSelectedTokenData(tokenData);
  };

  const [selectedToken, setSelectedToken] = useState(null);

  useEffect(() => {
    const initialToken =
      selectedTokenData &&
      selectedTokenData[buttonState?.value.toLowerCase()]?.typography?.[
        buttonSize
      ];
    setSelectedToken(initialToken);
  }, [selectedTokenData]);

  const { setLoading, pusherIsLoading } = useGlobalContext();
  const [data, setData] = useState({});

  const [uiData, setUiData] = useState({});
  const [elevationData, setElevationData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const types =
        "fontWeights,fontSizes,lineHeights,letterSpacing,fontFamilies,textDecoration";
      try {
        const data = await tokenDropdown(id, types);
        setData(data);
      } catch (error) {
        console.error("Failed to fetch design system", error);
      }
    };

    const fetchUItext = async () => {
      try {
        const user = JSON.parse(localStorage.getItem("user"));
        const userId = user?.id;
        setLoading(true);
        if (userId) {
          const { data } = await uiTextTokens(id);
          setUiData(data);
        } else {
          console.error("User ID not found in localStorage");
        }
      } catch (error) {
        console.error("Failed to fetch design systems", error);
      } finally {
        setLoading(false);
      }
    };
    if (!pusherIsLoading) {
      fetchData();
      fetchUItext();
    }
  }, [pusherIsLoading]);
  const filterUiTexts = (data, filter) =>
    data &&
    Object.values(data)?.filter((ui) => {
      return ui.key.includes(filter);
    });

  const uiTextData = filterUiTexts(uiData, "core.font.uitext");

  useEffect(() => {
    setElevationData(designSystem?.core?.boxShadow);
  }, [buttonState, designSystem]);

  const handleTokenChange = (token) => {
    const varNamePrefix =
      selectedTokenData[buttonState?.value.toLowerCase()]?.typography?.[
        buttonSize
      ]?.varName;

    const cssVariables = {
      [`${varNamePrefix}-font-family`]: token.fontFamily.value,
      [`${varNamePrefix}-font-weight`]: token.fontWeight.value,
      [`${varNamePrefix}-font-size`]: token.fontSize.value,
      [`${varNamePrefix}-line-height`]: token.lineHeight.value,
    };
    updateCSSVariablesAllObj(cssVariables);
    setSelectedToken({ value: token.data, data: token });
    setSelectedTypography(token);
  };

  const updateCSSVariables = (varName, value) => {
    document.documentElement.style.setProperty(varName, value);
  };

  const handleApplyPaddingChange = () => {
    const tokenData = [
      {
        key: selectedTokenData[buttonState?.value.toLowerCase()]?.spacing?.[
          buttonSize
        ]?.key,
        value: `{${spaceVValue.label}} {${spaceHValue.label}}`,
      },
    ];

    updateCSSVariables(
      selectedTokenData[buttonState?.value.toLowerCase()]?.spacing?.[buttonSize]
        ?.varName,
      `${spaceVValue?.value} ${spaceHValue?.value}`
    );
    addToLocalStorage(tokenData);

    setPaddingTokenModalOpen(false);
  };

  const handleApplyShadowChange = () => {
    const tokenData = [
      {
        key: selectedTokenData[buttonState?.value.toLowerCase()]?.elevation?.[
          buttonSize
        ]?.key,
        value: `{${selectedShadow.key}}`,
      },
    ];

    updateCSSVariables(
      selectedTokenData[buttonState?.value.toLowerCase()]?.spacing?.[buttonSize]
        ?.varName,
      `${spaceVValue?.value} ${spaceHValue?.value}`
    );
    addToLocalStorage(tokenData);

    setShadowTokenModalOpen(false);
  };

  const handleApplyTextChange = () => {
    const tokenData = [
      {
        key: selectedTokenData[buttonState?.value.toLowerCase()]?.spacing?.[
          buttonSize
        ]?.key,
        value: `{${spaceVValue.label}} {${spaceHValue.label}}`,
      },
    ];

    updateCSSVariables(
      selectedTokenData[buttonState?.value.toLowerCase()]?.spacing?.[buttonSize]
        ?.varName,
      `${spaceVValue?.value} ${spaceHValue?.value}`
    );
    addToLocalStorage(tokenData);

    setTextTokenModalOpen(false);
  };

  const handleApplyRadiusChange = () => {
    const tokenData = [
      {
        key: selectedTokenData[buttonState?.value.toLowerCase()]?.radius?.[
          buttonSize
        ].key,
        value: `${selectedRadius.key}`,
      },
    ];
    updateCSSVariables(
      selectedTokenData[buttonState?.value.toLowerCase()]?.radius?.[buttonSize]
        .varName,
      selectedRadius.value
    );
    addToLocalStorage(tokenData);

    setRadiusTokenModalOpen(false);
  };

  const handleApplyTypographyChange = () => {
    const key =
      selectedTokenData[buttonState?.value.toLowerCase()]?.typography?.[
        buttonSize
      ]?.key;

    const tokenData = [
      {
        key,
        value: selectedTypography.data,
      },
    ];

    addToLocalStorage(tokenData);
    setTypoTokenModalOpen(false);
  };

  const isEqual = (obj1, obj2) => {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  };

  console.log(ComponentPrimaryDatas, "ComponentPrimaryDatas");
  return (
    <div className='tab-content-wrapper d-flex justify-content-between'>
      <div className='tab-content-container'>
        <div className='plt-content-wrapper dis-mb-24'>
          <div className='plt-content-block-top d-flex justify-content-between align-items-center dis-mb-8'>
            <h6
              ref={sectionRefs.current[0]}
              className='plt-contents-head plt-heading-md plt-font-color-primary'
            >
              Primary
            </h6>
          </div>
          <p className='plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400'>
            Use a primary button to call attention to an action on a form or to
            highlight the strongest call to action on a page. Primary buttons
            should only appear once per container (not including the application
            header or in a modal dialog). Not every screen requires a primary
            button.
          </p>
        </div>
        {ComponentPrimaryDatas.map((data) => (
          <div key={data.id} className='plt-content-wrapper'>
            <div className='plt-content-wrapper-block dis-mb-24'>
              <h6 className='plt-contents-head plt-heading-sm plt-font-color-primary dis-mb-8'>
                {data.title}
              </h6>
              <p className='plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400'>
                {data.description}
              </p>
            </div>
            <ComponentCard
              code={data.variant}
              edit={true}
              showCode={true}
              editClick={() => {
                if (data?.title === "disabled") {
                  // setButtonSize("large");
                  setButtonState("Disabled");
                } else {
                  setButtonSize((prev) => (prev = data?.title?.toLowerCase()));
                }
                // buttonSize &&
                showModal(
                  data.variant,
                  data.size,
                  tokensData[0]?.primary,
                  data.disabled
                );
              }}
            >
              {isLoading || sheetIsLoading ? (
                <Skeleton height={36} width={150} />
              ) : (
                <PrimaryButton
                  size={data.size}
                  variant={data.variant}
                  disabled={data.disabled}
                  icon={false}
                />
              )}
            </ComponentCard>
            <AllUsage />
          </div>
        ))}
        <div className='plt-content-wrapper dis-mb-24'>
          <div className='plt-content-block-top d-flex justify-content-between align-items-center dis-mb-8'>
            <h6 className='plt-contents-head plt-heading-md plt-font-color-primary'>
              Fluid width
            </h6>
          </div>
          <p className='plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400'>
            Use a primary button to call attention to an action on a form or to
            highlight the strongest call to action on a page. Primary buttons
            should only appear once per container (not including the application
            header or in a modal dialog). Not every screen requires a primary
            button.
          </p>
        </div>
        {ComponentFluidDatas.map((data) => (
          <div key={data.id} className='plt-content-wrapper'>
            <div className='plt-content-wrapper-block dis-mb-24'>
              <h6 className='plt-contents-head plt-heading-sm plt-font-color-primary dis-mb-8'>
                {data.title}
              </h6>
              <p className='plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400'>
                {data.description}
              </p>
            </div>
            <ComponentCard
              edit={true}
              code={data.variant}
              showCode={true}
              editClick={() => {
                showModal(data.variant, data.size, tokensData[0]?.primary);
                setButtonSize("large");

                if (data?.title === "disabled") {
                  setButtonState("Disabled");
                }
              }}
            >
              <PrimaryButton
                size={data.size}
                variant={data.variant}
                disabled={data.disabled}
                icon={false}
                fluid={true}
              />
            </ComponentCard>
            <AllUsage />
          </div>
        ))}
        <div className='plt-content-wrapper dis-mb-24'>
          <h6
            ref={sectionRefs.current[1]}
            className='plt-contents-head plt-heading-md plt-font-color-primary dis-mb-8'
          >
            Secondary
          </h6>
          <p className='plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400'>
            Use a primary button to call attention to an action on a form or to
            highlight the strongest call to action on a page. Primary buttons
            should only appear once per container (not including the application
            header or in a modal dialog). Not every screen requires a primary
            button.
          </p>
        </div>
        {ComponentSecondaryDatas.map((data) => (
          <div key={data.id} className='plt-content-wrapper'>
            <div className='plt-content-wrapper-block dis-mb-24'>
              <h6 className='plt-contents-head plt-heading-sm plt-font-color-primary dis-mb-8'>
                {data.title}
              </h6>
              <p className='plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400'>
                {data.description}
              </p>
            </div>
            <ComponentCard
              edit={true}
              code={data.variant}
              showCode={true}
              editClick={() => {
                showModal(data.variant, data.size, tokensData[2]?.default);
                if (data?.title === "disabled") {
                  setButtonSize("large");
                  setButtonState("Disabled");
                } else {
                  setButtonSize(data?.title?.toLowerCase());
                }
              }}
            >
              <PrimaryButton
                size={data.size}
                variant={data.variant}
                disabled={data.disabled}
                icon={false}
              />
            </ComponentCard>{" "}
            <AllUsage />
          </div>
        ))}
        <div
          ref={sectionRefs.current[2]}
          className='plt-content-wrapper dis-mb-24'
        >
          <h6 className='plt-contents-head plt-heading-md plt-font-color-primary dis-mb-8'>
            Outline
          </h6>
          <p className='plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400'>
            Use a primary button to call attention to an action on a form or to
            highlight the strongest call to action on a page. Primary buttons
            should only appear once per container (not including the application
            header or in a modal dialog). Not every screen requires a primary
            button.
          </p>
        </div>
        {ComponentOutlineDatas.map((data) => (
          <div key={data.id} className='plt-content-wrapper'>
            <div className='plt-content-wrapper-block dis-mb-24'>
              <h6 className='plt-contents-head plt-heading-sm plt-font-color-primary dis-mb-8'>
                {data.title}
              </h6>
              <p className='plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400'>
                {data.description}
              </p>
            </div>
            <ComponentCard
              code={data.variant}
              edit={true}
              showCode={true}
              editClick={() => {
                showModal(data.variant, data.size, tokensData[4]?.stroke);
                if (data?.title === "disabled") {
                  setButtonSize("large");
                  setButtonState("Disabled");
                } else {
                  setButtonSize(data?.title?.toLowerCase());
                }
              }}
            >
              <PrimaryButton
                size={data.size}
                variant={data.variant}
                disabled={data.disabled}
                icon={false}
              />
            </ComponentCard>
            <AllUsage />
          </div>
        ))}
        <div className='plt-content-wrapper dis-mb-24'>
          <h6
            ref={sectionRefs.current[3]}
            className='plt-contents-head plt-heading-md plt-font-color-primary dis-mb-8'
          >
            Danger
          </h6>
          <p className='plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400'>
            Use a primary button to call attention to an action on a form or to
            highlight the strongest call to action on a page. Primary buttons
            should only appear once per container (not including the application
            header or in a modal dialog). Not every screen requires a primary
            button.
          </p>
        </div>
        {ComponentDangerDatas.map((data) => (
          <div key={data.id} className='plt-content-wrapper'>
            <div className='plt-content-wrapper-block dis-mb-24'>
              <h6 className='plt-contents-head plt-heading-sm plt-font-color-primary dis-mb-8'>
                {data.title}
              </h6>
              <p className='plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400'>
                {data.description}
              </p>
            </div>
            <ComponentCard
              edit={true}
              showCode={true}
              code={data.variant}
              editClick={() => {
                showModal(data.variant, data.size, tokensData[1]?.danger);
                if (data?.title === "disabled") {
                  setButtonSize("large");
                  setButtonState("Disabled");
                } else {
                  setButtonSize(data?.title?.toLowerCase());
                }
              }}
            >
              <PrimaryButton
                size={data.size}
                variant={data.variant}
                disabled={data.disabled}
                icon={false}
              />
            </ComponentCard>{" "}
            <AllUsage />
          </div>
        ))}
        <div className='plt-content-wrapper dis-mb-24'>
          <h6
            ref={sectionRefs.current[4]}
            className='plt-contents-head plt-heading-md plt-font-color-primary dis-mb-8'
          >
            Ghost
          </h6>
          <p className='plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400'>
            Use a primary button to call attention to an action on a form or to
            highlight the strongest call to action on a page. Primary buttons
            should only appear once per container (not including the application
            header or in a modal dialog). Not every screen requires a primary
            button.
          </p>
        </div>
        {ComponentGostDatas.map((data) => (
          <div key={data.id} className='plt-content-wrapper'>
            <div className='plt-content-wrapper-block dis-mb-24'>
              <h6 className='plt-contents-head plt-heading-sm plt-font-color-primary dis-mb-8'>
                {data.title}
              </h6>
              <p className='plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400'>
                {data.description}
              </p>
            </div>
            <ComponentCard
              edit={true}
              showCode={true}
              code={data.variant}
              editClick={() => {
                showModal(data.variant, data.size, tokensData[3]?.ghost);
                if (data?.title === "disabled") {
                  setButtonSize("large");
                  setButtonState("Disabled");
                } else {
                  setButtonSize(data?.title?.toLowerCase());
                }
              }}
            >
              <PrimaryButton
                onClick={() => {}}
                size={data.size}
                variant={data.variant}
                disabled={data.disabled}
                icon={false}
              />
            </ComponentCard>
            <AllUsage />
          </div>
        ))}
        <div className='plt-content-wrapper dis-mb-24'>
          <h6
            ref={sectionRefs.current[5]}
            className='plt-contents-head plt-heading-md plt-font-color-primary dis-mb-8'
          >
            Link
          </h6>
          <p className='plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400'>
            Use a primary button to call attention to an action on a form or to
            highlight the strongest call to action on a page. Primary buttons
            should only appear once per container (not including the application
            header or in a modal dialog). Not every screen requires a primary
            button.
          </p>
        </div>
        {ComponentLinkDatas.map((data) => (
          <div key={data.id} className='plt-content-wrapper'>
            <div className='plt-content-wrapper-block dis-mb-24'>
              <h6 className='plt-contents-head plt-heading-sm plt-font-color-primary dis-mb-8'>
                {data.title}
              </h6>
              <p className='plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400'>
                {data.description}
              </p>
            </div>
            <ComponentCard
              code={data.variant}
              edit={true}
              showCode={true}
              editClick={() => {
                showModal(data.variant, data.size, tokensData[5]?.link);
                if (data?.title === "disabled") {
                  setButtonSize("large");
                  setButtonState("Disabled");
                } else {
                  setButtonSize(data?.title?.toLowerCase());
                }
              }}
            >
              <PrimaryButton
                onClick={() => {}}
                size={data.size}
                variant={data.variant}
                disabled={data.disabled}
                icon={false}
              />
            </ComponentCard>
            <AllUsage />
          </div>
        ))}
        <div className='plt-content-wrapper dis-mb-24'>
          <h6 className='plt-contents-head plt-heading-md plt-font-color-primary dis-mb-8'>
            Button with icon
          </h6>
          <p className='plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400'>
            Use a primary button to call attention to an action on a form or to
            highlight the strongest call to action on a page. Primary buttons
            should only appear once per container (not including the application
            header or in a modal dialog). Not every screen requires a primary
            button.
          </p>
        </div>
        {ComponentBtnDatas.map((data) => (
          <div key={data.id} className='plt-content-wrapper'>
            <div className='plt-content-wrapper-block dis-mb-24'>
              <h6 className='plt-contents-head plt-heading-sm plt-font-color-primary dis-mb-8'>
                {data.title}
              </h6>
              <p className='plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400'>
                {data.description}
              </p>
            </div>
            <ComponentCard
              code={data.variant}
              showCode={true}
              edit={false}
              editClick={() => {
                showModal(data.variant, data.size);
                if (data?.title === "disabled") {
                  setButtonSize("large");
                  setButtonState("Disabled");
                } else {
                  setButtonSize(data?.title?.toLowerCase());
                }
              }}
            >
              <PrimaryButton
                onClick={() => {}}
                prefixIcon={data.prefixIcon}
                suffixIcon={data.suffixIcon}
                size={data.size}
                variant={data.variant}
                disabled={data.disabled}
                icon={true}
              />
            </ComponentCard>
            <AllUsage />
          </div>
        ))}
        <div className='plt-content-wrapper dis-mb-24'>
          <h6 className='plt-contents-head plt-heading-md plt-font-color-primary dis-mb-8'>
            Fill button
          </h6>
          <p className='plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400'>
            Use a primary button to call attention to an action on a form or to
            highlight the strongest call to action on a page. Primary buttons
            should only appear once per container (not including the application
            header or in a modal dialog). Not every screen requires a primary
            button.
          </p>
        </div>
        {FillBtnDatas.map((data) => (
          <div key={data.id} className='plt-content-wrapper'>
            <div className='plt-content-wrapper-block dis-mb-24'>
              <h6 className='plt-contents-head plt-heading-sm plt-font-color-primary dis-mb-8'>
                {data.title}
              </h6>
              <p className='plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400'>
                {data.description}
              </p>
            </div>
            <ComponentCard
              code={data.variant}
              showCode={true}
              edit={false}
              editClick={() => {
                showModal(data.variant, data.size);
                if (data?.title === "disabled") {
                  setButtonSize("large");
                  setButtonState("Disabled");
                } else {
                  setButtonSize(data?.title?.toLowerCase());
                }
              }}
            >
              <PrimaryButton
                onClick={() => {}}
                prefixIcon={data.prefixIcon}
                suffixIcon={data.suffixIcon}
                size={data.size}
                variant={data.variant}
                disabled={data.disabled}
                icon={true}
              />
            </ComponentCard>
            <AllUsage />
          </div>
        ))}
        <div className='plt-content-wrapper dis-mb-24'>
          <h6 className='plt-contents-head plt-heading-md plt-font-color-primary dis-mb-8'>
            Fill Icon button
          </h6>
          <p className='plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400'>
            Use a primary button to call attention to an action on a form or to
            highlight the strongest call to action on a page. Primary buttons
            should only appear once per container (not including the application
            header or in a modal dialog). Not every screen requires a primary
            button.
          </p>
        </div>
        {FillIconBtnDatas.map((data) => (
          <div key={data.id} className='plt-content-wrapper'>
            <div className='plt-content-wrapper-block dis-mb-24'>
              <h6 className='plt-contents-head plt-heading-sm plt-font-color-primary dis-mb-8'>
                {data.title}
              </h6>
              <p className='plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400'>
                {data.description}
              </p>
            </div>
            <ComponentCard
              code={data.variant}
              showCode={true}
              edit={false}
              editClick={() => {
                showModal(data.variant, data.size);
                if (data?.title === "disabled") {
                  setButtonSize("large");
                  setButtonState("Disabled");
                } else {
                  setButtonSize(data?.title?.toLowerCase());
                }
              }}
            >
              <PrimaryButton
                onClick={() => {}}
                prefixIcon={data.prefixIcon}
                suffixIcon={data.suffixIcon}
                size={data.size}
                variant={data.variant}
                disabled={data.disabled}
                icon={true}
								iconOnly={true}
								text={''}
              />
            </ComponentCard>
            <AllUsage />
          </div>
        ))}
				 <div className='plt-content-wrapper dis-mb-24'>
          <h6 className='plt-contents-head plt-heading-md plt-font-color-primary dis-mb-8'>
					Default Icon button
          </h6>
          <p className='plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400'>
            Use a primary button to call attention to an action on a form or to
            highlight the strongest call to action on a page. Primary buttons
            should only appear once per container (not including the application
            header or in a modal dialog). Not every screen requires a primary
            button.
          </p>
        </div>
        {FillIconBtnDatas.map((data) => (
          <div key={data.id} className='plt-content-wrapper'>
            <div className='plt-content-wrapper-block dis-mb-24'>
              <h6 className='plt-contents-head plt-heading-sm plt-font-color-primary dis-mb-8'>
                {data.title}
              </h6>
              <p className='plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400'>
                {data.description}
              </p>
            </div>
            <ComponentCard
              code={data.variant}
              showCode={true}
              edit={false}
              editClick={() => {
                showModal(data.variant, data.size);
                if (data?.title === "disabled") {
                  setButtonSize("large");
                  setButtonState("Disabled");
                } else {
                  setButtonSize(data?.title?.toLowerCase());
                }
              }}
            >
              <PrimaryButton
                onClick={() => {}}
                prefixIcon={data.prefixIcon}
                suffixIcon={data.suffixIcon}
                size={data.size}
                variant={'secondary'}
                disabled={data.disabled}
                icon={true}
								iconOnly={true}
								text={''}
              />
            </ComponentCard>
            <AllUsage />
          </div>
        ))}
				 <div className='plt-content-wrapper dis-mb-24'>
          <h6 className='plt-contents-head plt-heading-md plt-font-color-primary dis-mb-8'>
					Outline Icon button
          </h6>
          <p className='plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400'>
            Use a primary button to call attention to an action on a form or to
            highlight the strongest call to action on a page. Primary buttons
            should only appear once per container (not including the application
            header or in a modal dialog). Not every screen requires a primary
            button.
          </p>
        </div>
        {FillIconBtnDatas.map((data) => (
          <div key={data.id} className='plt-content-wrapper'>
            <div className='plt-content-wrapper-block dis-mb-24'>
              <h6 className='plt-contents-head plt-heading-sm plt-font-color-primary dis-mb-8'>
                {data.title}
              </h6>
              <p className='plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400'>
                {data.description}
              </p>
            </div>
            <ComponentCard
              code={data.variant}
              showCode={true}
              edit={false}
              editClick={() => {
                showModal(data.variant, data.size);
                if (data?.title === "disabled") {
                  setButtonSize("large");
                  setButtonState("Disabled");
                } else {
                  setButtonSize(data?.title?.toLowerCase());
                }
              }}
            >
              <PrimaryButton
                onClick={() => {}}
                prefixIcon={data.prefixIcon}
                suffixIcon={data.suffixIcon}
                size={data.size}
                variant={'outlined'}
                disabled={data.disabled}
                icon={true}
								iconOnly={true}
								text={''}
              />
            </ComponentCard>
            <AllUsage />
          </div>
        ))}
				 <div className='plt-content-wrapper dis-mb-24'>
          <h6 className='plt-contents-head plt-heading-md plt-font-color-primary dis-mb-8'>
					Danger Icon button
          </h6>
          <p className='plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400'>
            Use a primary button to call attention to an action on a form or to
            highlight the strongest call to action on a page. Primary buttons
            should only appear once per container (not including the application
            header or in a modal dialog). Not every screen requires a primary
            button.
          </p>
        </div>
        {FillIconBtnDatas.map((data) => (
          <div key={data.id} className='plt-content-wrapper'>
            <div className='plt-content-wrapper-block dis-mb-24'>
              <h6 className='plt-contents-head plt-heading-sm plt-font-color-primary dis-mb-8'>
                {data.title}
              </h6>
              <p className='plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400'>
                {data.description}
              </p>
            </div>
            <ComponentCard
              code={data.variant}
              showCode={true}
              edit={false}
              editClick={() => {
                showModal(data.variant, data.size);
                if (data?.title === "disabled") {
                  setButtonSize("large");
                  setButtonState("Disabled");
                } else {
                  setButtonSize(data?.title?.toLowerCase());
                }
              }}
            >
              <PrimaryButton
                onClick={() => {}}
                prefixIcon={data.prefixIcon}
                suffixIcon={data.suffixIcon}
                size={data.size}
                variant={'danger'}
                disabled={data.disabled}
                icon={true}
								iconOnly={true}
								text={''}
              />
            </ComponentCard>
            <AllUsage />
          </div>
        ))}
      </div>
      <CommonModal
        isOpen={isModalVisible}
        onRequestClose={() => {
          setModalVisibility(false);
          setButtonState(buttonStates[0]);
        }}
        title='Tokens'
        onApply={() => setModalVisibility(false)}
        onCancelText='Cancel'
        onApplyText='Apply'
        variant='modal-tertiary'
      >
        <div className='plt-modal-body-content'>
          <div className='plt-dropdown-secondary'>
            <div className='plt-select-dropdown-wrapper d-flex align-items-center justify-content-between'>
              <div className='plt-dropdown-wrapper plt-dropdown-wrapper-common'>
                {/* <CustomSelect
                  options={buttonStates}
                  selectedOption={buttonState}
                  onSelect={handleStateChange}
                /> */}
                <Dropdown
                  name='buttonState'
                  options={buttonStates}
                  selectedOption={buttonState}
                  onSelect={handleStateChange}
                />
              </div>
            </div>
          </div>
          <div className='plt-preview dis-mb-32'>
            <PrimaryButton
              // onClick={() => {}}
              style={{
                fontFamily: selectedToken?.data?.fontFamily?.value,
                fontSize: selectedToken?.data?.fontSize?.value,
                lineHeight: selectedToken?.data?.lineHeight?.value,
                fontWeight: selectedToken?.data?.fontWeight?.value,
              }}
              className={`${buttonState?.value.toLocaleLowerCase()} ${selectedClassName} d-flex m-auto `}
              disabled={
                buttonState?.value.toLowerCase() === "disabled" ||
                componentInfo?.disabled
              }
              size={componentInfo?.size}
            />
          </div>
          {selectedTokenData &&
            selectedTokenData[buttonState?.value.toLocaleLowerCase()] && (
              <>
                {selectedTokenData[buttonState?.value.toLowerCase()].bg
                  ?.tokenName && (
                  <div className='plt-token-details dis-mb-32'>
                    <h6 className='plt-token-title plt-heading-xs plt-font-color-primary dis-mb-4'>
                      Background color
                    </h6>
                    <div className='plt-token-value-item d-flex'>
                      <p className='plt-badge plt-token-value-name plt-body-compact-sm dis-mr-12'>
                        {selectedTokenData[buttonState?.value.toLowerCase()].bg
                          .tokenName || "N/A"}
                      </p>
                      <span
                        className='plt-iconbtn plt-iconbtn-sm plt-token-value-icon'
                        onClick={() => {
                          setTokenType("bg");
                          setColorTokenModalOpen(true);
                        }}
                      >
                        <EditIcon />
                      </span>
                    </div>
                  </div>
                )}
                {selectedTokenData[buttonState?.value.toLowerCase()]?.border
                  ?.tokenName && (
                  <div className='plt-token-details dis-mb-32'>
                    <h6 className='plt-token-title plt-heading-xs plt-font-color-primary dis-mb-4'>
                      Border color
                    </h6>
                    <div className='plt-token-value-item d-flex'>
                      <p className='plt-badge plt-token-value-name plt-body-compact-sm dis-mr-12'>
                        {selectedTokenData[buttonState?.value.toLowerCase()]
                          ?.border.tokenName || "N/A"}
                      </p>
                      <span
                        className='plt-iconbtn plt-iconbtn-sm plt-token-value-icon'
                        onClick={() => {
                          setTokenType("border");
                          setColorTokenModalOpen(true);
                        }}
                      >
                        <EditIcon />
                      </span>
                    </div>
                  </div>
                )}
                {selectedTokenData[buttonState?.value.toLowerCase()]
                  ?.typography?.[buttonSize]?.tokenName && (
                  <div className='plt-token-details dis-mb-32'>
                    <h6 className='plt-token-title plt-heading-xs plt-font-color-primary dis-mb-4'>
                      Typography
                    </h6>
                    <div className='plt-token-value-item d-flex'>
                      <p className='plt-badge plt-token-value-name plt-body-compact-sm dis-mr-12'>
                        {selectedTokenData[buttonState?.value.toLowerCase()]
                          ?.typography?.[buttonSize]?.tokenName || "N/A"}
                      </p>
                      <span
                        className='plt-iconbtn plt-iconbtn-sm plt-token-value-icon'
                        onClick={() => {
                          setTokenType("text");
                          setTypoTokenModalOpen(true);
                        }}
                      >
                        <EditIcon />
                      </span>
                    </div>
                  </div>
                )}
                {selectedTokenData[buttonState?.value.toLowerCase()]?.radius?.[
                  buttonSize
                ]?.className && (
                  <div className='plt-token-details dis-mb-32'>
                    <h6 className='plt-token-title plt-heading-xs plt-font-color-primary dis-mb-4'>
                      Radius
                    </h6>
                    <div className='plt-token-value-item d-flex'>
                      <p className='plt-badge plt-token-value-name plt-body-compact-sm dis-mr-12'>
                        {selectedTokenData[buttonState?.value.toLowerCase()]
                          ?.radius?.[buttonSize]?.className || "N/A"}
                      </p>
                      <span
                        className='plt-iconbtn plt-iconbtn-sm plt-token-value-icon'
                        onClick={() => setRadiusTokenModalOpen(true)}
                      >
                        <EditIcon />
                      </span>
                    </div>
                  </div>
                )}
                {selectedTokenData[buttonState?.value.toLowerCase()]?.text
                  ?.tokenName && (
                  <div className='plt-token-details dis-mb-32'>
                    <h6 className='plt-token-title plt-heading-xs plt-font-color-primary dis-mb-4'>
                      Text color
                    </h6>
                    <div className='plt-token-value-item d-flex'>
                      <p className='plt-badge plt-token-value-name plt-body-compact-sm dis-mr-12'>
                        {selectedTokenData[buttonState?.value.toLowerCase()]
                          ?.text.tokenName || "N/A"}
                      </p>
                      <span
                        className='plt-iconbtn plt-iconbtn-sm plt-token-value-icon'
                        onClick={() => {
                          setTokenType("text");
                          setColorTokenModalOpen(true);
                        }}
                      >
                        <EditIcon />
                      </span>
                    </div>
                  </div>
                )}
                {selectedTokenData[buttonState?.value.toLowerCase()]?.spacing?.[
                  buttonSize
                ]?.tokenName && (
                  <div className='plt-token-details dis-mb-24'>
                    <h6 className='plt-token-title plt-heading-xs plt-font-color-primary dis-mb-4'>
                      Padding
                    </h6>
                    <p className='plt-badge plt-token-value-name plt-body-compact-sm dis-mb-4'>
                      {selectedTokenData[buttonState?.value.toLowerCase()]
                        ?.spacing?.[buttonSize]?.tokenName || "N/A"}
                    </p>
                    <div className='plt-token-value-item d-flex align-items-center'>
                      <div className='d-flex align-items-center'>
                        <span className='plt-body-compact-sm plt-font-color-primary dis-mr-4'>
                          H:
                        </span>
                        <p className='plt-badge plt-token-value-name plt-body-compact-sm dis-mr-12'>
                          {spaceHValue?.label || "N/A"}
                        </p>
                      </div>
                      <div className='d-flex align-items-center'>
                        <span className='plt-body-compact-sm plt-font-color-primary dis-mr-4'>
                          V:
                        </span>
                        <p className='plt-badge plt-token-value-name plt-body-compact-sm dis-mr-12'>
                          {spaceVValue?.label || "N/A"}
                        </p>
                      </div>
                      <span
                        className='plt-iconbtn plt-iconbtn-sm plt-token-value-icon'
                        onClick={() => {
                          setTokenType("text");
                          setPaddingTokenModalOpen(true);
                        }}
                      >
                        <EditIcon />
                      </span>
                    </div>
                  </div>
                )}
                {selectedTokenData[buttonState?.value.toLowerCase()]
                  ?.elevation?.[buttonSize]?.tokenName &&
                  selectedShadow && (
                    <div className='plt-token-details dis-mb-24'>
                      <h6 className='plt-token-title plt-heading-xs plt-font-color-primary dis-mb-4'>
                        Box shadow
                      </h6>
                      <p className='plt-badge plt-token-value-name plt-body-compact-sm dis-mb-4'>
                        {selectedTokenData[buttonState?.value.toLowerCase()]
                          ?.elevation?.[buttonSize]?.tokenName || "N/A"}
                      </p>
                      <div className='plt-token-value-item d-flex align-items-center'>
                        <div className='d-flex align-items-center'>
                          <span className='plt-body-compact-sm plt-font-color-primary dis-mr-4'>
                            X:
                          </span>
                          <p className='plt-badge plt-token-value-name plt-body-compact-sm dis-mr-12'>
                            {selectedShadow?.value?.x}
                          </p>
                        </div>
                        <div className='d-flex align-items-center'>
                          <span className='plt-body-compact-sm plt-font-color-primary dis-mr-4'>
                            Y:
                          </span>
                          <p className='plt-badge plt-token-value-name plt-body-compact-sm dis-mr-12'>
                            {selectedShadow?.value?.y}
                          </p>
                        </div>
                      </div>
                      <div className='plt-token-value-item d-flex align-items-center dis-mt-12'>
                        <div className='d-flex align-items-center'>
                          <span className='plt-body-compact-sm plt-font-color-primary dis-mr-4'>
                            Blur:
                          </span>
                          <p className='plt-badge plt-token-value-name plt-body-compact-sm dis-mr-12'>
                            {selectedShadow?.value?.blur}
                          </p>
                        </div>
                        <div className='d-flex align-items-center'>
                          <span className='plt-body-compact-sm plt-font-color-primary dis-mr-4'>
                            Spread:
                          </span>
                          <p className='plt-badge plt-token-value-name plt-body-compact-sm dis-mr-12'>
                            {selectedShadow?.value?.spread}
                          </p>
                        </div>
                        <span
                          className='plt-iconbtn plt-iconbtn-sm plt-token-value-icon'
                          onClick={() => {
                            setTokenType("text");
                            setShadowTokenModalOpen(true);
                          }}
                        >
                          <EditIcon />
                        </span>
                      </div>
                    </div>
                  )}

                <div className='plt-token-details dis-mb-24'>
                  <h6 className='plt-token-title plt-heading-xs plt-font-color-primary dis-mb-4'>
                    Text decoration
                  </h6>
                  <div className='plt-token-value-item d-flex'>
                    <p className='plt-badge plt-token-value-name plt-body-compact-sm dis-mr-12'>
                      textdecoration-underline
                    </p>
                    <span
                      className='plt-iconbtn plt-iconbtn-sm plt-token-value-icon'
                      onClick={() => {
                        setTokenType("text");
                        setTextTokenModalOpen(true);
                      }}
                    >
                      <EditIcon />
                    </span>
                  </div>
                </div>
              </>
            )}
        </div>
      </CommonModal>
      {isColorTokenModalOpen && (
        <ColorTokenModal
          isColorTokenModalOpen={isColorTokenModalOpen}
          setColorTokenModalOpen={setColorTokenModalOpen}
          setButtonSelectedColor={setButtonSelectedColor}
          buttonState={buttonState}
          selectedClassName={componentInfo}
          componentInfo={componentInfo}
          colorSections={colorSections}
          onApplyChanges={onApplyChanges}
          tokenType={tokenType}
          selectedTokenData={selectedTokenData}
          setTokenState={setTokenState}
        />
      )}

      {isPaddingTokenModalOpen && (
        <PaddingTokenModal
          setPaddingTokenModalOpen={setPaddingTokenModalOpen}
          isPaddingTokenModalOpen={isPaddingTokenModalOpen}
          handleApplyPaddingChange={handleApplyPaddingChange}
          buttonState={buttonState}
          selectedClassName={selectedClassName}
          spaceVValue={spaceVValue}
          spaceHValue={spaceHValue}
          spaces={spaces}
          handleHorizontalChange={handleHorizontalChange}
          handleVerticalChange={handleVerticalChange}
        />
      )}
      {isShadowTokenModalOpen && (
        <ShadowTokenModal
          setShadowTokenModalOpen={setShadowTokenModalOpen}
          isShadowTokenModalOpen={isShadowTokenModalOpen}
          handleApplyPaddingChange={handleApplyPaddingChange}
          buttonState={buttonState}
          selectedClassName={selectedClassName}
          spaceVValue={spaceVValue}
          spaceHValue={spaceHValue}
          selectedShadow={selectedShadow}
          elevationData={elevationData}
          boxShadowStyle={boxShadowStyle}
          setSelectedShadow={setSelectedShadow}
          handleApplyShadowChange={handleApplyShadowChange}
        />
      )}

      {isTextTokenModalOpen && (
        <TextTokenModal
          handleTextdecorationChange={handleTextdecorationChange}
          selectedtextdecoration={selectedtextdecoration}
          textdecoration={textdecoration}
          isTextTokenModalOpen={isTextTokenModalOpen}
          setTextTokenModalOpen={setTextTokenModalOpen}
          handleApplyTextChange={handleApplyTextChange}
          buttonState={buttonState}
          selectedClassName={selectedClassName}
        />
      )}

      <RadiusTokenModal
        isRadiusTokenModalOpen={isRadiusTokenModalOpen}
        setRadiusTokenModalOpen={setRadiusTokenModalOpen}
        selectedClassName={selectedClassName}
        buttonState={buttonState}
        handleRadiusChange={handleRadiusChange}
        handleApplyRadiusChange={handleApplyRadiusChange}
        selectedRadius={selectedRadius}
        radiusValues={radiusValues}
      />
      {isTypoTokenModalOpen && (
        <TypoTokenModal
          isTypoTokenModalOpen={isTypoTokenModalOpen}
          setTypoTokenModalOpen={setTypoTokenModalOpen}
          buttonState={buttonState}
          selectedClassName={selectedClassName}
          selectedToken={selectedToken}
          handleTokenChange={handleTokenChange}
          handleApplyTypographyChange={handleApplyTypographyChange}
          uiTextData={uiTextData}
        />
      )}
      <div className='plt-token-contents'>
        <TabNavigation
          tabs={tabData}
          title={"CONTENTS"}
          activeIndex={activeSideTab}
          onTabClick={handleTabClick}
        />
      </div>
    </div>
  );
};

export default PreviewButtonComponent;
