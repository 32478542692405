import React, { useState } from "react";
// import "./PreviewInputComponentButtonComponent.scss";
// import { TabsData } from "../../../../assets/data/data";
import TabNavigation from "../../TabNavigation";
import ComponentCard from "../../../Common/ComponentCard";
import { addToLocalStorage } from "hooks/storageUtils";
import colorSectionData from "../../../../pages/styles/colorSectionData";
import { ButtonIconSample, DropdownArrow, EditIcon } from "../../../Icons";
import Inputs from "../../../Inputs";
import CommonModal from "../../../Common/CommonModal";
import CustomSelect from "../../../Common/CustomSelect";
import EditButton from "../../../Buttons/EditButton";
import { useGlobalContext } from "context/GlobalContext";
import data from "../data";
import ColorPickerComponent from "../../ColorPickerComponent";
import Dropdown from "components/Dropdown";

const PreviewInputComponent = ({ tabData, handleTabClick, activeTab, setSelectedTab }) => {
	const currentTheme = localStorage.getItem("theme") || "light";
	const themeKey = `Theme/${currentTheme}`;

	const ColorSectionComponent = ({
		title,
		description,
		colorData,
		type,
		setTokenState,
		buttonState,
		tokenState,
		initialColor,
	}) => {
		return (
			<div className="plt-content-wrapper dis-mb-32">
				{title && type?.length && (
					<h6 className="plt-contents-head plt-heading-sm plt-font-color-primary dis-mb-12">{title}</h6>
				)}
				{description && !type?.length && (
					<p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400 dis-mb-24">
						{description}
					</p>
				)}

				{colorData.map((color, index) => (
					<>
						{color?.subTitle && (
							<p className="plt-subtitle plt-heading-xs plt-font-color-primary dis-mb-8">{color?.subTitle}</p>
						)}
						<ColorPickerComponent
							key={index}
							initialColor={initialColor}
							buttonState={buttonState}
							setTokenState={setTokenState}
							type={type}
							tokenState={tokenState}
							baseColor={color.baseColor}
							id={color.id}
							shades={color.shades}
							tokenType={color.tokenType}
						/>
					</>
				))}
			</div>
		);
	};
	const [isRadiusTokenModalOpen, setRadiusTokenModalOpen] = useState(false);

	const { previewInputData, inputData, inputDataDense } = data;
	const { designSystem, setButtonSelectedColor } = useGlobalContext();
	const [isPaddingTokenModalOpen, setPaddingTokenModalOpen] = useState(false);
	const AllUsage = () => (
		<p className="plt-character-des plt-body-compact-sm dis-pt-12 dis-mb-32">
			See all states{" "}
			<span
				onClick={() => setSelectedTab(1)}
				className="plt-text-underline plt-body-compact-sm plt-font-color-primary font-weight-500 cursor-pointer"
			>
				usage
			</span>
		</p>
	);

	const [isModalVisible, setModalVisibility] = useState(false);
	const showModal = (tokenData) => {
		setModalVisibility(true);
		setSelectedInputData(tokenData);
		// setSelectedTokenData(tokenData);
	};
	const [tokenType, setTokenType] = useState("");
	// const buttonStates = ["Inactive", "Focused", "Filled", "Disabled", "Readonly"];
	const buttonStates = [
		{ value: "Inactive", name: "Default" },
		{ value: "Focused", name: "Hovered" },
		{ value: "Filled", name: "Pressed" },
		{ value: "Disabled", name: "Disabled" },
		{ value: "Readonly", name: "Readonly" },
	];
	const [buttonState, setButtonState] = useState(() => buttonStates[0]);

	// const [buttonState, setButtonState] = useState(buttonStates[0]);
	const [selectedInputData, setSelectedInputData] = useState({});

	const [selectedTokenData, setSelectedTokenData] = useState({});
	const [tokenState, setTokenState] = useState({ key: "", value: "" });

	const [isColorTokenModalOpen, setColorTokenModalOpen] = useState(false);

	// const handleSelect = (option) => {
	// 	setButtonState(option);
	// };

	const handleStateChange = (name, value) => {
		setButtonState(value);
	};

	const getBackgroundFillToken = (state) => {
		switch (state) {
			case "Inactive":
				return designSystem[themeKey]?.color?.background?.form?.fill?.inactive;
			case "Filled":
				return designSystem[themeKey]?.color?.background?.form?.fill?.filled;
			case "Focused":
				return designSystem[themeKey]?.color?.background?.form?.fill?.focused;
			case "Disabled":
				return designSystem[themeKey]?.color?.background?.form?.fill?.disabled;
			case "Readonly":
				return designSystem[themeKey]?.color?.background?.form?.fill?.readonly;
			default:
				return null;
		}
	};

	const backgroundFillToken = getBackgroundFillToken(buttonState);

	const getBorderToken = (state) => {
		switch (state) {
			case "Inactive":
				return designSystem[themeKey]?.color?.border?.form?.inactive;
			case "Focused":
				return designSystem[themeKey]?.color?.border?.form?.focused;
			case "Filled":
				return designSystem[themeKey]?.color?.border?.form?.filled;
			case "Disabled":
				return designSystem[themeKey]?.color?.border?.form?.disabled;
			case "Readonly":
				return designSystem[themeKey]?.color?.border?.form?.readonly;
			default:
				return null;
		}
	};

	const borderToken = getBorderToken(buttonState);

	//input color change apply
	const colorSections = colorSectionData();

	const onApplyChanges = async () => {
		addToLocalStorage([tokenState]);
		setColorTokenModalOpen(false);
	};

	const [selectedRadius, setSelectedRadius] = useState({
		key: designSystem[themeKey]?.color?.borderRadius?.form?.normal,
	});

	//input radius
	const radiusValues = designSystem?.core?.borderRadius
		? Object.keys(designSystem?.core?.borderRadius).map((key, index) => ({
				id: key,
				key: `{borderRadius.${key}}`,
				label: `dss-border-radius-${key}`,
				value: designSystem?.core?.borderRadius[key]?.value,
			}))
		: [];

	const applyChanges = (cssVarName, value) => {
		document.documentElement.style.setProperty(cssVarName, value);
	};

	const handleRadiusChange = (item) => {
		applyChanges(designSystem[themeKey]?.color?.borderRadius?.form?.normal?.varName, item?.value);

		setSelectedRadius(item);
	};

	const handleApplyRadiusChange = () => {
		const tokenData = [
			{
				key: designSystem[themeKey]?.color?.borderRadius?.form?.normal?.key,
				value: `${selectedRadius.key}`,
			},
		];

		addToLocalStorage(tokenData);

		setRadiusTokenModalOpen(false);
	};

	// input padding change
	const [spaceHValue, setSpaceHValue] = useState({ label: "", value: "" });
	const [spaceVValue, setSpaceVValue] = useState({ label: "", value: "" });
	const spaces = designSystem?.core?.space
		? Object.keys(designSystem?.core?.space).map((key) => ({
				label: `space.${key}`,
				value: designSystem?.core?.space[key]?.value,
			}))
		: [];

	return (
		<div className="tab-content-wrapper d-flex justify-content-between">
			<div className="tab-content-container">
				<div className="plt-content-wrapper dis-mb-24">
					<h6 className="plt-contents-head plt-heading-md plt-font-color-primary dis-mb-8">
						Text field - Normal | Default
					</h6>
					<p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
						Use a primary button to call attention to an action on a form or to highlight the strongest call to action
						on a page. Primary buttons should only appear once per container (not including the application header or in
						a modal dialog). Not every screen requires a primary button.
					</p>
				</div>
				{inputData.map((inputConfig, index) => (
					<div key={index} className="plt-content-wrapper dis-mb-24">
						<div className="plt-content-wrapper-block dis-mb-24">
							<h6 className="plt-contents-head plt-heading-sm plt-font-color-primary dis-mb-8">{inputConfig.title}</h6>
							<p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
								{inputConfig.desc}
							</p>
						</div>
						<ComponentCard edit={true} editClick={() => showModal(inputConfig)}>
							<Inputs
								inputId={index}
								dataItem={inputConfig.dataItem}
								leadingIcon={inputConfig.leadingIcon}
								tailingIcon={inputConfig.tailingIcon}
								prefix={inputConfig.prefix}
								suffix={inputConfig.suffix}
								tag={inputConfig.tag}
								readOnlyState={inputConfig.readOnlyState}
								disabled={inputConfig.disabled}
								label="Label"
								// labelClassname={inputConfig.className}
							/>
						</ComponentCard>
						<AllUsage />
					</div>
				))}

				<div className="plt-content-wrapper dis-mb-24">
					<h6 className="plt-contents-head plt-heading-md plt-font-color-primary dis-mb-8">Fluid</h6>
					<p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
						Use a primary button to call attention to an action on a form or to highlight the strongest call to action
						on a page. Primary buttons should only appear once per container (not including the application header or in
						a modal dialog). Not every screen requires a primary button.
					</p>
				</div>
				{inputData.map((inputConfig, index) => (
					<div key={index} className="plt-content-wrapper dis-mb-24">
						<div className="plt-content-wrapper-block dis-mb-24">
							<h6 className="plt-contents-head plt-heading-sm plt-font-color-primary dis-mb-8">{inputConfig.title}</h6>
							<p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
								{inputConfig.desc}
							</p>
						</div>
						<ComponentCard edit={true} editClick={() => showModal(inputConfig)}>
							<Inputs
								inputId={index}
								dataItem={inputConfig.dataItem}
								leadingIcon={inputConfig.leadingIcon}
								tailingIcon={inputConfig.tailingIcon}
								prefix={inputConfig.prefix}
								suffix={inputConfig.suffix}
								tag={inputConfig.tag}
								readOnlyState={inputConfig.readOnlyState}
								disabled={inputConfig.disabled}
								fluid={true}
								label="Label"
								// labelClassname={inputConfig.className}
							/>
						</ComponentCard>
						<AllUsage />
					</div>
				))}

				<div className="plt-content-wrapper dis-mb-24">
					<h6 className="plt-contents-head plt-heading-md plt-font-color-primary dis-mb-8">Text field - Dense</h6>
					<p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
						Use a primary button to call attention to an action on a form or to highlight the strongest call to action
						on a page. Primary buttons should only appear once per container (not including the application header or in
						a modal dialog). Not every screen requires a primary button.
					</p>
				</div>
				{inputDataDense.map((inputConfig, index) => (
					<div key={index} className="plt-content-wrapper dis-mb-24">
						<div className="plt-content-wrapper-block dis-mb-24">
							<h6 className="plt-contents-head plt-heading-sm plt-font-color-primary dis-mb-8">{inputConfig.title}</h6>
							<p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
								{inputConfig.desc}
							</p>
						</div>
						<ComponentCard edit={true} editClick={() => showModal(inputConfig)}>
							<Inputs
								inputId={index}
								dataItem={inputConfig.dataItem}
								leadingIcon={inputConfig.leadingIcon}
								tailingIcon={inputConfig.tailingIcon}
								prefix={inputConfig.prefix}
								suffix={inputConfig.suffix}
								tag={inputConfig.tag}
								readOnlyState={inputConfig.readOnlyState}
								disabled={inputConfig.disabled}
								denseClassName={inputConfig.className}
								label="Label"
							/>
						</ComponentCard>
						<AllUsage />
					</div>
				))}

				<div className="plt-content-wrapper dis-mb-24">
					<h6 className="plt-contents-head plt-heading-md plt-font-color-primary dis-mb-8">
						Text field - Dense - Fluid
					</h6>
					<p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
						Use a primary button to call attention to an action on a form or to highlight the strongest call to action
						on a page. Primary buttons should only appear once per container (not including the application header or in
						a modal dialog). Not every screen requires a primary button.
					</p>
				</div>
				{inputDataDense.map((inputConfig, index) => (
					<div key={index} className="plt-content-wrapper dis-mb-24">
						<div className="plt-content-wrapper-block dis-mb-24">
							<h6 className="plt-contents-head plt-heading-sm plt-font-color-primary dis-mb-8">{inputConfig.title}</h6>
							<p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
								{inputConfig.desc}
							</p>
						</div>
						<ComponentCard edit={true} editClick={showModal}>
							<Inputs
								inputId={index}
								dataItem={inputConfig.dataItem}
								leadingIcon={inputConfig.leadingIcon}
								tailingIcon={inputConfig.tailingIcon}
								prefix={inputConfig.prefix}
								suffix={inputConfig.suffix}
								tag={inputConfig.tag}
								fluid={true}
								readOnlyState={inputConfig.readOnlyState}
								disabled={inputConfig.disabled}
								denseClassName={inputConfig.className}
								label="Label"
							/>
						</ComponentCard>
						<AllUsage />
					</div>
				))}
			</div>
			<CommonModal
				isOpen={isModalVisible}
				onRequestClose={() => {
					setModalVisibility(false);
					setSelectedInputData(buttonStates[0]);
				}}
				title="Tokens"
				onApply={() => setModalVisibility(false)}
				variant="modal-tertiary"
			>
				<div className="plt-modal-body-content">
					<div className="plt-dropdown-secondary">
						<div className="plt-select-dropdown-wrapper d-flex align-items-center justify-content-between">
							<div className="plt-dropdown-wrapper plt-dropdown-wrapper-common">
								{/* <CustomSelect options={buttonStates} selectedOption={buttonState} onSelect={handleSelect} /> */}
								<Dropdown
									name="buttonState"
									options={buttonStates}
									selectedOption={buttonState}
									onSelect={handleStateChange}
								/>
							</div>
						</div>
					</div>

					<div className="plt-preview plt-heading-xl dis-mb-32">
						<Inputs
							// className={`${buttonState.toLowerCase()}`}
							className={`${buttonState?.value.toLowerCase()} `}
							dataItem={selectedInputData.dataItem}
							leadingIcon={selectedInputData.leadingIcon}
							tailingIcon={selectedInputData?.tailingIcon}
							prefix={selectedInputData?.prefix}
							suffix={selectedInputData?.suffix}
							tag={selectedInputData.tag}
							label="Label"
						/>
					</div>
					<div className="plt-token-details dis-mb-32">
						<h6 className="plt-token-title plt-heading-xs plt-font-color-primary dis-mb-4">Background color</h6>
						<div className="plt-token-value-item d-flex">
							<p className="plt-badge plt-token-value-name plt-body-compact-sm dis-mr-12">
								{backgroundFillToken?.tokenName}
							</p>
							<span
								className="plt-iconbtn plt-iconbtn-sm plt-token-value-icon"
								onClick={() => {
									setTokenType("inputBg");
									setSelectedTokenData(backgroundFillToken);
									setColorTokenModalOpen(true);
								}}
							>
								<EditIcon />
							</span>
						</div>
					</div>
					{/* <div className="plt-token-details dis-mb-32">
            <h6 className="plt-token-title plt-heading-xs plt-font-color-primary dis-mb-4">
              Typography Label
            </h6>
            <div className="plt-token-value-item d-flex">
              <p className="plt-badge plt-token-value-name plt-body-compact-sm dis-mr-12">
                {designSystem[`Theme/${currentTheme}`]?.color?.uitext?.form?.label?.tokenName}              </p>
              <span
                className="plt-iconbtn plt-iconbtn-sm plt-token-value-icon"
                onClick={() => {
                  setTokenType("text");
                  setColorTokenModalOpen(true);
                }}
              >
                <EditIcon />
              </span>
            </div>
          </div> */}
					<div className="plt-token-details dis-mb-32">
						<h6 className="plt-token-title plt-heading-xs plt-font-color-primary dis-mb-4">Label color</h6>
						<div className="plt-token-value-item d-flex">
							<p className="plt-badge plt-token-value-name plt-body-compact-sm dis-mr-12">
								{designSystem[themeKey]?.color?.text?.form?.secondary.tokenName}
							</p>
							<span
								className="plt-iconbtn plt-iconbtn-sm plt-token-value-icon"
								onClick={() => {
									setTokenType("inputBg");
									setColorTokenModalOpen(true);
									setSelectedTokenData(designSystem[themeKey]?.color?.text?.form?.secondary);
								}}
							>
								<EditIcon />
							</span>
						</div>
					</div>
					<div className="plt-token-details dis-mb-32">
						<h6 className="plt-token-title plt-heading-xs plt-font-color-primary dis-mb-4">Placeholder color</h6>
						<div className="plt-token-value-item d-flex">
							<p className="plt-badge plt-token-value-name plt-body-compact-sm dis-mr-12">
								{designSystem[themeKey]?.color?.text?.form?.placeholder?.tokenName}
							</p>
							<span
								className="plt-iconbtn plt-iconbtn-sm plt-token-value-icon"
								onClick={() => {
									setTokenType("inputBg");
									setSelectedTokenData(designSystem[themeKey]?.color?.text?.form?.placeholder);
									setColorTokenModalOpen(true);
								}}
							>
								<EditIcon />
							</span>
						</div>
					</div>
					<div className="plt-token-details dis-mb-32">
						<h6 className="plt-token-title plt-heading-xs plt-font-color-primary dis-mb-4">Validation color</h6>
						<div className="plt-token-value-item d-flex">
							<p className="plt-badge plt-token-value-name plt-body-compact-sm dis-mr-12">
								{designSystem[themeKey]?.color?.text?.form?.negative.tokenName}
							</p>
							<span
								className="plt-iconbtn plt-iconbtn-sm plt-token-value-icon"
								onClick={() => {
									setTokenType("inputBg");
									setColorTokenModalOpen(true);
									setSelectedTokenData(designSystem[themeKey]?.color?.text?.form?.negative);
								}}
							>
								<EditIcon />
							</span>
						</div>
					</div>
					<div className="plt-token-details dis-mb-32">
						<h6 className="plt-token-title plt-heading-xs plt-font-color-primary dis-mb-4">Border color</h6>
						<div className="plt-token-value-item d-flex">
							<p className="plt-badge plt-token-value-name plt-body-compact-sm dis-mr-12">{borderToken?.tokenName}</p>
							<span
								className="plt-iconbtn plt-iconbtn-sm plt-token-value-icon"
								onClick={() => {
									setSelectedTokenData(borderToken);
									setTokenType("inputBg");
									setColorTokenModalOpen(true);
								}}
							>
								<EditIcon />
							</span>
						</div>
					</div>
					{designSystem[themeKey]?.color?.borderRadius?.form?.normal && (
						<div className="plt-token-details dis-mb-32">
							<h6 className="plt-token-title plt-heading-xs plt-font-color-primary dis-mb-4">Border Radius</h6>
							<div className="plt-token-value-item d-flex">
								<p className="plt-badge plt-token-value-name plt-body-compact-sm dis-mr-12">
									{designSystem[themeKey]?.color?.borderRadius?.form?.normal?.tokenName || "N/A"}
								</p>
								<span
									className="plt-iconbtn plt-iconbtn-sm plt-token-value-icon"
									onClick={() => setRadiusTokenModalOpen(true)}
								>
									<EditIcon />
								</span>
							</div>
						</div>
					)}
					{/* {designSystem[`Theme/${currentTheme}`]?.color?.borderRadius?.form?.normal && (
            <div className="plt-token-details dis-mb-24">
              <h6 className="plt-token-title plt-heading-xs plt-font-color-primary dis-mb-4">
                Padding
              </h6>
              <p className="plt-badge plt-token-value-name plt-body-compact-sm dis-mb-4">
                {selectedTokenData[buttonState.toLowerCase()]?.spacing?.[
                  buttonSize
                ]?.tokenName || "N/A"}
              </p>
              <div className="plt-token-value-item d-flex align-items-center">
                <div className="d-flex align-items-center">
                  <span className="plt-body-compact-sm plt-font-color-primary dis-mr-4">
                    H:
                  </span>
                  <p className="plt-badge plt-token-value-name plt-body-compact-sm dis-mr-12">
                    {spaceHValue?.label || "N/A"}
                  </p>
                </div>
                <div className="d-flex align-items-center">
                  <span className="plt-body-compact-sm plt-font-color-primary dis-mr-4">
                    V:
                  </span>
                  <p className="plt-badge plt-token-value-name plt-body-compact-sm dis-mr-12">
                    {spaceVValue?.label || "N/A"}
                  </p>
                </div>
                <span
                  className="plt-iconbtn plt-iconbtn-sm plt-token-value-icon"
                  onClick={() => {
                    setTokenType("text");
                    setPaddingTokenModalOpen(true);
                  }}
                >
                  <EditIcon />
                </span>
              </div>
            </div>
          )} */}
					{/* <div className="plt-token-details dis-mb-32">
            <h6 className="plt-token-title plt-heading-xs plt-font-color-primary dis-mb-4">
              Typography
            </h6>
            <div className="plt-token-value-item d-flex">
              <p className="plt-badge plt-token-value-name plt-body-compact-sm dis-mr-12">
                text.button.fill.primary
              </p>
              <span
                className="plt-iconbtn plt-iconbtn-sm plt-token-value-icon"
                onClick={() => {
                  setTokenType("text");
                  setColorTokenModalOpen(true);
                }}
              >
                <EditIcon />
              </span>
            </div>
          </div> */}
				</div>
			</CommonModal>

			{/* Padding change */}
			<CommonModal
				isOpen={isPaddingTokenModalOpen}
				onRequestClose={() => setPaddingTokenModalOpen(false)}
				title="Change token"
				onApply={() => handleApplyPaddingChange()}

				// variant="modal-tertiary"
			>
				<div className="plt-modal-body-content">
					<div className="plt-preview dis-mb-32" style={{ fontFamily: buttonState }}>
						{previewInputData.map((data, index) => (
							<Inputs
								className={`${buttonState?.value.toLowerCase()}`}
								dataItem={selectedInputData.dataItem}
								leadingIcon={selectedInputData.leadingIcon}
								tailingIcon={selectedInputData?.tailingIcon}
								prefix={selectedInputData?.prefix}
								suffix={selectedInputData?.suffix}
								tag={selectedInputData.tag}
								label="Label"
							/>
						))}
					</div>

					<div className="plt-padding-wrapper d-flex  dis-mb-32">
						<div className="plt-select-dropdown-wrapper d-flex align-items-center">
							<h6 className="plt-select-dropdown-title dis-mb-4 plt-ui-text-sm-regular">Horizontal</h6>
							<div className="plt-dropdown-wrapper plt-dropdown-wrapper-padding">
								<CustomSelect
									options={spaces.map((space) => space.value)}
									selectedOption={spaceHValue.label ? spaceHValue.value : spaceHValue}
									onSelect={(label) => handleHorizontalChange(spaces.find((option) => option.value === label))}
								/>
							</div>
						</div>
						<div className="plt-select-dropdown-wrapper d-flex align-items-center">
							<h6 className="plt-select-dropdown-title dis-mb-4 plt-ui-text-sm-regular">Vertical</h6>
							<div className="plt-dropdown-wrapper plt-dropdown-wrapper-padding">
								<CustomSelect
									options={spaces.map((space) => space.value)}
									selectedOption={spaceVValue.label ? spaceVValue.value : spaceVValue}
									onSelect={(label) => handleVerticalChange(spaces.find((option) => option.value === label))}
								/>
							</div>
						</div>
					</div>
				</div>
			</CommonModal>

			{/* color modal */}
			<CommonModal
				isOpen={isColorTokenModalOpen}
				onRequestClose={() => {
					setButtonSelectedColor(null), setColorTokenModalOpen(false);
				}}
				title="Change token"
				onApply={onApplyChanges}
				variant="change-token-modal"
			>
				<>
					<div className="plt-preview dis-mb-24">
						{previewInputData.map((data, index) => (
							<Inputs
								className={`${buttonState?.value.toLowerCase()}`}
								dataItem={data.dataItem}
								leadingIcon={true}
								tailingIcon={true}
								prefix={true}
								suffix={true}
								label="Label"
							/>
						))}
					</div>
					<div className="plt-modal-body-content dis-mt-32 dis-pl-24 dis-pr-24">
						{colorSections?.map((section, index) => (
							<div key={section.title}>
								<ColorSectionComponent
									{...section}
									onApplyChanges={onApplyChanges}
									type={tokenType}
									buttonState={buttonState}
									tokenState={selectedTokenData}
									setTokenState={setTokenState}
									initialColor={
										tokenType === "bg"
											? selectedTokenData[buttonState?.toLowerCase()]?.bg?.value
											: tokenType === "text"
												? selectedTokenData[buttonState?.toLowerCase()]?.text?.value
												: tokenType === "border"
													? selectedTokenData[buttonState?.toLowerCase()]?.border?.value
													: undefined
									}
								/>
							</div>
						))}
					</div>
				</>
			</CommonModal>

			{/* radius change input modal */}
			<CommonModal
				isOpen={isRadiusTokenModalOpen}
				onRequestClose={() => {
					setSelectedRadius({
						key: designSystem[themeKey]?.color?.borderRadius?.form?.normal,
					});
					setRadiusTokenModalOpen(false);
				}}
				title="Change token"
				onApply={() => handleApplyRadiusChange()}
			>
				<div className="plt-modal-body-content">
					<div className="modal-token-wrapper">
						<div className="plt-preview dis-mb-32">
							{previewInputData.map((data, index) => (
								<Inputs
									className={`${buttonState?.value.toLowerCase()}`}
									dataItem={data.dataItem}
									leadingIcon={data.leadingIcon}
									tailingIcon={data.tailingIcon}
									prefix={data.prefix}
									suffix={data.suffix}
									label="Label"
								/>
							))}
						</div>

						<div className="token-list dss-custom-radio-group flex-column">
							{radiusValues &&
								radiusValues.map((item) => (
									<div key={item.id} className="token-item">
										<label
											className={`dss-radio-wrapper dss-custom-radio-group-md dss-radio-default
                    ${selectedRadius?.key === item.key ? "checked" : ""}`}
										>
											<input
												className="dss-radio dss-radio-input dss-radio-default"
												name="radio-group"
												type="radio"
												id={item.id}
												value={item.value}
												onChange={() => handleRadiusChange(item)}
												checked={selectedRadius?.key === item.key}
											/>
											<div className="dss-radio-label" htmlFor={item.id}>
												<p className="plt-badge plt-body-sm">{item.label}</p>
											</div>
										</label>
										<div className="token-info dis-ml-40">
											<p className="plt-body-compact-sm font-color-black dis-mt-12">{item.value}</p>
										</div>
									</div>
								))}
						</div>
					</div>
				</div>
			</CommonModal>

			<div className="plt-token-contents">
				<TabNavigation tabs={tabData} title={"CONTENTS"} activeIndex={activeTab} onTabClick={handleTabClick} />
			</div>
		</div>
	);
};

export default PreviewInputComponent;
