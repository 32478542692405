import React, { useState, useRef, useEffect } from "react";
import UserImage from "assets/images/png/userAvatar.png";
import { getUser } from "hooks/storageUtils";
import { useGlobalContext } from "context/GlobalContext";
import { uploadAvatar, deleteUser, uploadLogo } from "api";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import {} from "api";
import { logout } from "helpers/auth";
import { PrimaryLogo } from "components/Icons";
import ChangePasswordModal from "components/modal/ChangePasswordModal";
import { changePassword } from "api";
import "./Account.scss";
import { DeleteButtonicon } from "components/Icons";
import { UploadIconSmall } from "components/Icons";
import { deleteImage } from "api";
import { fetchPasswordAudit } from "api";
import FormValidationError from "components/FormValidationError";
import { updateUser } from "api";
import { EditButtonicon } from "components/Icons";
import ConfirmDialog from "components/Common/Confirm/ConfirmDialog";
import DomainTable from "./DomainTable";
import { DeleteButtoniconRed } from "components/Icons";

const Account = ({ setCompanyLogo }) => {
	const { setLoading } = useGlobalContext();
	const [formData, setFormData] = useState({
		name: "",
		email: "",
	});
	const [image, setImage] = useState(null);
	const [logo, setLogo] = useState(null);
	const [errors, setErrors] = useState({});
	const [audit, setAudit] = useState({});
	const [isChangePasswordModalOpen, setChangePasswordModalOpen] = useState(false);

	const apiUrl = process.env.REACT_APP_SHEET_URL;

	const [isDropdownOpen, setIsDropdownOpen] = useState(false);
	const [isDeleteConfirmModalOpen, setDeleteConfirmModalOpen] = useState(false);
	const dropdownRef = useRef(null);

	useEffect(() => {
		const getPasswordAudit = async () => {
			try {
				setLoading(true);
				const data = await fetchPasswordAudit();
				setAudit(data.data);
			} catch (error) {
				console.error("Failed to fetch password audit", error);
			} finally {
				setLoading(false);
			}
		};

		getPasswordAudit();

		const handleClickOutside = (event) => {
			if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
				setIsDropdownOpen(false);
			}
		};

		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);

	const navigate = useNavigate();

	const fields = [
		{ name: "name", label: "Name" },
		{ name: "email", label: "Email address" },
	];

	const user = getUser();

	useEffect(() => {
		setFormData({
			name: user.name,
			email: user.email,
		});
		setImage(user?.image ?? UserImage);
	}, []);

	const onImageChange = async (event) => {
		if (event.target.files && event.target.files[0]) {
			setImage(URL.createObjectURL(event.target.files[0]));

			//upload image
			try {
				setLoading(true);
				const user = getUser();
				const data = await uploadAvatar(user.id, {
					image: event.target.files[0],
				});
				localStorage.setItem("user", JSON.stringify(data.data));
				toast.success(data.message);
			} catch (error) {
				toast.error(error.message);
				console.error("Failed upload avatar", error);
			} finally {
				setLoading(false);
			}
		}
	};

	const onLogoChange = async (event) => {
		if (event.target.files && event.target.files[0]) {
			setLogo(URL.createObjectURL(event.target.files[0]));

			//upload image
			try {
				setLoading(true);
				const user = getUser();
				const data = await uploadLogo(user.id, {
					logo: event.target.files[0],
				});
				localStorage.setItem("user", JSON.stringify(data.data));
				setCompanyLogo("null");
				toast.success(data.message);
			} catch (error) {
				toast.error(error.message);
				console.error("Failed upload logo", error);
			} finally {
				setLoading(false);
			}
		}
	};

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData({ ...formData, [name]: value });
	};

	const handleSave = async () => {
		const newErrors = {};
		fields.forEach((field) => {
			if (!formData[field.name]) {
				newErrors[field.name] = `${field.label} is required`;
			}
		});

		if (Object.keys(newErrors).length > 0) {
			setErrors(newErrors);
			return;
		}
		try {
			setLoading(true);
			const user = getUser();
			const data = await updateUser(user.id, formData);
			localStorage.setItem("user", JSON.stringify(data.data));
			setErrors({});
		} catch (error) {
			toast.error(error.message);
			console.error("Failed upload avatar", error);
		} finally {
			setLoading(false);
		}
	};

	const handleCancel = () => {
		setErrors({});
		navigate("/");
	};

	const handleDelete = async () => {
		// if (confirm("Are you sure to delete") == true) {
		try {
			setLoading(true);
			const user = getUser();
			await deleteUser(user.id);
			logout(navigate);
		} catch (error) {
			toast.error(error.message);
			console.error("Failed upload avatar", error);
		} finally {
			setLoading(false);
			setDeleteConfirmModalOpen(false);
		}
		// }
	};

	const openChangePasswordModal = () => {
		setChangePasswordModalOpen(true);
	};
	const closeChangePasswordModal = () => {
		setChangePasswordModalOpen(false);
	};

	const handleChangePasswordApply = async (request) => {
		try {
			setLoading(true);
			const user = getUser();
			let input = {
				old_password: request.oldPassword,
				new_password: request.newPassword,
				new_password_confirmation: request.confirmPassword,
				user_id: user.id,
			};
			const data = await changePassword(input);
			setAudit(data.data);
			toast.success(data.message);
		} catch (error) {
			toast.error(error.message);
			console.error("Failed change password", error);
		} finally {
			setLoading(false);
		}
	};

	const handleDeleteAvatar = async () => {
		setIsDropdownOpen(false);
		if (confirm("Are you sure to delete") == true) {
			try {
				setLoading(true);
				const user = getUser();
				const data = await deleteImage(user.id);
				localStorage.setItem("user", JSON.stringify(data.data));
				toast.success(data.message);
			} catch (error) {
				toast.error(error.message);
				console.error("Failed upload logo", error);
			} finally {
				setLoading(false);
			}
		}
	};

	const closeDeleteConfirmModal = () => {
		setDeleteConfirmModalOpen(false);
	};

	return (
		<div className="tab-content-wrapper">
			<div className="tab-content-container">
				<div className="plt-content-block dis-mb-0">
					<div className="plt-content-wrapper dis-mb-40">
						<div className="plt-content-block-top d-flex justify-content-between align-items-center">
							<h6 className="plt-contents-head plt-heading-xl plt-font-color-primary font-weight-600">My account</h6>
						</div>
					</div>
				</div>

				<div className="account-container">
					<div
						className="plt-select-dropdown-wrapper image-dropdown dis-mb-24 d-flex  align-items-center"
						ref={dropdownRef}
					>
						<span onClick={() => setIsDropdownOpen((prev) => !prev)}>
							<img src={user?.image ? `${apiUrl}${user?.image_url}` : UserImage} alt="user" className="user-image" />
						</span>
						{/* <span
              className='image-dropdown-edit d-flex align-items-center cursor-pointer'
              onClick={() => setIsDropdownOpen((prev) => !prev)}
            >
              <EditButtonicon />
            </span> */}
						{/* {user.name} */}
						{/* <span className='plt-modal-link plt-ui-text-sm-medium dis-mb-32 cursor-pointer d-block w-fit'>
          Change
        </span> */}

						{/* {isDropdownOpen && ( */}
						{/* <div className='image-dropdown'>
                <ul>
                  <li className='dropdown-item plt-ui-text-sm-medium plt-font-color-primary cursor-pointer'>
                    <input
                      type='file'
                      onChange={onImageChange}
                      className='filetype'
                      id='file'
                    />
                    <label
                      htmlFor='file'
                      className='plt-ui-text-sm-medium plt-font-color-primary cursor-pointer d-flex'
                    >
                      <span className='dropdown-item-icon dis-mr-8'>
                        <UploadIconSmall />
                      </span>
                      Upload your image
                    </label>
                  </li>
                  <li
                    className='dropdown-item plt-ui-text-sm-medium plt-font-color-primary cursor-pointer'
                    onClick={handleDeleteAvatar}
                  >
                    <span className='dropdown-item-icon dis-mr-8'>
                      <DeleteButtonicon />
                    </span>
                    Delete your image
                  </li>
                </ul>
              </div> */}
						{/* )} */}
						<div className="image-edit-wrapper">
							<ul className="image-edit-list d-flex align-items-center">
								<li className="image-edit-item cursor-pointer">
									<input type="file" onChange={onImageChange} className="filetype" id="file" />
									<label htmlFor="file" className="plt-btn plt-btn-sm plt-btn-primary font-weight-500">
										{/* <span className='dropdown-item-icon dis-mr-8'>
                        <UploadIconSmall />
                      </span> */}
										Upload new picture
									</label>
								</li>
								<li className="image-edit-item cursor-pointer" onClick={handleDeleteAvatar}>
									{/* <span className='dropdown-item-icon dis-mr-8'>
                      <DeleteButtonicon />
                    </span> */}
									<span className="plt-btn plt-btn-sm plt-btn-secondary font-weight-500">Delete your image</span>
								</li>
							</ul>
						</div>
					</div>

					<div className="account-row  d-flex justify-content-between align-items-center">
						{fields.map((field) => (
							<div key={field.name} className="plt-select-dropdown-wrapper dis-mb-24">
								<h6 className="plt-select-dropdown-title plt-ui-text-md-medium dis-mb-4">{field.label}</h6>
								<div className="plt-multi-dropdown-wrapper">
									<input
										type="text"
										className="input-field-custom modal-value-btn plt-ui-text-sm-regular plt-font-color-primary"
										name={field.name}
										value={formData[field.name]}
										onChange={handleChange}
									/>
								</div>
								{errors[field.name] && <FormValidationError error={errors[field.name]} />}
							</div>
						))}
					</div>

					{/* <div className='plt-select-dropdown-wrapper dis-mb-24'>
            <h6 className='plt-select-dropdown-title plt-ui-text-md-medium dis-mb-4'>
              Company Logo
            </h6>

            <img
              src={
                user.company_logo ? (
                  `${apiUrl}${user.logo_url}`
                ) : (
                  <PrimaryLogo />
                )
              }
              alt='logo'
              className='user-image'
            />
          </div> */}
					{/*
          <div className='dis-mb-32'>
            <input
              type='file'
              name='logo'
              onChange={onLogoChange}
              className='filetype'
            />
          </div> */}

					<div className="password-block dis-mb-40">
						<p className="password-block-txt dis-mb-16">
							<span className="d-block plt-body-compact-md plt-font-color-primary font-weight-600">
								{/* {audit?.updated_at
                  ? `Your password was last changed ${audit?.last_updated_at}.`
                  : "You havn't change password"} */}
							</span>
							<span className="d-block plt-body-compact-md font-weight-400 dis-mt-4">
								{/* {audit?.updated_at &&
                  `Password changed on ${audit?.updated_at}`} */}
							</span>
						</p>

						<span
							className="plt-modal-link plt-ui-text-sm-medium cursor-pointer d-block w-fit"
							onClick={openChangePasswordModal}
						>
							Change password
						</span>
					</div>

					<DomainTable />
				</div>

				<div className="d-flex align-items-center justify-content-between">
					<div className="d-flex align-items-center">
						<DeleteButtoniconRed />
						<span
							className="plt-modal-link plt-ui-text-sm-medium cursor-pointer plt-font-color-negative dis-ml-8"
							onClick={() => {
								setDeleteConfirmModalOpen(true);
							}}
						>
							Delete Account
						</span>
						{/* All your data will be deleted and you will not be able to access this
          account afterwards. */}
					</div>

					<div className="plt-modal-footer dis-p-0">
						<button className="plt-btn plt-btn-secondary" onClick={handleCancel}>
							Cancel
						</button>
						<button className="plt-btn plt-btn-primary" onClick={handleSave}>
							Save changes
						</button>
					</div>
				</div>
			</div>
			<ChangePasswordModal
				isOpen={isChangePasswordModalOpen}
				onRequestClose={closeChangePasswordModal}
				onApply={handleChangePasswordApply}
				title="Change password"
				setChangePasswordModalOpen={setChangePasswordModalOpen}
			/>
			<ConfirmDialog
				footerClassName="plt"
				isOpen={isDeleteConfirmModalOpen}
				onRequestClose={closeDeleteConfirmModal}
				onApply={handleDelete}
				title="Delete your account?"
				message="Deleting your user account will loose access to all connected workspaces. Please use caution before deleting your user."
			/>
		</div>
	);
};

export default Account;
