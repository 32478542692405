import { tokenDropdown, uiTextTokens } from "api";
import CommonModal from "components/Common/CommonModal";
import TabNavigation from "components/DesignSystemPage/TabNavigation/index";
import Dropdown from "components/Dropdown";
import TypographyTokenDropdown from "components/TypographyTokenDropdown";
import AuthAction from "components/auth/AuthAction";
import Permission from "components/auth/Permission";
import { BODY_SIZE, SCALE } from "constants";
import { useGlobalContext } from "context/GlobalContext";
import { generateFontSizes } from "helpers/scale";
import { getScssProperty, setScssProperty } from "helpers/style";
import { updateCSSVariables } from "helpers/token";
import { addToLocalStorage } from "hooks/storageUtils";
import useScrollManagement from "hooks/useScrollManagement";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ScaleTable from "./ScaleTable";
import "./ScaleToken.scss";
import fontSizeData from "./fontSizeData";
import SelectInput from "components/SelectInput";
import { removePx } from "helpers/transform";

const ScaleToken = () => {
  const { id } = useParams();
  const { setLoading, designSystemMeta, pusherIsLoading, designSystem } =
    useGlobalContext();
  const initialScale = SCALE.find(
    (s) => s.value === designSystem?.core?.fontSize?.baseScale?.value
  );
  const initialBodySize = BODY_SIZE.find(
    (s) => `${s.value}px` === designSystem?.core?.fontSize?.base?.value
  );

  const [selectedScale, setSelectedScale] = useState(initialScale);
  const [selectedBodySize, setSelectedBodySize] = useState(initialBodySize);

  const [data, setData] = useState({});
  const [uiData, setUiData] = useState({});
  const [selectedToken, setSelectedToken] = useState({});
  const [selectedRef, setSelectedRef] = useState("");
  const [charCount, setCharCount] = useState(0);
  const [isEditTypographyModalOpen, setEditTypographyModalOpen] =
    useState(false);
  const [activeSideTab, setActiveSideTab] = useState(0);
  const [isTypeScaleModalOpen, setTypeScaleModalOpen] = useState(false);
  const maxChar = 20;
  const fontSizeVariables = fontSizeData();
  const tabData = ["Heading", "Body text", "UI Component text"];
  useEffect(() => {
    const fetchData = async () => {
      const types =
        "fontWeights,fontSizes,lineHeights,letterSpacing,fontFamilies";
      try {
        const data = await tokenDropdown(id, types);
        setData(data);
      } catch (error) {
        console.error("Failed to fetch design system", error);
      }
    };

    const fetchUItext = async () => {
      try {
        const user = JSON.parse(localStorage.getItem("user"));
        const userId = user?.id;
        setLoading(true);
        if (userId) {
          const { data } = await uiTextTokens(id);
          setUiData(data);
        } else {
          console.error("User ID not found in localStorage");
        }
      } catch (error) {
        console.error("Failed to fetch design systems", error);
      } finally {
        setLoading(false);
      }
    };
    if (!pusherIsLoading) {
      fetchData();
      fetchUItext();
    }
  }, [pusherIsLoading]);

  const filterUiTexts = (data, filter) =>
    data &&
    Object.values(data)?.filter((ui) => {
      return ui.key.includes(filter);
    });
  const headingTableData = filterUiTexts(uiData, "core.font.heading");

  const bodyTableData = filterUiTexts(uiData, "core.font.body");

  const UItableData = filterUiTexts(uiData, "core.font.uitext");

  const typeScaleSections = [
    {
      title: "Headings",
      description:
        "Headings & Sub headings are clear and succinct descriptions of their following content. Your heading style should address the issues with Visual hierarchy and should be able to differentiate with the body text that follows. You can change the heading size with the help of tokens",
      rows: headingTableData,
      type: "heading",
    },
    {
      title: "Body text",
      description:
        "Body text is used for long-form experiences where a paragraph or multiple lines of text are required. Body text styles are optimised for reading as a large chunk of information through line height and paragraph spacing. Inline links sit within body text styles and inherit the same font values.",
      rows: bodyTableData,
      type: "body",
    },
    {
      title: "UI Component text",
      description:
        "UI text is text used in UI components, and is reserved for text read in a single line with clear actions or guidance. Optimisation for UI text style is significantly different to Heading or Body texts because of its use case",
      rows: UItableData,
      type: "ui-text",
    },
  ];

  const sectionRefs = typeScaleSections.map(() => React.useRef(null));
  const scrollToSection = useScrollManagement(
    sectionRefs,
    activeSideTab,
    setActiveSideTab,
    "tab-content-body"
  );

  const openModal = () => {
    setEditTypographyModalOpen(true);
  };

  const onApply = () => {
    setEditTypographyModalOpen(false);

    const convertedTypography = {
      fontFamily: `{${selectedToken.fontFamily.key.replace("core.", "")}}`,
      fontWeight: `{${selectedToken.fontWeight.key.replace("core.", "")}}`,
      fontSize: `{${selectedToken.fontSize.key.replace("core.", "")}}`,
      lineHeight: `{${selectedToken.lineHeight.key.replace("core.", "")}}`,
    };
    const tokenData = [
      { key: selectedToken.key, value: convertedTypography, type_id: 2 },
    ];

    addToLocalStorage(tokenData);
    const uiTextProperties = [
      "font-family",
      "font-weight",
      "font-size",
      "line-height",
    ];

    updateCSSVariables(
      "--dss-" + selectedToken.scale + "-font-weight",
      selectedToken.fontWeight.value
    );
    updateCSSVariables(
      "--dss-" + selectedToken.scale + "-font-size",
      selectedToken.fontSize.value
    );
    updateCSSVariables(
      "--dss-" + selectedToken.scale + "-line-height",
      selectedToken.lineHeight.value
    );

    //Paint dom on edit
    if (selectedRef) {
      selectedRef.current.style.fontWeight = selectedToken.fontWeight.value;
      selectedRef.current.style.fontSize = selectedToken.fontSize.value;
      selectedRef.current.style.lineHeight = selectedToken.lineHeight.value;
    }
    //   updateCSSVariables("--dss-heading-3xl-font-weight", "100");
    //update rows
    updateState(selectedToken);
  };

  const updateState = (token) => {
    const newUiData = [...uiData];

    const updatedUiData = newUiData.map((ui) => {
      if (ui.id !== token.id) {
        return ui;
      } else {
        return {
          ...ui,
          ...token,
        };
      }
    });
    setUiData(updatedUiData);
  };

  const handleBodySizeChange = (value) => {
    setSelectedBodySize(value);
    setTypeScale(value?.value, selectedScale?.value);
  };
  const setTypeScale = (base, scale) => {
    const fontSizes = generateFontSizes(base, scale);

    fontSizeVariables.map((variable, index) => {
      setScssProperty(variable.fontSizeToken, `${fontSizes[index]}px`);
    });
  };

  const handleScaleChange = (value) => {
    setSelectedScale(value);
    setTypeScale(selectedBodySize?.value, value.value);
  };

  const onScaleApply = () => {
    setTypeScaleModalOpen(false);

    const fontSizes = generateFontSizes(
      selectedBodySize.value,
      selectedScale.value
    );

    const scaleTokens = fontSizeVariables.map((variable, index) => {
      return {
        key: variable.fontSizeKey,
        value: `${fontSizes[index]}px`,
      };
    });
    updateStateWithTypeScale(scaleTokens);
    addToLocalStorage(scaleTokens);
  };

  const updateStateWithTypeScale = (scaleTokens) => {
    let newUiData = [...uiData];
    let updatedUiData = [];
    scaleTokens.map((token) => {
      updatedUiData = newUiData.map((ui) => {
        if (ui.fontSize.key !== token.key) {
          return ui;
        } else {
          return {
            ...ui,
            fontSize: {
              ...ui.fontSize,
              value: token.value,
            },
          };
        }
      });
      newUiData = [...updatedUiData];
    });

    setUiData(updatedUiData);
  };

  const handleInputChange = (field, value) => {
    if (field === "description" && value.length > maxChar) {
      return;
    }
    const transformedValue = { key: value.value, value: value.label };

    setSelectedToken({ ...selectedToken, [field]: transformedValue });
    if (field === "description") {
      setCharCount(value.length);
    }
  };

  const closeModal = () => {
    setEditTypographyModalOpen(false);
  };

  const onScaleCancel = () => {
    setTypeScaleModalOpen(false);
    setSelectedBodySize(initialBodySize);
    setSelectedScale(initialScale);

    fontSizeVariables.map((variable, index) => {
      setScssProperty(
        variable.fontSizeToken,
        data.fontSizes.find((fontSize) => fontSize.key === variable.fontSizeKey)
          .value
      );
    });
  };

  const handleTabClick = (index) => {
    setActiveSideTab(index);
    scrollToSection(index, sectionRefs);
  };

  return (
    <div className='d-flex justify-content-between w-full'>
      <div className='tab-content-container'>
        <div className='plt-value-wrapper-top d-flex flex-column'>
          <div className='plt-value-wrapper d-flex align-items-center dis-mb-16'>
            <h6 className='plt-value-title dis-pr-8 plt-heading-md font-weight-600 plt-font-color-primary'>
              Type scale:
            </h6>
            <h4 className='plt-value-txt plt-heading-xs font-weight-600 plt-font-color-primary'>
              {selectedScale?.label}
            </h4>
          </div>
          <div className='plt-value-wrapper d-flex align-items-center dis-mb-8'>
            <h6 className='plt-value-title dis-pr-8 plt-heading-xs plt-font-color-primary'>
              Base font size:
            </h6>
            <div className='plt-value-btn'>
              <span className='plt-value-btn-txt plt-ui-text-sm-medium font-weight-600'>
                {/* {selectedBodySize.value}px */}
                {selectedBodySize?.value}px
              </span>
            </div>
          </div>
        </div>
        <AuthAction>
          <Permission designSystem={designSystemMeta}>
            <span
              onClick={() => setTypeScaleModalOpen(true)}
              className='plt-modal-link plt-ui-text-sm-medium dis-mb-24 cursor-pointer d-block w-fit'
            >
              Change type scale
            </span>
          </Permission>
        </AuthAction>
        {typeScaleSections.map((section, index) => (
          <div
            ref={sectionRefs[index]}
            key={index}
            // className={`plt-content-block ${index !== 0 ? "dis-mt-48" : ""}`}
            className={`plt-content-block dis-mb-48`}
          >
            <div className='plt-content-wrapper dis-mb-24'>
              <div className='plt-content-block-top d-flex justify-content-between align-items-center dis-mb-8'>
                <h6 className='plt-contents-head plt-heading-md plt-font-color-primary'>
                  {section.title}
                </h6>
              </div>
              <p className='plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400'>
                {section.description}
              </p>
            </div>
            <ScaleTable
              rows={section.rows}
              type={section.type}
              openModal={openModal}
              setSelectedToken={setSelectedToken}
              setSelectedRef={setSelectedRef}
            />
          </div>
        ))}
        {/* <ScaleTable rows={UItableData} type={"ui-text"} /> */}
      </div>
      <div className='plt-token-contents'>
        <TabNavigation
          tabs={tabData}
          title={"CONTENTS"}
          activeIndex={activeSideTab}
          onTabClick={handleTabClick}
        />
      </div>

      <CommonModal
        isOpen={isEditTypographyModalOpen}
        onRequestClose={closeModal}
        title='Edit typography'
        onApply={onApply}
        onCancelText='Cancel'
        onApplyText='Apply'
      >
        <div className='plt-modal-body-content'>
          <div className='plt-preview plt-heading-xl dis-mb-32'>
            <div
              className={`plt-font-preview dss-${selectedToken?.scale}`}
              style={{
                fontFamily: selectedToken?.fontFamily?.value,
                fontWeight: selectedToken?.fontWeight?.value,
                fontSize: selectedToken?.fontSize?.value,
                lineHeight: selectedToken?.lineHeight?.value,
              }}
            >
              Heading over two or three lines
            </div>
          </div>
          <div className='modal-value-wrapper'>
            <h6 className='modal-value-title plt-ui-text-sm-regular plt-font-color-primary dis-mb-4'>
              Set name
            </h6>
            <div className='modal-value-block w-100'>
              <p className='plt-badge plt-token-value-name plt-body-compact-sm'>
                {selectedToken.className}
              </p>
            </div>
          </div>
          <div className='typo-modal-detail d-flex align-items-center justify-content-between dis-mt-32'>
            <div className='plt-select-dropdown-wrapper'>
              <h6 className='plt-select-dropdown-title plt-ui-text-sm-regular plt-font-color-primary dis-mb-4'>
                Weight
              </h6>
              <div className='modal-value-block w-100'>
                <TypographyTokenDropdown
                  name='fontWeight'
                  options={data?.fontWeights}
                  selectedOption={selectedToken.fontWeight}
                  onSelect={handleInputChange}
                />
              </div>
            </div>
            <div className='plt-select-dropdown-wrapper'>
              <h6 className='plt-select-dropdown-title plt-ui-text-sm-regular plt-font-color-primary dis-mb-4'>
                Size
              </h6>
              <div className='modal-value-block w-100'>
                <TypographyTokenDropdown
                  name='fontSize'
                  options={data?.fontSizes}
                  selectedOption={selectedToken.fontSize}
                  onSelect={handleInputChange}
                />
              </div>
            </div>
            <div className='plt-select-dropdown-wrapper'>
              <h6 className='plt-select-dropdown-title plt-ui-text-sm-regular plt-font-color-primary dis-mb-4'>
                Line height
              </h6>
              <div className='modal-value-block w-100'>
                <TypographyTokenDropdown
                  name='lineHeight'
                  options={data?.lineHeights}
                  selectedOption={selectedToken.lineHeight}
                  onSelect={handleInputChange}
                />
              </div>
            </div>
          </div>
          <div className='modal-textarea-wrapper dis-mt-32 dis-mb-24'>
            <h6 className='modal-textarea-title plt-ui-text-sm-regular plt-font-color-primary'>
              Add description
            </h6>
            <textarea
              className='modal-textarea w-100'
              placeholder='Text'
              value={selectedToken.description}
              onChange={(e) => handleInputChange("description", e.target.value)}
            ></textarea>
            <div className='modal-textarea-info d-flex align-items-center justify-content-between dis-mt-4'>
              <span className='modal-textarea-desc plt-ui-text-sm-regular'>
                Helper text
              </span>
              <span className='modal-textarea-desc plt-ui-text-sm-regular'>
                {charCount}/{maxChar}
              </span>
            </div>
          </div>
        </div>
      </CommonModal>

      <CommonModal
        isOpen={isTypeScaleModalOpen}
        onRequestClose={onScaleCancel}
        title='Change type scale'
        onApply={onScaleApply}
        onCancelText='Cancel'
        onApplyText='Apply'
        // variant="modal-secondary"
      >
        <div className='plt-modal-body-content'>
          <div className='plt-select-scale-wrapper w-100 d-flex align-items-center'>
            <div className='plt-dropdown-secondary'>
              <div className='plt-select-dropdown-wrapper d-flex align-items-center justify-content-between'>
                <div className='plt-dropdown-wrapper'>
                  <SelectInput
                    name='scale'
                    options={SCALE}
                    selectedOption={selectedScale}
                    defaultValue={selectedScale}
                    onSelect={handleScaleChange}
                  />
                </div>
              </div>
            </div>
            <div className='plt-dropdown-secondary'>
              <div className='plt-select-dropdown-wrapper d-flex align-items-center justify-content-between'>
                <div className='plt-dropdown-wrapper plt-dropdown-wrapper-commonsize'>
                  <SelectInput
                    name='fontSize'
                    options={BODY_SIZE}
                    defaultValue={selectedBodySize}
                    selectedOption={selectedBodySize}
                    onSelect={handleBodySizeChange}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className='plt-preview-group'>
            {fontSizeVariables.map((item, index) => (
              <div className='plt-preview' key={index}>
                <div
                  className={`plt-font-preview ${item.className}`}
                  style={{
                    fontFamily: data?.fontFamilies?.find(
                      (fontFamily) =>
                        fontFamily.key === "core.fontFamily.primary"
                    )?.value,
                  }}
                >
                  Heading over two or three lines
                </div>
                <span className='d-flex justify-content-end align-item-end'>
                  {getScssProperty(item.fontSizeToken)}
                </span>
              </div>
            ))}
          </div>
        </div>
      </CommonModal>
    </div>
  );
};

export default ScaleToken;
