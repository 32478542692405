import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import "./CommonModal.scss";
import { ModalCloseIcon } from "../../Icons/index";
import PrimaryButton from "../PrimaryButton";
import UploadButton from "components/Buttons/UploadButton";

const FontCommonModal = ({
  isOpen,
  children,
  onRequestClose,
  title,
  onUpload,
  onApply,
  variant,
  onCancelText = "Cancel",
  onApplyText = "Apply",
  bodyClassName,
}) => {
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    }
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [isOpen]);

  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <div className={`plt-modal-overlay ${variant}`}>
      <div className='plt-modal-content' onClick={(e) => e.stopPropagation()}>
        <div className='plt-modal-header'>
          <h5 className='plt-modal-header-title plt-heading-sm plt-font-color-primary'>
            {title}
          </h5>
          <span
            className='plt-modal-close-icon plt-iconbtn'
            onClick={onRequestClose}
          >
            <ModalCloseIcon />
          </span>
        </div>
        <div className={`plt-modal-body ${bodyClassName}`}>{children}</div>
        <div className='plt-modal-footer'>
          <PrimaryButton
            // className={`dss-btn dss-btn-secondary dss-ui-text-md-medium mr-auto font-upload-btn`}
            text='Upload fonts'
            variant= "secondary"
            icon={true}
            fluid={false}
            className='mr-auto font-upload-btn'
            //  className={`mr-auto font-upload-btn`}
            onClick={() => onUpload(true)}
            prefixIcon={<UploadButton />}

          />
          <PrimaryButton
            // className={`dss-btn dss-btn-secondary dss-ui-text-md-medium `}
            text={onCancelText}
            onClick={onRequestClose}
             variant= "secondary"
          />
          {onApply && (
            <PrimaryButton
              // className={`dss-btn dss-btn-primary dss-ui-text-md-medium `}
              text={onApplyText}
              onClick={onApply}
              variant= "primary"
            />
          )}
        </div>
      </div>
    </div>,
    document.body
  );
};

export default FontCommonModal;
