import { useState } from "react";
import { CheckboxComponentPrimaryDatas } from "../../../../assets/data/data";
import ComponentCard from "../../../../components/Common/ComponentCard";
import CheckboxComponent from "../../../Common/CheckboxComponent";
import TabNavigation from "../../TabNavigation";
import "./PreviewCheckboxComponent.scss";

const PreviewCheckboxComponent = ({ tabData, handleTabClick, activeTab, setSelectedTab }) => {
	// const { isLoading } = useGlobalContext();
	const [isModalVisible, setModalVisibility] = useState(false);
	const [checkedStates, setCheckedStates] = useState(
		CheckboxComponentPrimaryDatas.map(() => true), // Initialize all checkboxes as checked
	);

	const showModal = () => {
		setModalVisibility(true);
	};

	const handleCheckboxChange = (index) => {
		setCheckedStates((prevStates) => prevStates.map((checked, i) => (i === index ? !checked : checked)));
	};

	const AllUsage = () => (
		<p className="plt-character-des plt-body-compact-sm dis-pt-12 dis-mb-32">
			See all states{" "}
			<span
				onClick={() => setSelectedTab(1)}
				className="plt-text-underline plt-body-compact-sm plt-font-color-primary font-weight-500 cursor-pointer"
			>
				usage
			</span>
		</p>
	);

	return (
		<div className="tab-content-wrapper d-flex justify-content-between">
			<div className="tab-content-container">
				<div className="plt-content-wrapper dis-mb-24">
					<h6 className="plt-contents-head plt-heading-md plt-font-color-primary dis-mb-8">Default</h6>
					<p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
						Use a primary button to call attention to an action on a form or to highlight the strongest call to action
						on a page. Primary buttons should only appear once per container (not including the application header or in
						a modal dialog). Not every screen requires a primary button.
					</p>
				</div>
				{CheckboxComponentPrimaryDatas.map((data, index) => (
					<div key={data.id} className="dis-mb-32">
						<div className="plt-content-wrapper dis-mb-24">
							<h6 className="plt-contents-head plt-heading-sm plt-font-color-primary dis-mb-8">{data.title}</h6>
							<p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
								{data.description}
							</p>
						</div>
						<ComponentCard editClick={showModal}>
							<CheckboxComponent
								label="Check box label"
								size={data.size}
								variant="default"
								value={data.checked}
								onChange={() => handleCheckboxChange(index)}
							/>
						</ComponentCard>
						<AllUsage />
					</div>
				))}

				
				<div className="plt-content-wrapper dis-mb-24">
					<h6 className="plt-contents-head plt-heading-md plt-font-color-primary dis-mb-8">Disabled</h6>
					<p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
						Use a primary button to call attention to an action on a form or to highlight the strongest call to action
						on a page. Primary buttons should only appear once per container (not including the application header or in
						a modal dialog). Not every screen requires a primary button.
					</p>
				</div>
				{CheckboxComponentPrimaryDatas.map((data, index) => (
					<div key={data.id} className="dis-mb-32">
						<div className="plt-content-wrapper dis-mb-24">
							<h6 className="plt-contents-head plt-heading-sm plt-font-color-primary dis-mb-8">{data.title}</h6>
							<p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
								{data.description}
							</p>
						</div>
						<ComponentCard editClick={showModal}>
							<CheckboxComponent
								label="Check box label"
								size={data.size}
								variant="default"
                disabled={true}
								value={data.checked}
								checked={checkedStates[index]}
								onChange={() => handleCheckboxChange(index)}
							/>
						</ComponentCard>
						<AllUsage />
					</div>
				))}

        <div className="plt-content-wrapper dis-mb-24">
					<h6 className="plt-contents-head plt-heading-md plt-font-color-primary dis-mb-8">Outline</h6>
					<p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
						Use a primary button to call attention to an action on a form or to highlight the strongest call to action
						on a page. Primary buttons should only appear once per container (not including the application header or in
						a modal dialog). Not every screen requires a primary button.
					</p>
				</div>
				{CheckboxComponentPrimaryDatas.map((data, index) => (
					<div key={data.id} className="dis-mb-32">
						<div className="plt-content-wrapper dis-mb-24">
							<h6 className="plt-contents-head plt-heading-sm plt-font-color-primary dis-mb-8">{data.title}</h6>
							<p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
								{data.description}
							</p>
						</div>
						<ComponentCard editClick={showModal}>
							<CheckboxComponent
								label="Check box label"
								size={data.size}
								variant="outline"
								value={data.checked}
								onChange={() => handleCheckboxChange(index)}
							/>
						</ComponentCard>
						<AllUsage />
					</div>
				))}


        <div className="plt-content-wrapper dis-mb-24">
					<h6 className="plt-contents-head plt-heading-md plt-font-color-primary dis-mb-8">Outline-Disabled</h6>
					<p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
						Use a primary button to call attention to an action on a form or to highlight the strongest call to action
						on a page. Primary buttons should only appear once per container (not including the application header or in
						a modal dialog). Not every screen requires a primary button.
					</p>
				</div>
				{CheckboxComponentPrimaryDatas.map((data, index) => (
					<div key={data.id} className="dis-mb-32">
						<div className="plt-content-wrapper dis-mb-24">
							<h6 className="plt-contents-head plt-heading-sm plt-font-color-primary dis-mb-8">{data.title}</h6>
							<p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
								{data.description}
							</p>
						</div>
						<ComponentCard editClick={showModal}>
							<CheckboxComponent
								label="Check box label"
								size={data.size}
								variant="outline"
                disabled={true}
								value={data.checked}
								checked={checkedStates[index]}
								onChange={() => handleCheckboxChange(index)}
							/>
						</ComponentCard>
						<AllUsage />
					</div>
				))}
				{/* {!isLoading ? (
          <div className=" d-flex ">
            <PrimaryButton
              onClick={{}}
              text={"Button text"}
              className={`dss-btn dss-btn-primary dss-btn-lg`}
            />
          </div>
        ) : (
          <>
            <ColorRing
              visible={true}
              height="40"
              width="40"
              ariaLabel="color-ring-loading"
              wrapperStyle={{}}
              wrapperClass="color-ring-wrapper"
              colors={["#e15b64", "#f47e60", "#f8b26a", "#abbd81", "#849b87"]}
            />
          </>
        )} */}
			</div>
			{/* <CommonModal
        isOpen={isModalVisible}
        onRequestClose={() => setModalVisibility(false)}
        title="Tokens"
        onApply={() => setModalVisibility(false)}
        onCancelText="Cancel"
        onApplyText="Apply"
        variant="modal-tertiary"
      >
        <div className="plt-modal-body-content">
          <div className="plt-dropdown-secondary">
            <div className="plt-select-dropdown-wrapper d-flex align-items-center justify-content-between">
              <div className="plt-dropdown-wrapper plt-dropdown-wrapper-common">
                <CustomSelect
                  options={{}}
                  selectedOption={{}}
                />
              </div>
            </div>
          </div>

          <div className="plt-preview plt-heading-xl dis-mb-32">
            {previewCheckboxData.map((data, index) => (
              <CheckboxComponent
                label="Check box label"
                size={data.size}
                variant="default"
              />
            ))}
          </div>

          <div className="plt-token-details dis-mb-32">
            <h6 className="plt-token-title plt-heading-xs plt-font-color-primary dis-mb-4">
              Border color default
            </h6>
            <div className="plt-token-value-item d-flex">
              <p className="plt-badge plt-token-value-name plt-body-compact-sm dis-mr-12">
                text.button.fill.primary
              </p>
              <span
                className="plt-iconbtn plt-iconbtn-sm plt-token-value-icon"
                onClick={() => {
                  setTokenType("text");
                  setColorTokenModalOpen(true);
                }}
              >
                <EditIcon />
              </span>
            </div>
          </div>

          <div className="plt-token-details dis-mb-32">
            <h6 className="plt-token-title plt-heading-xs plt-font-color-primary dis-mb-4">
              Border color active
            </h6>
            <div className="plt-token-value-item d-flex">
              <p className="plt-badge plt-token-value-name plt-body-compact-sm dis-mr-12">
                text.button.fill.primary
              </p>
              <span
                className="plt-iconbtn plt-iconbtn-sm plt-token-value-icon"
                onClick={() => {
                  setTokenType("text");
                  setColorTokenModalOpen(true);
                }}
              >
                <EditIcon />
              </span>
            </div>
          </div>

          <div className="plt-token-details dis-mb-32">
            <h6 className="plt-token-title plt-heading-xs plt-font-color-primary dis-mb-4">
              Background color active
            </h6>
            <div className="plt-token-value-item d-flex">
              <p className="plt-badge plt-token-value-name plt-body-compact-sm dis-mr-12">
                background.button.{buttonState.toLowerCase()}.primary
              </p>
              <span
                className="plt-iconbtn plt-iconbtn-sm plt-token-value-icon"
                onClick={() => {
                  setTokenType("bg");
                  setColorTokenModalOpen(true);
                }}
              >
                <EditIcon />
              </span>
            </div>
          </div>

          <div className="plt-token-details dis-mb-32">
            <h6 className="plt-token-title plt-heading-xs plt-font-color-primary dis-mb-4">
              Text color
            </h6>
            <div className="plt-token-value-item d-flex">
              <p className="plt-badge plt-token-value-name plt-body-compact-sm dis-mr-12">
                text.button.fill.primary
              </p>
              <span
                className="plt-iconbtn plt-iconbtn-sm plt-token-value-icon"
                onClick={() => {
                  setTokenType("text");
                  setColorTokenModalOpen(true);
                }}
              >
                <EditIcon />
              </span>
            </div>
          </div>

          <div className="plt-token-details dis-mb-24">
            <h6 className="plt-token-title plt-heading-xs plt-font-color-primary dis-mb-4">
              Padding
            </h6>
            <div className="plt-token-value-item d-flex align-items-center">
              <div className="d-flex align-items-center">
                <span className="dis-mr-4">H:</span>
                <p className="plt-badge plt-token-value-name plt-body-compact-sm dis-mr-12">
                  spacing-200
                </p>
              </div>
              <div className="d-flex align-items-center">
                <span className="dis-mr-4">V:</span>
                <p className="plt-badge plt-token-value-name plt-body-compact-sm dis-mr-12">
                  spacing-200
                </p>
              </div>

              <span className="plt-iconbtn plt-iconbtn-sm plt-token-value-icon">
                <EditIcon />
              </span>
            </div>
          </div>
        </div>
      </CommonModal> */}
			<div className="plt-token-contents">
				<TabNavigation tabs={tabData} title={"CONTENTS"} activeIndex={activeTab} onTabClick={handleTabClick} />
			</div>
		</div>
	);
};

export default PreviewCheckboxComponent;
