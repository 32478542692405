import CommonModal from "components/Common/CommonModal";
import PrimaryButton from "components/Common/PrimaryButton";
import TokenDropdown from "components/TokenDropdown";

function TextTokenModal({
  isTextTokenModalOpen,
  setTextTokenModalOpen,
  selectedClassName,
  buttonState,
  spaceVValue,
  spaceHValue,
  textdecoration,
  selectedtextdecoration,
  handleTextdecorationChange,
}) {
  return (
    <CommonModal
      isOpen={isTextTokenModalOpen}
      onRequestClose={() => setTextTokenModalOpen(false)}
      title="Change token"
      onApply={() => handleApplyTextChange()}
      onCancelText="Cancel"
      onApplyText="Apply"
      // variant="modal-tertiary"
    >
      <div className="plt-modal-body-content">
        <div
          className="plt-preview dis-mb-32"
          style={{ fontFamily: buttonState }}
        >
          <PrimaryButton
            // onClick={() => {}}
            text={"Button text"}
            className={` ${buttonState} ${selectedClassName} d-flex m-auto`}
            disabled={buttonState === "disabled"}
            style={{
              padding: `${spaceVValue?.value}  ${spaceHValue?.value}`,
            }}
          />
        </div>

        <div className="plt-padding-wrapper d-flex  dis-mb-32">
          <div className="plt-select-dropdown-wrapper d-flex align-items-center w-100">
            <h6 className="plt-select-dropdown-title dis-mb-4 plt-ui-text-sm-regular">
              Text decoration
            </h6>
            <div className="plt-dropdown-wrapper">
              {/* <CustomSelect
              options={textdecoration}
              selectedOption={selectedtextdecoration}
              onSelect={handleTextdecorationChange}
            /> */}
              <TokenDropdown
                name="textdecoration"
                options={textdecoration.map((textdecoration) => ({
                  value: textdecoration?.value,
                  label: `${textdecoration?.value}`,
                }))}
                selectedOption={{
                  value: selectedtextdecoration,
                  label: selectedtextdecoration,
                }}
                // onSelect={(name, option) =>
                //   handleTextdecorationChange(
                //     textdecoration.find((textdecoration) => textdecoration?.value === option?.value)
                //   )
                // }
                onSelect={handleTextdecorationChange}
              />
            </div>
          </div>
        </div>
      </div>
    </CommonModal>
  );
}

export default TextTokenModal;
