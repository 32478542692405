import CommonModal from "components/Common/CommonModal";
import PrimaryButton from "components/Common/PrimaryButton";
import ColorPickerComponent from "components/DesignSystemPage/ColorPickerComponent";

const ColorSectionComponent = ({
	title,
	description,
	colorData,
	type,
	setTokenState,
	buttonState,
	tokenState,
	initialColor,
}) => {
	return (
		<div className="plt-content-wrapper dis-mb-32">
			{title && type?.length && (
				<h6 className="plt-contents-head plt-heading-sm plt-font-color-primary dis-mb-12">{title}</h6>
			)}
			{description && !type?.length && (
				<p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400 dis-mb-24">
					{description}
				</p>
			)}

			{colorData.map((color, index) => (
				<>
					{color?.subTitle && (
						<p className="plt-subtitle plt-heading-xs plt-font-color-primary dis-mb-8">{color?.subTitle}</p>
					)}
					<ColorPickerComponent
						key={index}
						initialColor={initialColor}
						buttonState={buttonState}
						setTokenState={setTokenState}
						type={type}
						tokenState={tokenState}
						baseColor={color.baseColor}
						id={color.id}
						shades={color.shades}
						tokenType={color.tokenType}
					/>
				</>
			))}
		</div>
	);
};

function ColorTokenModal({
	isColorTokenModalOpen,
	setColorTokenModalOpen,
	setButtonSelectedColor,
	buttonState,
	selectedClassName,
	componentInfo,
	colorSections,
	onApplyChanges,
	tokenType,
	selectedTokenData,
	setTokenState,
}) {
	return (
		<CommonModal
			isOpen={isColorTokenModalOpen}
			onRequestClose={() => {
				setButtonSelectedColor(null), setColorTokenModalOpen(false);
			}}
			title="Change token"
			onApply={onApplyChanges}
			onCancelText="Cancel"
			onApplyText="Apply"
			variant="change-token-modal"
		>
			<>
				<div className="plt-preview dis-mb-24">
					<PrimaryButton
						// onClick={() => {}}
						variant={componentInfo?.variant}
						size={componentInfo?.size}
						text={"Button text"}
						// className={` ${buttonState?.value.toLowerCase()} ${selectedClassName}  d-flex m-auto`}
						// className={`d-flex m-auto`}
						disabled={buttonState === "disabled"}
					/>
				</div>
				<div className="plt-modal-body-content dis-mt-32 dis-pl-24 dis-pr-24">
					{colorSections?.map((section, index) => (
						<div key={section.title}>
							<ColorSectionComponent
								{...section}
								onApplyChanges={onApplyChanges}
								type={tokenType}
								buttonState={buttonState}
								tokenState={selectedTokenData}
								setTokenState={setTokenState}
								initialColor={
									tokenType === "bg"
										? selectedTokenData[buttonState?.value.toLowerCase()]?.bg?.value
										: tokenType === "text"
											? selectedTokenData[buttonState?.value.toLowerCase()]?.text?.value
											: tokenType === "border"
												? selectedTokenData[buttonState?.value.toLowerCase()]?.border?.value
												: undefined
								}
							/>
						</div>
					))}
				</div>
			</>
		</CommonModal>
	);
}

export default ColorTokenModal;
