import TokenDropdown from "components/TokenDropdown";
import AuthAction from "components/auth/AuthAction";
import Permission from "components/auth/Permission";
import { useGlobalContext } from "context/GlobalContext";
import { addToLocalStorage } from "hooks/storageUtils";
import { useState } from "react";
import EditButton from "../../Buttons/EditButton";
import CommonModal from "../CommonModal";
import "./RadiusCard.scss";
import SelectInput from "components/SelectInput";

const RadiusCard = ({ RadiusBoxData, radiusValues }) => {
  const { designSystemMeta } = useGlobalContext();
  const [isRadiusModalOpen, setRadiusModalOpen] = useState(false);
  const [radiusData, setRadiusData] = useState(RadiusBoxData);
  const [selectedRadius, setSelectedRadius] = useState({
    label: radiusData.value,
    value: radiusData.value,
  });
  const [charCount, setCharCount] = useState(0);
  const maxChar = 20;

  const openModal = () => {
    setRadiusModalOpen(true);
  };

  const closeModal = () => {
    setRadiusModalOpen(false);
  };

  const handleRadiusValueChange = (value) => {
    setSelectedRadius(value);
  };

  const handleApply = () => {
    const updatedRadiusData = { ...radiusData, value: selectedRadius.value };
    setRadiusData(updatedRadiusData);
    addToLocalStorage([updatedRadiusData]);
    setRadiusModalOpen(false);
  };

  const [radius, setRadius] = useState({
    name: "heading-3xl",
    description: "",
    weight: "Regular",
    size: "16",
    lineHeight: "130%",
    letterSpacing: "0px",
    paragraphSpacing: "15px",
  });

  const handleInputChange = (field, value) => {
    if (field === "description" && value.length > maxChar) {
      return;
    }
    setRadius({ ...radius, [field]: value });
    if (field === "description") {
      setCharCount(value.length);
    }
  };

  return (
    <div>
      <div className='plt-common-card-box dis-p-24'>
        <h6 className='plt-common-card-title plt-heading-sm plt-font-color-primary dis-mb-4'>
          {radiusData?.name}
          <AuthAction>
            <Permission designSystem={designSystemMeta}>
              <div onClick={openModal}>
                <EditButton />
              </div>
            </Permission>
          </AuthAction>
        </h6>
        <div className='plt-common-card-box-wrapper d-flex justify-content-between'>
          <div className='plt-common-card-content'>
            <p className='plt-common-card-des plt-body-md dis-mb-24'>
              {radiusData?.content}
            </p>
            <h3 className='plt-badge plt-body-compact-sm dis-mb-10'>
              {radiusData?.tokenName}
            </h3>
            <div className='plt-common-card-axis d-flex'>
              <h5 className='plt-common-card-axis-txt plt-body-compact-sm '>
                {radiusData?.rem}
              </h5>
              <h5 className='plt-common-card-axis-txt plt-body-compact-sm '>
                {radiusData?.value}
              </h5>
            </div>
          </div>
          <div
            style={{ borderRadius: radiusData?.value }}
            className={`plt-common-card-img-box dss-${radiusData?.radiusType}`}
          ></div>
        </div>
      </div>

      <CommonModal
        isOpen={isRadiusModalOpen}
        onRequestClose={closeModal}
        title='Edit radius style'
        onApply={handleApply}
        onCancelText='Cancel'
        onApplyText='Apply'
      >
        <div className='plt-modal-body-content'>
          <div className='plt-preview plt-heading-xl dis-mb-32'>
            <div
              style={{
                borderRadius: selectedRadius.value || radiusData?.value,
              }}
              className='plt-common-card-img-box dss-small-radius m-auto'
            ></div>
          </div>
          <div className='modal-value-wrapper dis-mb-24'>
            <h6 className='modal-value-title plt-ui-text-sm-regular plt-font-color-primary dis-mb-4'>
              Style name
            </h6>
            <div className='modal-value-block w-100'>
              <p className='plt-badge plt-token-value-name plt-body-compact-sm'>
                {radiusData?.tokenName}
              </p>
            </div>
          </div>
          <div className='radius-modal-detail d-flex align-items-center justify-content-between dis-mb-24'>
            <div className='plt-select-dropdown-wrapper'>
              <h6 className='plt-select-dropdown-title plt-ui-text-sm-regular plt-font-color-primary dis-mb-4'>
                Value
              </h6>
              <div className='modal-value-block w-100'>
                <div className='plt-dropdown-wrapper plt-dropdown-wrapper-value'>
                  {/* radiusss */}

                  {/* <CustomSelect
                    options={radiusValues}
                    selectedOption={selectedRadiusValue}
                    onSelect={handleRadiusValueChange}
                  /> */}

                  <SelectInput
                    name='fontWeight'
                    options={radiusValues.map((radius) => ({
                      value: radius,
                      label: `${radius}`,
                    }))}
                    selectedOption={selectedRadius}
                    defaultValue={selectedRadius}
                    onSelect={handleRadiusValueChange}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className='modal-textarea-wrapper dis-mb-24'>
            <h6 className='modal-textarea-title plt-ui-text-sm-regular dis-mb-4'>
              Add description
            </h6>
            <textarea
              className='modal-textarea w-100'
              placeholder='Text'
              value={radius.description}
              onChange={(e) => handleInputChange("description", e.target.value)}
            ></textarea>
            <div className='modal-textarea-info d-flex align-items-center justify-content-between dis-mt-4'>
              <span className='modal-textarea-desc plt-ui-text-sm-regular'>
                Helper text
              </span>
              <span className='modal-textarea-desc plt-ui-text-sm-regular'>
                {charCount}/{maxChar}
              </span>
            </div>
          </div>
        </div>
      </CommonModal>
    </div>
  );
};

export default RadiusCard;
