import React, { useState } from "react";
import "./PreviewTooltipComponent.scss";
import { TooltipComponenSmallDatas, TooltipComponentPrimaryDatas } from "../../../../assets/data/data";
import TabNavigation from "../../TabNavigation";
import ComponentCard from "../../../Common/ComponentCard";
import Tooltip from "../../../Tooltip";
import CommonModal from "../../../Common/CommonModal";
import CustomSelect from "../../../Common/CustomSelect";
import EditButton from "../../../Buttons/EditButton";
import { EditIcon } from "components/Icons";

const PreviewTooltipComponent = ({ tabData, handleTabClick, activeTab, setSelectedTab }) => {
	const previewtooltipData = [
		{
			id: 1,
			title: "Bottom left arrow",
			description:
				"Use a primary button to call attention to an action on a form or to highlight the strongest call to action on a page.",
			position: "top",
			arrow: "btm-lft",
		},
	];

	const [isModalVisible, setModalVisibility] = useState(false);
	const showModal = () => {
		setModalVisibility(true);
	};

	const buttonStates = ["Default", "Hovered", "Pressed", "Disabled"];
	const [buttonState, setButtonState] = useState(buttonStates[0]);

	const AllUsage = () => (
		<p className="plt-character-des plt-body-compact-sm dis-pt-12 dis-mb-32">
			See all states{" "}
			<span
				onClick={() => setSelectedTab(1)}
				className="plt-text-underline plt-body-compact-sm plt-font-color-primary font-weight-500 cursor-pointer"
			>
				usage
			</span>
		</p>
	);

	return (
		<div className="tab-content-wrapper d-flex justify-content-between">
			<div className="tab-content-container">
				<div className="plt-content-wrapper dis-mb-24">
					<h6 className="plt-contents-head plt-heading-md plt-font-color-primary dis-mb-8">Short</h6>
					<p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
						A basic text field. If you use a text field outside of a form component, always use a label and associate
						the label to the field properly.
					</p>
				</div>
				{TooltipComponentPrimaryDatas.map((data) => (
					<div key={data.id} className="dis-mb-32">
						<div className="plt-content-wrapper dis-mb-24">
							<h6 className="plt-contents-head plt-heading-sm plt-font-color-primary dis-mb-8">{data.title}</h6>
							<p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
								{data.description}
							</p>
						</div>
						<ComponentCard editClick={showModal}>
							<Tooltip position={data.position} arrow={data.arrow} text="This is tooltip" label="Label">
								Hover this
							</Tooltip>
						</ComponentCard>
						<AllUsage />
					</div>
				))}
				<div className="plt-content-wrapper dis-mb-24">
					<h6 className="plt-contents-head plt-heading-md plt-font-color-primary dis-mb-8">Truncate</h6>
					<p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
						A basic text field. If you use a text field outside of a form component, always use a label and associate
						the label to the field properly.
					</p>
				</div>
				{TooltipComponentPrimaryDatas.map((data) => (
					<div key={data.id} className="dis-mb-32">
						<div className="plt-content-wrapper dis-mb-24">
							<h6 className="plt-contents-head plt-heading-sm plt-font-color-primary dis-mb-8">{data.title}</h6>
							<p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
								{data.description}
							</p>
						</div>
						<ComponentCard editClick={showModal}>
							<Tooltip
								position={data.position}
								arrow={data.arrow}
								text="In which case you can set them to truncate, which can still get quite lon"
								label="Label"
							>
								Hover this
							</Tooltip>
						</ComponentCard>
						<AllUsage />
					</div>
				))}
				<div className="plt-content-wrapper dis-mb-24">
					<h6 className="plt-contents-head plt-heading-md plt-font-color-primary dis-mb-8">Wrap</h6>
					<p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
						A basic text field. If you use a text field outside of a form component, always use a label and associate
						the label to the field properly.
					</p>
				</div>
				{TooltipComponentPrimaryDatas.map((data) => (
					<div key={data.id} className="dis-mb-32">
						<div className="plt-content-wrapper dis-mb-24">
							<h6 className="plt-contents-head plt-heading-sm plt-font-color-primary dis-mb-8">{data.title}</h6>
							<p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
								{data.description}
							</p>
						</div>
						<ComponentCard editClick={showModal}>
							<Tooltip
								position={data.position}
								arrow={data.arrow}
								text="Tooltips shouldn’t really exceed one line, but sometimes it’s unavoidable"
								label="Label"
								wrap
							>
								Hover this
							</Tooltip>
						</ComponentCard>
						<AllUsage />
					</div>
				))}
				<div className="plt-content-wrapper dis-mb-24">
					<h6 className="plt-contents-head plt-heading-md plt-font-color-primary dis-mb-8">small</h6>
					<p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
						A basic text field. If you use a text field outside of a form component, always use a label and associate
						the label to the field properly.
					</p>
				</div>
				{TooltipComponenSmallDatas.map((data) => (
					<div key={data.id} className="dis-mb-32">
						<div className="plt-content-wrapper dis-mb-24">
							<h6 className="plt-contents-head plt-heading-sm plt-font-color-primary dis-mb-8">{data.title}</h6>
							<p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
								{data.description}
							</p>
						</div>
						<ComponentCard editClick={showModal}>
							<Tooltip position={data.position} arrow={data.arrow} size="small" text="This is tooltip" label="Label">
								Hover this
							</Tooltip>
						</ComponentCard>
						<AllUsage />
					</div>
				))}
			</div>
			<CommonModal
				isOpen={isModalVisible}
				onRequestClose={() => setModalVisibility(false)}
				title="Tokens"
				onApply={() => setModalVisibility(false)}
				onCancelText="Cancel"
				onApplyText="Apply"
				variant="modal-tertiary"
			>
				<div className="plt-modal-body-content">
					<div className="plt-dropdown-secondary">
						<div className="plt-select-dropdown-wrapper d-flex align-items-center justify-content-between">
							<div className="plt-dropdown-wrapper plt-dropdown-wrapper-common">
								<CustomSelect options={buttonStates} selectedOption={buttonState} />
							</div>
						</div>
					</div>

					<div className="plt-preview plt-heading-xl dis-mb-32">
						{previewtooltipData.map((data, index) => (
							<Tooltip position={data.position} arrow={data.arrow} text="This is tooltip" label="Label">
								Hover this
							</Tooltip>
						))}
					</div>

					<div className="plt-token-details dis-mb-32">
						<h6 className="plt-token-title plt-heading-xs plt-font-color-primary dis-mb-4">Background color</h6>
						<div className="plt-token-value-item d-flex">
							<p className="plt-badge plt-token-value-name plt-body-compact-sm dis-mr-12">
								background.button.{buttonState.toLowerCase()}.primary
							</p>
							<span
								className="plt-iconbtn plt-iconbtn-sm plt-token-value-icon"
								onClick={() => {
									setTokenType("bg");
									setColorTokenModalOpen(true);
								}}
							>
								<EditIcon />
							</span>
						</div>
					</div>

					<div className="plt-token-details dis-mb-32">
						<h6 className="plt-token-title plt-heading-xs plt-font-color-primary dis-mb-4">Padding</h6>
						<div className="plt-token-value-item d-flex align-items-center">
							<div className="d-flex align-items-center">
								<span className="dis-mr-4">H:</span>
								<p className="plt-badge plt-token-value-name plt-body-compact-sm dis-mr-12">spacing-200</p>
							</div>
							<div className="d-flex align-items-center">
								<span className="dis-mr-4">V:</span>
								<p className="plt-badge plt-token-value-name plt-body-compact-sm dis-mr-12">spacing-200</p>
							</div>

							<span className="plt-iconbtn plt-iconbtn-sm plt-token-value-icon">
								<EditIcon />
							</span>
						</div>
					</div>

					<div className="plt-token-details dis-mb-32">
						<h6 className="plt-token-title plt-heading-xs plt-font-color-primary dis-mb-4">Text color</h6>
						<div className="plt-token-value-item d-flex">
							<p className="plt-badge plt-token-value-name plt-body-compact-sm dis-mr-12">text.button.fill.primary</p>
							<span
								className="plt-iconbtn plt-iconbtn-sm plt-token-value-icon"
								onClick={() => {
									setTokenType("text");
									setColorTokenModalOpen(true);
								}}
							>
								<EditIcon />
							</span>
						</div>
					</div>
				</div>
			</CommonModal>
			<div className="plt-token-contents">
				<TabNavigation tabs={tabData} title={"CONTENTS"} activeIndex={activeTab} onTabClick={handleTabClick} />
			</div>
		</div>
	);
};

export default PreviewTooltipComponent;
