import React, { useState } from "react";
import { TagComponentPrimaryDatas } from "../../../../assets/data/data";
import TabNavigation from "../../TabNavigation";
import ComponentCard from "../../../Common/ComponentCard";
// import Tabs from "../../../Tabs";
// import { ButtonIconSample, DropdownArrow } from "../../../Icons";
// import Inputs from "../../../Inputs";
import Tag from "../../../Tag";
// import { TagComponentPrimaryDatas } from "../../../../assets/data/data";
import CommonModal from "../../../Common/CommonModal";
import CustomSelect from "../../../Common/CustomSelect";
import EditButton from "../../../Buttons/EditButton";
import { EditIcon } from "components/Icons";

const PreviewTagComponent = ({ tabData, handleTabClick, activeTab, setSelectedTab }) => {
	const commonDesc =
		"Use a primary button to call attention to an action on a form or to highlight the strongest call to action on a page.";

	const [isModalVisible, setModalVisibility] = useState(false);
	const showModal = () => {
		setModalVisibility(true);
	};

	const previewtagData = [
		{
			id: 1,
			title: "Normal Label Only",
			desc: "Use a primary button to call attention to an action on a form or to highlight the strongest call to action on a page.",
			prefixIcon: false,
			statusIcon: false,
			disable: false,
			mode: "",
		},
	];

	const buttonStates = ["Default", "Hovered", "Pressed", "Disabled"];
	const [buttonState, setButtonState] = useState(buttonStates[0]);

	const AllUsage = () => (
		<p className="plt-character-des plt-body-compact-sm dis-pt-12 dis-mb-32">
			See all states{" "}
			<span
				onClick={() => setSelectedTab(1)}
				className="plt-text-underline plt-body-compact-sm plt-font-color-primary font-weight-500 cursor-pointer"
			>
				usage
			</span>
		</p>
	);

  return (
    <div className="tab-content-wrapper d-flex justify-content-between">
      <div className="tab-content-container">
        <div className="plt-content-wrapper dis-mb-24">
          <h6 className="plt-contents-head plt-heading-md plt-font-color-primary dis-mb-8">
            Large
          </h6>
          <p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
            Use a primary button to call attention to an action on a form or to
            highlight the strongest call to action on a page. Primary buttons
            should only appear once per container (not including the application
            header or in a modal dialog). Not every screen requires a primary
            button.
          </p>
        </div>
        {TagComponentPrimaryDatas.map((tagConfig, index) => (
          <div key={index} className="plt-content-wrapper dis-mb-24">
            <div className="plt-content-wrapper-block dis-mb-24">
              <h6 className="plt-contents-head plt-heading-sm plt-font-color-primary dis-mb-8">
                {tagConfig.title}
              </h6>
              <p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
                {tagConfig.desc}
              </p>
            </div>
            <ComponentCard editClick={showModal}>
              <Tag
                size="large"
                prefixIcon={tagConfig.prefixIcon}
                statusIcon={tagConfig.statusIcon}
                // suffixIcon={tagConfig.suffixIcon}
                // dataItem={tagConfig.dataItem}
                // leadingIcon={tagConfig.leadingIcon}
                // tailingIcon={tagConfig.tailingIcon}
                // prefix={tagConfig.prefix}
                // counter={tagConfig.counter}
                //   prefixIcon
                //prefixIcon={tagConfig.prefixIcon}
                mode={tagConfig.mode}
                disable={tagConfig.disable}
                varient={tagConfig.varient}
              />
            </ComponentCard>
            <AllUsage />
          </div>
        ))}
        <div className="plt-content-wrapper dis-mb-24">
          <h6 className="plt-contents-head plt-heading-md plt-font-color-primary dis-mb-8">
            Normal
          </h6>
          <p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
            Use a primary button to call attention to an action on a form or to
            highlight the strongest call to action on a page. Primary buttons
            should only appear once per container (not including the application
            header or in a modal dialog). Not every screen requires a primary
            button.
          </p>
        </div>
        {TagComponentPrimaryDatas.map((tagConfig, index) => (
          <div key={index} className="plt-content-wrapper dis-mb-24">
            <div className="plt-content-wrapper-block dis-mb-24">
              <h6 className="plt-contents-head plt-heading-sm plt-font-color-primary dis-mb-8">
                {tagConfig.title}
              </h6>
              <p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
                {tagConfig.desc}
              </p>
            </div>
            <ComponentCard editClick={showModal}>
              <Tag
                size="normal"
                prefixIcon={tagConfig.prefixIcon}
                statusIcon={tagConfig.statusIcon}
                mode={tagConfig.mode}
                disable={tagConfig.disable}
                varient={tagConfig.varient}
              />
            </ComponentCard>
            <AllUsage />
          </div>
        ))}
        <div className="plt-content-wrapper dis-mb-24">
          <h6 className="plt-contents-head plt-heading-md plt-font-color-primary dis-mb-8">
            Small
          </h6>
          <p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
            Use a primary button to call attention to an action on a form or to
            highlight the strongest call to action on a page. Primary buttons
            should only appear once per container (not including the application
            header or in a modal dialog). Not every screen requires a primary
            button.
          </p>
        </div>
        {TagComponentPrimaryDatas.map((tagConfig, index) => (
          <div key={index} className="plt-content-wrapper dis-mb-24">
            <div className="plt-content-wrapper-block dis-mb-24">
              <h6 className="plt-contents-head plt-heading-sm plt-font-color-primary dis-mb-8">
                {tagConfig.title}
              </h6>
              <p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
                {tagConfig.desc}
              </p>
            </div>
            <ComponentCard editClick={showModal}>
              <Tag
                size="small"
                prefixIcon={tagConfig.prefixIcon}
                statusIcon={tagConfig.statusIcon}
                mode={tagConfig.mode}
                disable={tagConfig.disable}
                varient={tagConfig.varient}
              />
            </ComponentCard>
            <AllUsage />
          </div>
        ))}
      </div>
      <CommonModal
        isOpen={isModalVisible}
        onRequestClose={() => setModalVisibility(false)}
        title="Tokens"
        onApply={() => setModalVisibility(false)}
        onCancelText="Cancel"
        onApplyText="Apply"
        variant="modal-tertiary"
      >
        <div className="plt-modal-body-content">
          <div className="plt-dropdown-secondary">
            <div className="plt-select-dropdown-wrapper d-flex align-items-center justify-content-between">
              <div className="plt-dropdown-wrapper plt-dropdown-wrapper-common">
                <CustomSelect
                  options={buttonStates}
                  selectedOption={buttonState}
                />
              </div>
            </div>
          </div>

          <div className="plt-preview plt-heading-xl dis-mb-32">
            {previewtagData.map((data, index) => (
              <Tag size="large" prefixIcon={data.prefixIcon} mode={data.mode} varient={data.varient} />
            ))}
          </div>

					<div className="plt-token-details dis-mb-32">
						<h6 className="plt-token-title plt-heading-xs plt-font-color-primary dis-mb-4">Background color</h6>
						<div className="plt-token-value-item d-flex">
							<p className="plt-badge plt-token-value-name plt-body-compact-sm dis-mr-12">
								background.button.{buttonState.toLowerCase()}.primary
							</p>
							<span
								className="plt-iconbtn plt-iconbtn-sm plt-token-value-icon"
								onClick={() => {
									setTokenType("bg");
									setColorTokenModalOpen(true);
								}}
							>
								<EditIcon />
							</span>
						</div>
					</div>

					<div className="plt-token-details dis-mb-32">
						<h6 className="plt-token-title plt-heading-xs plt-font-color-primary dis-mb-4">Typography</h6>
						<div className="plt-token-value-item d-flex">
							<p className="plt-badge plt-token-value-name plt-body-compact-sm dis-mr-12">uiText.lg.medium</p>
							<span
								className="plt-iconbtn plt-iconbtn-sm plt-token-value-icon"
								onClick={() => {
									setTokenType("text");
									setTypoTokenModalOpen(true);
								}}
							>
								<EditIcon />
							</span>
						</div>
					</div>

					<div className="plt-token-details dis-mb-32">
						<h6 className="plt-token-title plt-heading-xs plt-font-color-primary dis-mb-4">Text color</h6>
						<div className="plt-token-value-item d-flex">
							<p className="plt-badge plt-token-value-name plt-body-compact-sm dis-mr-12">text.button.fill.primary</p>
							<span
								className="plt-iconbtn plt-iconbtn-sm plt-token-value-icon"
								onClick={() => {
									setTokenType("text");
									setColorTokenModalOpen(true);
								}}
							>
								<EditIcon />
							</span>
						</div>
					</div>

					<div className="plt-token-details dis-mb-32">
						<h6 className="plt-token-title plt-heading-xs plt-font-color-primary dis-mb-4">Radius</h6>
						<div className="plt-token-value-item d-flex">
							<p className="plt-badge plt-token-value-name plt-body-compact-sm dis-mr-12">radius-150</p>
							<span
								className="plt-iconbtn plt-iconbtn-sm plt-token-value-icon"
								onClick={() => setRadiusTokenModalOpen(true)}
							>
								<EditIcon />
							</span>
						</div>
					</div>

					<div className="plt-token-details dis-mb-32">
						<h6 className="plt-token-title plt-heading-xs plt-font-color-primary dis-mb-4">Padding</h6>
						<div className="plt-token-value-item d-flex align-items-center">
							<div className="d-flex align-items-center">
								<span className="dis-mr-4">H:</span>
								<p className="plt-badge plt-token-value-name plt-body-compact-sm dis-mr-12">spacing-200</p>
							</div>
							<div className="d-flex align-items-center">
								<span className="dis-mr-4">V:</span>
								<p className="plt-badge plt-token-value-name plt-body-compact-sm dis-mr-12">spacing-200</p>
							</div>

							<span className="plt-iconbtn plt-iconbtn-sm plt-token-value-icon">
								<EditIcon />
							</span>
						</div>
					</div>
				</div>
			</CommonModal>
			<div className="plt-token-contents">
				<TabNavigation tabs={tabData} title={"CONTENTS"} activeIndex={activeTab} onTabClick={handleTabClick} />
			</div>
		</div>
	);
};

export default PreviewTagComponent;
