import { updatePage } from "api";
import error from "components/../assets/images/svg/iconography/error.svg";
import info from "components/../assets/images/svg/iconography/info.svg";
import placeholder from "components/../assets/images/svg/iconography/placeholder.svg";
import success from "components/../assets/images/svg/iconography/success.svg";
import warning from "components/../assets/images/svg/iconography/warning.svg";
import AddButton from "components/Buttons/AddButton";
import EditButton from "components/Buttons/EditButton";
import CommonModal from "components/Common/CommonModal";
import { SearchIcon } from "components/Icons";
import SelectInput from "components/SelectInput";
import { useGlobalContext } from "context/GlobalContext";
import { ContentState, EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { useEffect, useState } from "react";
import IconographyBox from "./IconographyBox";
import "./IconographyStyles.scss";

const IconographyStyles = () => {
	const { designSystemMeta, setDesignSystemMeta } = useGlobalContext();
	const tabData = ["Icon library"];
	const [page, setPage] = useState({});

	useEffect(() => {
		setPage(designSystemMeta?.pages?.find((page) => page.type === "ic"));
	}, []);

	useEffect(() => {
		const contentBlock = htmlToDraft(designSystemMeta?.pages?.find((page) => page.type === "ic")?.content ?? "");
		if (contentBlock) {
			const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
			const editorState = EditorState.createWithContent(contentState);
			setEditorState(editorState);
		}
	}, []);

	const IconLibraryDatas = [
		{
			id: 1,
			img: placeholder,
			content: "icon-placeholder",
		},
		{
			id: 2,
			img: error,
			content: "icon-cancel",
		},
		{
			id: 3,
			img: warning,
			content: "icon-caution",
		},
		{
			id: 4,
			img: success,
			content: "icon-correct",
		},
		{
			id: 5,
			img: info,
			content: "icon-Information",
		},
	];
	const fonts = [
		{ label: "General", value: "General" },
		{ label: "General1", value: "General1" },
		{ label: "General3", value: "General3" },
	];
	const datas = [
		{ label: "All icons", value: "all-icons" },
		{ label: "App catalogue", value: "app-catalogue" },
		{ label: "Data", value: "data" },
		{ label: "Research", value: "research" },
		{ label: "Travel", value: "travel" },
	];
	const [selecteStyles, setSelectedStyles] = useState({
		label: "General",
		value: "General",
	});
	const [selecteDatas, setSelectedDatas] = useState({
		label: "All icons",
		value: "all-icons",
	});
	const [isAddElevationModalOpen, setAddElevationModalOpen] = useState(false);
	const [activeTab, setActiveTab] = useState(0);
	const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
	const [isEditing, setIsEditing] = useState(false);

	const handleStylesChange = (value) => setSelectedStyles(value);

	const handleDatasChange = (value) => setSelectedDatas(value);

	const handleTabClick = (index) => {
		setActiveTab(index);
	};

	const saveChanges = async () => {
		const contentHtml = draftToHtml(convertToRaw(editorState.getCurrentContent()));
		const dataToSubmit = {
			id: page.id,
			content: contentHtml,
		};

		try {
			await updatePage(dataToSubmit, page?.id);
			setIsEditing(false);
			setPage({ ...page, content: contentHtml });
			updateState(contentHtml);
		} catch (error) {
			console.error("Failed to update page:", error.message);
		}
	};

	const updateState = (contentHtml) => {
		const newDesignSystemMeta = { ...designSystemMeta };
		const newPages = newDesignSystemMeta.pages.map((p) => {
			if (p.id !== page.id) {
				return p;
			} else {
				return {
					...p,
					content: contentHtml,
				};
			}
		});
		newDesignSystemMeta.pages = newPages;
		setDesignSystemMeta(newDesignSystemMeta);
	};

	return (
		<div className="d-flex justify-content-between w-full">
			<div className="tab-content-container">
				<div className="iconography-container">
					<div className="plt-dropdown-container d-flex dis-mb-32">
						<div className="plt-select-dropdown-wrapper dis-mr-20">
							<h6 className="plt-select-dropdown-title plt-ui-text-sm-regular plt-font-color-primary dis-mb-4">
								Selected style set:
							</h6>
							<div className="plt-dropdown-wrapper plt-dropdown-wrapper-style">
								{/* <CustomSelect
                  options={fonts}
                  selectedOption={selecteStyles}
                  onSelect={handleStylesChange}
                /> */}

								<SelectInput
									options={fonts}
									defaultValue={selecteStyles ?? "Select role..."}
									onSelect={handleStylesChange}
								/>
							</div>
						</div>

						<div className="input-container plt-input-search dis-mr-20">
							<input type="text" placeholder="Search" className="input-field input-field-custom dis-mt-0" />
							<span className="input-prefix">
								<SearchIcon />
							</span>
						</div>

						<div className="plt-select-dropdown-wrapper d-flex align-items-center">
							<div className="plt-dropdown-wrapper plt-dropdown-wrapper-icons">
								{/* <CustomSelect
                  options={datas}
                  selectedOption={selecteDatas}
                  onSelect={handleDatasChange}
                /> */}

								<SelectInput options={datas} defaultValue={selecteDatas} onSelect={handleDatasChange} />
							</div>
						</div>
					</div>

					<div className="plt-content-wrapper dis-mb-24">
						<div className="plt-content-block-top d-flex justify-content-between align-items-center dis-mb-8">
							<h6 className="plt-contents-head plt-heading-md plt-font-color-primary">Icon library</h6>
							<div className="plt-icon-wrapper d-flex justify-content-between align-items-center">
								<span onClick={() => setAddElevationModalOpen(true)} className="plt-function-icon">
									<AddButton />
								</span>
								<span onClick={() => setModalOpen(true)} className="plt-function-icon">
									<EditButton />
								</span>
							</div>
						</div>
						<p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
							It is important to maintain the same style of radius for the same types components. Inconsistency will
							create a messy UI.
						</p>
					</div>

					<div className="plt-iconography-card-wrapper d-flex">
						{IconLibraryDatas.map((data) => (
							<div key={data.id} className="plt-icon-card">
								<IconographyBox IconographyBoxData={data} />
							</div>
						))}
					</div>
				</div>
			</div>

			<CommonModal
				isOpen={isAddElevationModalOpen}
				onRequestClose={() => setAddElevationModalOpen(false)}
				title="Add icons"
				onApply={() => setAddElevationModalOpen(false)}
				onCancelText="Cancel"
				onApplyText="Apply"
			>
				<div className="plt-modal-body-content">
					<div className="plt-preview plt-heading-xl dis-mb-32">
						<div className="plt-common-card-img-box m-auto"></div>
					</div>
				</div>
			</CommonModal>
		</div>
	);
};

export default IconographyStyles;
