import React, { useEffect, useState } from "react";

import PrimaryButton from "components/Common/PrimaryButton";
import SettingsLayout from "components/layouts/SettingsLayout";
import { useGlobalContext } from "context/GlobalContext";
import { getUser } from "hooks/storageUtils";
import { manageUserPermission, fetchUserPermissions } from "api";
import "./EmailNotification.scss";

const EmailNotification = () => {
	const { setLoading } = useGlobalContext();
	const [permissions, setPermissions] = useState();

	useEffect(() => {
		const getPermissions = async (id) => {
			try {
				setLoading(true);
				const data = await fetchUserPermissions(id);
				setPermissions(data.data);
			} catch (error) {
				console.error("Failed to fetch permission", error);
			} finally {
				setLoading(false);
			}
		};
		const user = getUser();
		getPermissions(user.id);
	}, []);

	const handleSave = async () => {
		try {
			setLoading(true);
			const user = getUser();
			let request = {
				userId: user.id,
				permission: "Project updates",
				grant: true,
			};
			const data = await manageUserPermission(request);
			setPermissions(data.data);
		} catch (error) {
			console.error("Failed to fetch permission", error);
		} finally {
			setLoading(false);
		}
	};
	const handleCancel = () => {};

	return (
		<SettingsLayout>
			<div className="tab-content-wrapper">
				<div className="tab-content-container">
					<div className="plt-content-block dis-mb-0">
						<div className="plt-content-wrapper dis-mb-40">
							<div className="plt-content-block-top d-flex justify-content-between align-items-center">
								<h6 className="plt-contents-head plt-heading-xl plt-font-color-primary">Email notifications</h6>
							</div>
						</div>
					</div>

					<div className="notification-container">
						<div className={`plt-content-block dis-mb-32`}>
							<div className="plt-content-wrapper dis-mb-16">
								<div className="plt-content-block-top d-flex align-items-center dis-mb-8">
									<h6 className="plt-contents-head plt-heading-md plt-font-color-primary">Projects</h6>
								</div>
								<p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
									Receive notifications related to updates within design system projects.
								</p>
							</div>

							<div className="component-card">
								<div className="button-wrapper d-flex align-items-center">
									<div>
										<h6 className="plt-contents-head plt-heading-sm plt-font-color-primary dis-mb-8">
											Project updates
										</h6>
										<p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
											Receive emails about your project details
										</p>
									</div>
									<div className="notitication-check">
										{/* <input checked={true} className={`dss-checkbox dss-checkbox-preview dss-checkbox-normal`} type='checkbox' />{" "}  */}
										<label className="dss-toggler dss-toggler-large dss-toggler-default">
											<div className="dss-toggler-elem">
												<input className="dss-toggler-toggler-input" type="checkbox" />
											</div>
										</label>
									</div>
								</div>
							</div>
						</div>
						{/* plt-content-block */}
						<div className={`plt-content-block dis-mb-32`}>
							<div className="plt-content-wrapper dis-mb-16">
								<div className="plt-content-block-top d-flex align-items-center dis-mb-8">
									<h6 className="plt-contents-head plt-heading-md plt-font-color-primary">Account</h6>
								</div>
								<p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
									Receive notifications about important system-wide announcements and updates.
								</p>
							</div>

							<div className="component-card dis-mb-16">
								<div className="button-wrapper d-flex align-items-center">
									<div>
										<h6 className="plt-contents-head plt-heading-sm plt-font-color-primary dis-mb-8">
											{" "}
											Suspicious activity
										</h6>
										<p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
											Receive emails about your project details
										</p>
									</div>
									<div className="notitication-check">
										{/* <input
              checked={true}
              className={`dss-checkbox dss-checkbox-preview  dss-checkbox-normal`}
              type='checkbox'
            />{" "} */}
										<label className="dss-toggler dss-toggler-large dss-toggler-default">
											<div className="dss-toggler-elem">
												<input className="dss-toggler-toggler-input" type="checkbox" />
											</div>
										</label>
									</div>
								</div>
							</div>
							<div className="component-card">
								<div className="button-wrapper d-flex align-items-center">
									<div>
										<h6 className="plt-contents-head plt-heading-sm plt-font-color-primary dis-mb-8">
											{" "}
											Subscription renewal
										</h6>
										<p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
											Receive emails about your project details
										</p>
									</div>
									<div className="notitication-check">
										{/* <input
              checked={true}
              className={`dss-checkbox dss-checkbox-preview  dss-checkbox-normal`}
              type='checkbox'
            />{" "} */}
										<label className="dss-toggler dss-toggler-large dss-toggler-default">
											<div className="dss-toggler-elem">
												<input className="dss-toggler-toggler-input" type="checkbox" />
											</div>
										</label>
									</div>
								</div>
							</div>
						</div>
						{/* plt-content-block */}

						<div className={`plt-content-block dis-mb-32`}>
							<div className="plt-content-wrapper dis-mb-16">
								<div className="plt-content-block-top d-flex align-items-center dis-mb-8">
									<h6 className="plt-contents-head plt-heading-md plt-font-color-primary">Team</h6>
								</div>
								<p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
									Receive notifications regarding your team and related updates.
								</p>
							</div>

							<div className="component-card dis-mb-16">
								<div className="button-wrapper d-flex align-items-center">
									<div>
										<h6 className="plt-contents-head plt-heading-sm plt-font-color-primary dis-mb-8">
											Joining requests
										</h6>
										<p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
											Receive emails about your project details
										</p>
									</div>
									<div className="notitication-check">
										{/* <input
              checked={true}
              className={`dss-checkbox dss-checkbox-preview  dss-checkbox-normal`}
              type='checkbox'
            />{" "} */}
										<label className="dss-toggler dss-toggler-large dss-toggler-default">
											<div className="dss-toggler-elem">
												<input className="dss-toggler-toggler-input" type="checkbox" />
											</div>
										</label>
									</div>
								</div>
							</div>
							<div className="component-card dis-mb-16">
								<div className="button-wrapper d-flex align-items-center">
									<div>
										<h6 className="plt-contents-head plt-heading-sm plt-font-color-primary dis-mb-8">
											Account deletion
										</h6>
										<p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
											Receive emails about your project details
										</p>
									</div>
									<div className="notitication-check">
										{/* <input
              checked={true}
              className={`dss-checkbox dss-checkbox-preview  dss-checkbox-normal`}
              type='checkbox'
            />{" "} */}
										<label className="dss-toggler dss-toggler-large dss-toggler-default">
											<div className="dss-toggler-elem">
												<input className="dss-toggler-toggler-input" type="checkbox" />
											</div>
										</label>
									</div>
								</div>
							</div>
						</div>
						{/* plt-content-block */}

						<div className={`plt-content-block dis-mb-32`}>
							<div className="plt-content-wrapper dis-mb-16">
								<div className="plt-content-block-top d-flex align-items-center dis-mb-8">
									<h6 className="plt-contents-head plt-heading-md plt-font-color-primary">Platform</h6>
								</div>
								<p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
									Receive notifications about important system-wide announcements and updates.
								</p>
							</div>

							<div className="component-card dis-mb-16">
								<div className="button-wrapper d-flex align-items-center">
									<div>
										<h6 className="plt-contents-head plt-heading-sm plt-font-color-primary dis-mb-8">
											{" "}
											System announcements
										</h6>
										<p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
											Receive emails about your project details
										</p>
									</div>
									<div className="notitication-check">
										{/* <input
              checked={true}
              className={`dss-checkbox dss-checkbox-preview  dss-checkbox-normal`}
              type='checkbox'
            />{" "} */}
										<label className="dss-toggler dss-toggler-large dss-toggler-default">
											<div className="dss-toggler-elem">
												<input className="dss-toggler-toggler-input" type="checkbox" />
											</div>
										</label>
									</div>
								</div>
							</div>
							<div className="component-card dis-mb-16">
								<div className="button-wrapper d-flex align-items-center">
									<div>
										<h6 className="plt-contents-head plt-heading-sm plt-font-color-primary dis-mb-8">
											Platform updates
										</h6>
										<p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
											Receive emails about your project details
										</p>
									</div>
									<div className="notitication-check">
										{/* <input
              checked={true}
              className={`dss-checkbox dss-checkbox-preview  dss-checkbox-normal`}
              type='checkbox'
            />{" "} */}
										<label className="dss-toggler dss-toggler-large dss-toggler-default">
											<div className="dss-toggler-elem">
												<input className="dss-toggler-toggler-input" type="checkbox" />
											</div>
										</label>
									</div>
								</div>
							</div>
						</div>
						{/* plt-content-block */}

						<div className="d-flex align-items-center">
							<PrimaryButton
								onClick={handleCancel}
								text="Cancel"
								// className="plt-btn plt-btn-secondary plt-btn-lg  ml-auto dis-mr-20"
								className="ml-auto dis-mr-20"
								variant= "secondary"
								size= "lg"
							/>
							<PrimaryButton
								onClick={handleSave}
								text="Update notifications"
								// className="plt-btn plt-btn-primary plt-btn-lg"
								variant= "primary"
								size= "lg"
							/>
						</div>
					</div>
				</div>
			</div>
		</SettingsLayout>
	);
};

export default EmailNotification;
